<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" [formGroup]="Form">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Transfer Orders to Courier Company</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <!-- <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button> -->
                            <table id="excel-table" class="table table-striped report-tbl users">
                                <tr>
                                    <th class="center-align left-tbl" width="5%">#</th>
                                    <th class="center-align" width="10%">Order Number</th>
                                    <th class="center-align" width="10%">Customer Name</th>
                                    <th class="center-align" width="10%">Contact No</th>
                                    <th class="center-align" width="10%">Address</th>
                                    <th class="center-align" width="10%">City</th>
                                    <th class="center-align" width="10%">Amount</th>
                                    <th class="center-align" width="10%">Weight</th>
                                    <th class="center-align" width="10%">Service</th>
                                    <th class="center-align" width="10%">Product Detail</th>
                                    <th class="center-align" width="10%">Action</th>
                                </tr>
                                <ng-container *ngFor="let OrderData of orderList.orders; index as i">
                                    <tr class="left-align">
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">
                                            <!-- {{OrderData.id}} -->
                                            <input type="text" name="id" formControlName="id"  />
                                        </td>
                                        <td class="left-align">
                                            {{OrderData.shipper_name}}
                                            <input type="hidden" name="shipper_name" [(ngModel)]="OrderData.shipper_name">
                                        </td>
                                        <td class="center-align">
                                            {{OrderData.phone}}
                                            <input type="hidden" name="phone" [(ngModel)]="OrderData.phone">
                                        </td>
                                        <td class="left-align">
                                            {{OrderData.billing_address}}
                                            <input type="hidden" name="address" [(ngModel)]="OrderData.billing_address">
                                        </td>
                                        <td class="center-align">
                                            {{OrderData.city_name}}
                                            <input type="hidden" name="city" [(ngModel)]="OrderData.city_name">
                                        </td>
                                        <td class="center-align">
                                            {{OrderData.net_amount + ConvertStringToNumber(OrderData.shipping) }}
                                            <!-- var amount_ ={{OrderData.net_amount + ConvertStringToNumber(OrderData.shipping) }} -->
                                            <input type="hidden" name="amount" >
                                        </td>
                                        <td class="center-align">
                                            <input type="text" name="weight">
                                        </td>
                                        <td class="center-align" style="width: 200px;display: inline-block;">
                                            <select class="custom-select" id="catmajorname" name="service">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Service ---
                                                </option>
                                                <option value="Overnight" default>Overnight</option>
                                                <option value="Second Day">Second Day</option>
                                            </select>
                                        </td>

                                        <td class="center-align">
                                            <input type="text" name="productDetail">
                                        </td>
                                        <td class="center-align">
                                            <!-- <a [routerLink]="['/sales/orders/orderdetail',OrderData.id]">View</a> -->
                                            <button type="button" class="btn btn-primary" (click)="submitForm()">Save</button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                            <!-- <app-pagination [products]="orderList.orders" [paginate]="paginate"
                                (setPage)="setPage($event)">
                            </app-pagination> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->