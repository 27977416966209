import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings';
import { ActivatedRoute } from "@angular/router";
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
// import { bounce, zoomOut, zoomIn, fadeIn, bounceIn, } from 'ng-animate';

@Component({
  selector: 'app-product-review-detail',
  templateUrl: './product-review-detail.component.html',
  styleUrls: ['./product-review-detail.component.scss']
})

export class ProductReviewDetailComponent implements OnInit {

  ReviewList = []
  Product_Id:string;

  constructor(private activatedRoute: ActivatedRoute,private modalService: NgbModal, private http: HttpClient, private _masterProductService: ProductApiService,config: NgbRatingConfig) {
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit(): void {
    this.Product_Id = this.activatedRoute.snapshot.paramMap.get("id");
    this.getProductsReviewById(this.Product_Id);
  }

  // Show Product Review By Product Id
  getProductsReviewById(event: any) {
    this._masterProductService.getProductReviewById(event)
        .subscribe(
          data => this.ReviewList = data
        );

      
  }

}
