import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  modalContent: undefined
  labelBox: string;
  labelImg: string;
  GatewayList: {};

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultUpload: DataUrl = '';
  localUrl: any;

  base64textString = [];

  Form = new FormGroup({
    name: new FormControl(),
    PaymentGatewayId: new FormControl(),
    bank_charges: new FormControl(),
    fed_charges: new FormControl(),
    status: new FormControl(),
    Id: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterOrderService: OrderApiService, private imageCompress: NgxImageCompressService) {
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.Form.patchValue({
        name: tableRow.name,
        PaymentGatewayId: tableRow.id,
        bank_charges: tableRow.bank_charges,
        fed_charges: tableRow.fed_charges
      });

      console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        name: null,
        Id: null,
        bank_charges: null,
        fed_charges: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.getPaymentGatewayLists();
  }

  getPaymentGatewayLists() {
    this._masterOrderService.getAllPaymentGatewayLists()
      .subscribe(
        data => this.GatewayList = data
      );
  }

  CreatePaymentGateway() {
    if (this.Form.get('name').value == null) {
      alert('Enter Payment Gateway Name');
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('name').value,
        logo_url: this.localCompressedURl,
        bank_charges: this.Form.get('bank_charges').value,
        fed_charges: this.Form.get('fed_charges').value,
      }

      console.log(obj);

      this._masterOrderService.insertPaymentGateway(obj)
        .subscribe(response => {
          console.log(response);
          window.location.reload();
        });
    }
  }

  UpdatePaymentGateway() {
    if (this.Form.valid) {

      var obj = {
        id: this.Form.get('Id').value,
        name: this.Form.get('name').value
      }

      this._masterOrderService.updatePaymentGateway(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  DeletePaymentGateway(){
    var obj = {
      id: this.Form.get('PaymentGatewayId').value,
      status: this.Form.get('status').value
    }

    if (JSON.stringify(obj.status) == 'null') {
      this._masterOrderService.deletePaymentGateway(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterOrderService.restorePaymentGateway(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}