import { Component, OnInit } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WebsiteContentService } from 'src/app/shared/service/website-content.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss']
})
export class CreatePageComponent implements OnInit {

  MenusList: any={};
  page_Id: string;
  returnUrl: string;

  Forms = new FormGroup({
    pageId: new FormControl(),
    name: new FormControl(),
    meta_title: new FormControl(),
    meta_desc: new FormControl(),
    description: new FormControl(),
    menu: new FormControl(),
  });

  constructor(private http: HttpClient, private modalService: NgbModal,
    private _masterContentService: WebsiteContentService,
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.getAllListMenus();
    this.page_Id = this.activatedRoute.snapshot.paramMap.get("id");
    if(this.page_Id != null)
    this.getMenuById(this.page_Id);

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/pages/list-page';
  }

  getMenuById(event: any) {
    //this.showSpinner();
    this._masterContentService.getSinglePageById(event)
      .subscribe(data => {
        var prodData = {
          website_menu_name: data.website_menu_name,
          meta_desc: data.meta_description,
          meta_title: data.meta_title,
          description: data.content,
          menu: data.website_menu_id,
          pageId: data.id,
          name:data.title
        };
        this.Forms.patchValue(prodData);
        // console.log(prodData)
      },
        err => {
         // console.log(err);
        });
  }

  getAllListMenus(): void {

    this._masterContentService.getMenusLists()
      .subscribe(response => {
        this.MenusList = response;
      }),
      (error) => {
       // console.log(error);
      }
  }


  Create() {

    if (this.Forms.get('name').value == null) {
      alert("Enter Page Title");
      return;
    }
    
    if (this.Forms.get('description').value == null) {
      alert("Enter Description");
      return;
    }

    if (this.Forms.get('menu').value == null) {
      alert("Select Menu");
      return;
    }

    //if (this.Forms.valid) {

      var obj = {
        title: this.Forms.get('name').value,
        meta_title: this.Forms.get('meta_title').value,
        meta_description: this.Forms.get('meta_desc').value,
        content: this.Forms.get('description').value,
        website_menu_id: this.Forms.get('menu').value,

      }
     // console.log(obj);

      this._masterContentService.insertContent(obj)
        .subscribe(response => {
          //console.log(response);
          this.router.navigate([this.returnUrl]);
        });
   // }

  }


  Update() {

    
    // if (this.Forms.get('name').value == null) {
    //   alert("Enter Page Title");
    //   return;
    // }
    
    if (this.Forms.get('description').value == null) {
      alert("Enter Description");
      return;
    }

    if (this.Forms.get('menu').value == null) {
      alert("Select Menu");
      return;
    }
    
    // if (this.Forms.valid) {
    //   console.log("update")

      var obj = {
        title: this.Forms.get('name').value,
        meta_title: this.Forms.get('meta_title').value,
        meta_description: this.Forms.get('meta_desc').value,
        content: this.Forms.get('description').value,
        website_menu_id: this.Forms.get('menu').value,
        id: this.Forms.get('pageId').value

      }
     console.log(obj);

      this._masterContentService.updatePage(obj)
        .subscribe(response => {
          //console.log(response);
          this.router.navigate([this.returnUrl]);
        });
    // }
  }

}
