import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ActivatedRoute } from "@angular/router";
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.scss']
})
export class OrderdetailComponent implements OnInit {

  Order_Id: string;
  User_Email: string;
  Shipper_Name: string;
  Billing_Address: string;
  Shipper_Address: string;
  Phone_Number: string;
  City: string;
  Order_Amount: string;
  Discount_Amount: string;
  Net_Amount: number;
  Shipment_Amount: string;
  Bank_Charges: string;
  FED_Charges: string;
  Payment_Gateway: string;
  Delivery_Mode: string;
  // deliverymode: string;
  Source_Type: string;
  Tax: string;
  Order_Date: string;
  Order_Status: string;
  Tracking_Number: string;

  orderList = [];
  DeliveryModeList: {};
  OrderStatusList: any = {};

  Form = new FormGroup({
    deliverymode: new FormControl(),
    orderstatus: new FormControl(),
    orderid: new FormControl()
  });

  constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal, private http: HttpClient, private _orderService: OrderApiService) {
  }

  ngOnInit(): void {
    this.Order_Id = this.activatedRoute.snapshot.paramMap.get("id");
    this.getOrderDetailById(this.Order_Id);
    this.getDeliveryMode();
    this.getOrderStatus();
  }

  getDeliveryMode() {
    this._orderService.getAllDeliveryModeLists()
      .subscribe(
        data => this.DeliveryModeList = data
      );
  }

  getOrderStatus() {
    this._orderService.getOrderStatusLists()
      .subscribe(data => {
        this.OrderStatusList = data;
        // console.log(response)
      }),
      (error) => {
        //console.log(error);
      }
    // );
  }

  getOrderDetailById(event: any) {

    this._orderService.getSingleOrderDetailById(event)
      .subscribe(data => {
        data = JSON.stringify(JSON.parse(data));
        const myObj = JSON.parse(data);

        this.Order_Id = myObj.id;
        this.User_Email = myObj.email;
        this.Shipper_Name = myObj.shipper_name
        this.Billing_Address = myObj.billing_address
        this.Shipper_Address = myObj.shipper_address
        this.Phone_Number = myObj.phone
        this.City = myObj.city_name;
        this.Order_Amount = myObj.amount
        this.Discount_Amount = myObj.discount_amount
        this.Shipment_Amount = myObj.shipping
        this.Net_Amount = Number(myObj.net_amount) + Number(myObj.shipping)
        this.Bank_Charges = myObj.bank_charges
        this.FED_Charges = myObj.fed_charges
        this.Payment_Gateway = myObj.payment_gateway_name
        this.Delivery_Mode = myObj.delivery_mode_id
        //this.deliverymode = myObj.delivery_mode_id
        this.Source_Type = myObj.source_type_name
        this.Tax = myObj.tax
        this.Order_Date = myObj.date
        this.Order_Status = myObj.order_status_id
        this.Tracking_Number = myObj.tracking_name

        this.getDeliveryModeById(this.Delivery_Mode);
        this.getOrderStatusById(this.Order_Status);
        
        // this.orderList = Object.keys(myObj.order_details).map(index => {
        //   let data = myObj.order_details[index];
        //   return data;
        // });

        this.orderList = myObj.order_details;

        if (myObj.order_status_id == "3") {
          this.Form.get('orderstatus').disable();
        }

        if (myObj.order_status_id == "5") {
          this.Form.get('orderstatus').disable();
        }

        if (myObj.order_status_id == "6") {
          this.Form.get('orderstatus').disable();
        }

      },
        err => {
          console.log(err);
        });

  }

  getDeliveryModeById(string) {

    this._orderService.getDeliveryModeById(string)
      .subscribe(
        data => {
          var record = {
            deliverymode: data.id
          }
          this.Form.patchValue(record);
        }
      );
  }

  getOrderStatusById(string) {

    this._orderService.getOrderStatusById(string)
      .subscribe(
        data => {
          var record = {
            orderstatus: data.id
          }
          // console.log(record)
          this.Form.patchValue(record);
        }
      );
  }

  Update() {

    var obj = {
      delivery_mode_id: this.Form.get('deliverymode').value,
      order_status_id: this.Form.get('orderstatus').value,
      id: this.Order_Id
    }
    console.log(obj);

    this._orderService.updateOrder(obj)
      .subscribe(response => {
        console.log(response);
        this.ngOnInit();
        // window.location.reload();
      });

  }



}
