<div class="container-fluid">


    <div class="row product-adding">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h5>Product Review</h5>
                </div>

                <div class="card-body">

                    <div class="col-xl-12">
                        <div class="product-page-details product-right mb-0">
                            <h2>{{ReviewList[0].product_name}}</h2>
                            <hr>
                            <span *ngFor="let list of ReviewList; index as i">
                                <h6 class="product-title">{{list.user_name}} {{list.email}}</h6>
                                <ngb-rating class="star" [rate]="list.rating"></ngb-rating>
                                <p class="mb-0">{{list.comment}}</p>


                                <hr>
                            </span>





                        </div>
                    </div>
                </div>
            </div>
        </div>








    </div><br>



</div>