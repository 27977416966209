import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { DataServiceService } from '../../shared/service/data-service.service';
import { Router } from '@angular/router';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;

  DashboardList: {};

  public model: NgbDateStruct;
  public date: { year: number, month: number };

  constructor(private http: HttpClient, private _attributeService: DataServiceService,
    private router: Router, private calendar: NgbCalendar) {
    Object.assign(this, { doughnutData, pieData })
  }

  Form = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl(),
  });


  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;


  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }

  Earnings: string;
  productsCount: string;
  productReviewsCount: string;
  newOrdersCount: string;
  pendingOrdersCount: string;
  completedOrdersCount: string;
  usersOrdersRatio: string;
  returnOrdersCount: string;
  cancelledOrdersCount: string;

  orderList = [];
  productList = [];
  productViewCountList = [];

  showThisMonthLine: boolean = true;

  ngOnInit() {
    this.getDashboard();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  getDashboard() {
    var obj = {
      start_date: this.Form.get('start_date').value,
      end_date: this.Form.get('end_date').value,
    }

    if (obj.start_date && obj.start_date.month !== undefined) {
      const startDateMonth = obj.start_date.month;
      const currentMonth = new Date().getMonth()+1;
      this.showThisMonthLine = currentMonth === startDateMonth;
      
      console.log(startDateMonth)


    } else {
      console.error('obj.start_date is null or undefined');
    }

    
      



    this.Earnings = "";
    this.orderList = [];
    this.productList = [];

    this._attributeService.getDashboardData(obj)
      .subscribe(response => {
        response = JSON.stringify(JSON.parse(response));
        const myObj = JSON.parse(response);

        this.Earnings = myObj.salesCount;
        this.productsCount = myObj.productsCount
        this.productReviewsCount = myObj.productReviewsCount

        this.newOrdersCount = myObj.newOrdersCount
        this.pendingOrdersCount = myObj.pendingOrdersCount
        this.completedOrdersCount = myObj.completedOrdersCount
        this.usersOrdersRatio = myObj.usersOrdersRatio

        this.returnOrdersCount = myObj.returnOrdersCount
        this.cancelledOrdersCount = myObj.cancelledOrdersCount

        for (var i = 0; i < myObj.orders.length; i++) {
          var orderDetails = {
            id: myObj.orders[i].id,
            amount: Number(myObj.orders[i].net_amount) + Number(myObj.orders[i].shipping),
            payment_gateway_name: myObj.orders[i].payment_gateway_name,
            order_status_name: myObj.orders[i].order_status_name,
          };
          this.orderList.push(orderDetails);
        }

        for (var i = 0; i < myObj.products.length; i++) {
          var productDetails = {
            name: myObj.products[i].name,
            amount: myObj.products[i].sales_amount,
            quantity: myObj.products[i].sales_count,
          };
          this.productList.push(productDetails);
          // console.log(orderDetails)
        }

        for (var i = 0; i < myObj.productviewcount.length; i++) {
          var productViewCount = {
            name: myObj.productviewcount[i].name,
            count: myObj.productviewcount[i].count,
          };
          this.productViewCountList.push(productViewCount);
          // console.log(orderDetails)
        }

      },
        // err => {
        //   err = JSON.stringify(err)
        //   const myObj = JSON.parse(err);

        //   if (myObj.error.message == "Unauthenticated.") {
        //     //console.log("Errorrr..." + myObj.error.message);

        //     localStorage.removeItem('E-Com-Admin-user');
        //     this.router.navigate(['/auth/login']);
        //   }
        // });


        error => {
          console.log(error);
          if (error.error.message == 'Unauthenticated.') {
            localStorage.removeItem('E-Com-Admin-user');
            this.router.navigate(['/auth/login']);
          }
        });

  }

  ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    console.log(input);
    return numeric;
  }

}
