import { Component, OnInit, Renderer2, ElementRef, ViewChild, ChangeDetectorRef, NgModule } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { OutletApiService } from 'src/app/shared/service/outlet-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ActivatedRoute, Router } from "@angular/router";
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})

export class DigitalAddComponent implements OnInit {
  
  returnUrl: string;
  labelImg: string;
  ImageBaseData: string | ArrayBuffer = null;
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  localUrl: any;
  Product_Id: string;
  Single_Product_Record: [];
  BrandList: {};
  MasterOutlets: {};
  MasterCategories: {};
  AgeRangeList: {};
  MajorCategories: {};
  SubCategories: {};
  VariantGroupList: any;
  VariantList: any;
  CustomerDetails: {};
  Customers = [];
  productImage = [];
  rows: any = []
  Custom: any = []

  contactForm: FormGroup;

  Form = new FormGroup({
    productId: new FormControl(),
    title: new FormControl(),
    sku: new FormControl(),
    mastername: new FormControl(),
    majorname: new FormControl(),
    subcatname: new FormControl(),
    agerange: new FormControl(),
    shortdesc: new FormControl(),
    longdesc: new FormControl(),
    variantgroup: new FormControl(),
    variant: new FormControl(),
    purchaseprice: new FormControl(),
    retailprice: new FormControl(),
    saleprice: new FormControl(),
    variantprice: new FormControl(),
    purchasedate: new FormControl(),
    img: new FormControl(),
    shortdesc_: new FormControl(),
    longdesc_: new FormControl(),
    outlet: new FormControl(),
    brand: new FormControl(),
    featured: new FormControl(),
    isvariant: new FormControl(),
    imgId: new FormControl(),
  });


  @ViewChild('div') div: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private http: HttpClient,
    private _masterCategoryService: CategoriesApiService,
    private _masterProductService: ProductApiService,
    private _masterOutletService: OutletApiService,
    private _masterService: MasterApiService,
    private renderer: Renderer2,
    private fb: FormBuilder, el: ElementRef,
    private imageCompress: NgxImageCompressService,
    private cf: ChangeDetectorRef, private router: Router,
    private spinner: NgxSpinnerService) {

    // var events = 'cut copy paste';
    // events.split(' ').forEach(e =>
    //   renderer.listen(el.nativeElement, e, (event) => {
    //     event.preventDefault();
    //   })
    // );
  }


  isVariant: boolean = false; // hidden by default
  isNonvariant: boolean = false; // hidden by default

  toggleShow($event: any) {
    this.isVariant = false;
    this.isNonvariant = false;

    if ($event.target.value == 0) {
      this.isNonvariant = !this.isNonvariant;
    }

    if ($event.target.value == 1) {
      this.isVariant = !this.isVariant;
    }
  }

  /*
    handleFileInput(files: FileList) {
      let me = this;
      let file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        //console.log(reader.result);
        me.ImageBaseData = reader.result;
      };
      reader.onerror = function (error) {
        //console.log('Error: ', error);
      };
    }
  */

  imgResultMultiple: UploadResponse[] = [];

  uploadMultipleFiles() {
    return this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        
        this.imgResultMultiple = multipleOrientedFiles;
        // console.warn(`${multipleOrientedFiles.length} files selected`);
      });
  }


  // selectFile(event: any) {
  selectFile() {
    // var fileName: any;
    // let file = event.target.files[0];
    // fileName = file['name'];
    // if (event.target.files && event.target.files[0]) {
    //   var reader = new FileReader();
    //   reader.onload = (event: any) => {
    //     this.localUrl = event.target.result;
    //     console.log(this.localUrl.files)
    //     this.compressFiles(this.localUrl, fileName)
    //   }
    //   reader.readAsDataURL(event.target.files[0]);
    // }


    /*
        var fileName: any[] = [];
        let file = event.target.files && event.target.files.length;
        if (file > 0 && file < 5) {
          let i: number = 0;
          for (const singlefile of event.target.files) {
    
            var reader = new FileReader();
            reader.readAsDataURL(singlefile);
            //this.localUrl.push(singlefile);
            reader.onload = (event: any) => {
              this.localUrl = event.target.result;
              //console.log("Local Url: " + this.localUrl)
              this.compressFiles(this.localUrl, fileName)
            }
            */

    /*
var fileName: any[] = [];
var img: any;
let file = event.target.files && event.target.files.length;
// if (file > 0 && this.file < 5) {
let i: number = 0;
for (const singlefile of event.target.files) {
var reader = new FileReader();
reader.readAsDataURL(singlefile);
//this.localUrl.push(singlefile);
//this.cf.detectChanges();
i++;
// console.log(this.localUrl);
reader.onload = (event) => {
  const url = (<FileReader>event.target).result as string;
 // fileName.push(url);
img =  this.compressFiles(url, fileName)
  this.cf.detectChanges();
  //console.log("img: "+ img);
};
//console.log(singlefile);
}
// }

// }
//}
*/

    return this.imageCompress
      .uploadMultipleFiles()
      .then(
        (multipleOrientedFiles: UploadResponse[]) => {
          this.imgResultMultiple = multipleOrientedFiles;
          // console.warn(`${multipleOrientedFiles.length} files selected`);
          // console.log(this.imgResultMultiple);
        }
      );

    /*
          return this.imageCompress
          .uploadMultipleFiles()
          .then((multipleOrientedFiles: UploadResponse[]) => {
            this.imgResultMultiple = multipleOrientedFiles;
           // this.imgResultMultiple_ = multipleOrientedFiles;
            //this.imgResultBeforeCompress = multipleOrientedFiles;
            //console.warn('Size in bytes was:', this.imageCompress.byteCount(multipleOrientedFiles));
    
            this.imageCompress
              .compressFile(this.imgResultMultiple.toString(), 0, 50, 50)
              .then(
                (result: DataUrl) => {
                  this.imgResultAfterCompress = result;
                  console.warn(
                    'Size in bytes is now:',
                    this.imageCompress.byteCount(result)
                  );
                }
              );
    
             // console.log("imgResultMultiple: "+this.imgResultMultiple); 
             // console.log("imgResultAfterCompress: "+this.imgResultAfterCompress);
          });
          */



  }
  /*
    // const multipleOrientedFiles : string[] = [];
    compressFile() {
      return this.imageCompress
        .uploadMultipleFiles()
        .then((multipleOrientedFiles: UploadResponse[]) => {
          this.imgResultMultiple = multipleOrientedFiles;
          // this.imgResultMultiple_ = multipleOrientedFiles;
          //this.imgResultBeforeCompress = multipleOrientedFiles;
          //console.warn('Size in bytes was:', this.imageCompress.byteCount(multipleOrientedFiles));
  
          this.imageCompress
            .compressFile(this.imgResultMultiple.toString(), -1, 50, 50)
            .then(
              (result: DataUrl) => {
                this.imgResultAfterCompress = result;
                console.warn(
                  'Size in bytes is now:',
                  this.imageCompress.byteCount(result)
                );
              }
            );
        });
    }
  
  
    localCompressedURl: any;
    sizeOfOriginalImage: number;
    sizeOFCompressedImage: number;
  
    compressFiles(image, fileName) {
      var orientation = -1;
      this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
      console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.localCompressedURl = result;
          this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
          console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
          // create file from byte
          const imageName = fileName;
          // call method that creates a blob from dataUri
          const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
          //imageFile created below is the new compressed file which can be send to API in form data
          const imageFile = new File([result], imageName, { type: 'image/jpeg' });
        });
      return this.localCompressedURl;
    }
    dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/jpeg' });
      return blob;
    }
  */

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  ngOnInit() {

    this.Product_Id = this.activatedRoute.snapshot.paramMap.get("id");

    this.getProductsById(this.Product_Id);

    this.getMasterCategory();
    this.getAgeRange();
    this.getProductVariantGroup();
    this.getMasterOutlet();
    this.getBrandsList();

    this.contactForm = this.fb.group({
      variantgroup: [null],
      variant: [null],
      purchaseprice: [null],
      variantprice: [null],
      purchasedate: [null],
      qty: [null],
      variantgroupName: [null],
      variantName: [null]
    });

    this.rows = [{
      name: '',
      email: '',
      mobno: ''
    }]

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || 'products/digital/digital-product-list';

  }

  addVariant() {

    if (this.contactForm.get('purchaseprice').value == null) {
      alert("Enter Purchase Price");
      return;
    }

    if (this.contactForm.get('purchasedate').value == null) {
      alert("Enter Purchase Date");
      return;
    }

    if (this.contactForm.get('qty').value == null) {
      alert("Enter Quantity");
      return;
    }

    var customer;

    if (this.contactForm.get('variantgroup').value != null && this.contactForm.get('variant').value != null && this.contactForm.get('variantprice').value != null) {

      customer = {
        variant: this.contactForm.get('variant').value,
        variantgroup: this.contactForm.get('variantgroup').value,
        purchaseprice: this.contactForm.get('purchaseprice').value,
        variantprice: this.contactForm.get('variantprice').value,
        //saleprice: this.contactForm.get('saleprice').value,
        purchasedate: this.contactForm.get('purchasedate').value,
        qty: this.contactForm.get('qty').value,
        variantgroupName: this.VariantGroupList.filter(v => v.id == this.contactForm.get('variantgroup').value)[0].name,
        variantName: this.VariantList.filter(v => v.id == this.contactForm.get('variant').value)[0].name
      };
    }
    else {
      customer = {
        variant: this.contactForm.get('variant').value,
        variantgroup: this.contactForm.get('variantgroup').value,
        purchaseprice: this.contactForm.get('purchaseprice').value,
        variantprice: this.contactForm.get('variantprice').value,
        //saleprice: this.contactForm.get('saleprice').value,
        purchasedate: this.contactForm.get('purchasedate').value,
        qty: this.contactForm.get('qty').value
        //   variantgroupName: this.VariantGroupList.filter(v => v.id == this.contactForm.get('variantgroup').value)[0].name,
        // variantName: this.VariantList.filter(v => v.id == this.contactForm.get('variant').value)[0].name
      };
    }

     //console.log(customer);
    this.Customers.push(customer);

    this.Form.get('isvariant').disable();
  }

  deleteRow(index: any) {

    if (parseInt(this.Product_Id) > 0) {
      var obj = {
        productId: this.Product_Id,
        variantGroupId: this.Customers[index].variantgroup,
        variantId: this.Customers[index].variant
      }

      this._masterProductService.getProductVariantInventoriesListById(obj)
        .subscribe(response => {
          if (parseInt(response[0].stock_remaining) == response[0].stock_in) {
            this.Customers.splice(index, 1);
          }
          else {
            alert("Variant can't be removed due to Stock already Out.")
          }
        });
    }
    else {
      this.Customers.splice(index, 1);
    }
  }

  deleteImageRow(index: any) {
    this.productImage.splice(index, 1);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // Show Brands
  getBrandsList() {
    this._masterProductService.getAllBrandsList()
      .subscribe(
        data => this.BrandList = data
      );
  }

  // Show Master Category
  getMasterOutlet() {
    this._masterOutletService.getAllListOutlets()
      .subscribe(
        data => this.MasterOutlets = data
      );
  }

  // Show Master Category
  getMasterCategory() {

    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => this.MasterCategories = data
      );
  }

  getAgeRange() {
    this._masterService.getAllListsFromAPI("age-groups")
      .subscribe(
        data => this.AgeRangeList = data
      );
  }

  // Show Major Category List on DropDown
  getMasterCategoryById(event: any, string) {
    this.showSpinner();
    var value;
    if (string == 0) {
      value = event.target.value;
    }
    else {
      value = string;
    }

    this._masterCategoryService.getMajorCategoriesById(value)
      .subscribe(
        data => {
          this.MajorCategories = data;
          this.hideSpinner();
        }
      );
  }

  // Show Major Category List on DropDown
  getSubCategoryById(event: any, string) {

    this.showSpinner();
    var value;
    if (string == 0) {
      value = event.target.value;
    }
    else {
      value = string;
    }
    this._masterCategoryService.getSubCategoriesById(value)
      .subscribe(
        data => {
          this.SubCategories = data;
          //console.log(data); console.log(value)
          this.hideSpinner();
        }
      );
  }

  // Show Product Variant Group
  getProductVariantGroup() {
    this.showSpinner();
    this._masterProductService.getAllListProductVariantGroup()
      .subscribe(
        data => {
          this.VariantGroupList = data;
          this.hideSpinner();
        }
      );
  }

  // Show Product Variant List on DropDown
  getProductVariantById(event: any) {
    this.showSpinner();
    this._masterProductService.getProductVariantGroupById(event.target.value)
      .subscribe(
        data => {
          this.VariantList = data;
          this.hideSpinner();
        }
      );
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement(),
    );
  }

  // ========== For Edit Product Start ==========

  // Show Product By Id
  getProductsById(event: any) {
    //this.showSpinner();
    this._masterProductService.getSingleProductById(event)

      .subscribe(data => {
        var prodData = {
          title: data.name,
          sku: data.sku,
          mastername: data.master_category_id,
          majorname: data.major_category_id,
          subcatname: data.sub_category_id,
          agerange: data.age_group_id,
          //agerange: ((data.age_group_id.replace('[', '')).replace(']', '')),
          brand: data.brand_id,
          outlet: data.outlet_id,
          featured: data.is_featured,
          shortdesc_: data.short_description,
          longdesc_: data.long_description,
          catImage: data.product_images,
          retailprice: data.retail_price,
          saleprice: data.sale_price,
          isvariant: data.is_varient,
          labelImg: data.product_images[0].url,
          productId: data.id
        };

        console.log(prodData)

        this.getMasterCategoryById(0, prodData.mastername);
        this.getSubCategoryById(0, prodData.majorname);

        this.Form.patchValue(prodData);
        this.Product_Id = prodData.productId
        // console.log(prodData)

        if (prodData.isvariant == 0) {
          this.Form.get('isvariant').disable();
        }
        else {
          this.Form.get('isvariant').disable();
          this.isVariant = !this.isVariant;
        }

        for (var i = 0; i < data.product_images.length; i++) {
          var img = {
            url: data.product_images[i].url,
            imgId: data.product_images[i].id,
          };
          this.productImage.push(img);
        }

        for (var i = 0; i < data.product_inventories.length; i++) {
          var customers = {
            variantName: data.product_inventories[i].product_varient_name,
            variantgroupName: data.product_inventories[i].product_varient_group_name,
            purchaseprice: data.product_inventories[i].purchase_price,
            variantprice: data.product_inventories[i].varient_price,
            purchasedate: data.product_inventories[i].purchase_date,
            qty: data.product_inventories[i].stock_in,
            variantgroup: data.product_inventories[i].product_varient_group_id,
            variant: data.product_inventories[i].product_varient_id
          };
          this.Customers.push(customers);
        }
      },
        err => {
          //console.log(err);
        });
  }

  // ========== For Edit Product End ==========

  Create() {

    if (this.imgResultMultiple.length == 0) {
      alert("Select Pictures");
      return;
    }

    if (this.Form.get('shortdesc_').value == null) {
      alert("Enter Short Description");
      return;
    }

    if (this.Form.get('longdesc_').value == null) {
      alert("Enter Long Description");
      return;
    }

    if (this.Form.get('title').value == null) {
      alert("Enter Product Title");
      return;
    }

    if (this.Form.get('retailprice').value == null) {
      alert("Enter Retail Price");
      return;
    }

    if (this.Form.get('sku').value == null) {
      alert("Enter SKU");
      return;
    }

    if (this.Form.get('subcatname').value == null) {
      alert("Select Sub Category");
      return;
    }

    if (this.Form.get('mastername').value == null) {
      alert("Select Master Category");
      return;
    }

    if (this.Form.get('majorname').value == null) {
      alert("Select Major Category");
      return;
    }

    if (this.Form.get('isvariant').value == null) {
      alert("Select Variant");
      return;
    }


    if (this.Form.valid) {

      var table = document.querySelectorAll("span.var table tr");
      var variantData = [];
      for (var i = 1; i < table.length; i++) {
        var row = table[i].querySelectorAll(".var-tbl td");
        variantData.push({
          product_varient_id: row[7].innerHTML,
          product_varient_group_id: row[6].innerHTML,
          purchase_price: row[2].innerHTML,
          varient_price: row[3].innerHTML,
          // sale_price: row[4].innerHTML,
          purchase_date: row[4].innerHTML.replace(/<[^>]*>/g, '').slice(0, 11),
          stock_in: row[5].innerHTML
        });
      }
      //console.log(variantData);

      var obj = {
        name: this.Form.get('title').value,
        sku: this.Form.get('sku').value,
        cart_description: this.Form.get('shortdesc_').value,
        short_description: this.Form.get('shortdesc_').value,
        long_description: this.Form.get('longdesc_').value,
        sub_category_id: this.Form.get('subcatname').value,
        age_group_id: this.Form.get('agerange').value,
        brand_id: this.Form.get('brand').value,
        outlet_id: this.Form.get('outlet').value,
        is_featured: this.Form.get('featured').value,
        is_varient: this.Form.get('isvariant').value,
        //  media_array: [fileUplodVM.ImageBaseData],
        media_array: this.imgResultMultiple,//this.localCompressedURl,
        retail_price: this.Form.get('retailprice').value,
        sale_price: this.Form.get('saleprice').value,
        product_prices: variantData

      }
       console.log(obj);

      this._masterProductService.insertProductData(obj)
        .subscribe(response => {
          console.log(response);
          this.router.navigate([this.returnUrl]);
        });
    }
  }

  Update() {

    if (this.Form.get('shortdesc_').value == null) {
      alert("Enter Short Description");
      return;
    }

    if (this.Form.get('longdesc_').value == null) {
      alert("Enter Long Description");
      return;
    }

    if (this.Form.get('title').value == null) {
      alert("Enter Product Title");
      return;
    }

    if (this.Form.get('retailprice').value == null) {
      alert("Enter Retail Price");
      return;
    }

    if (this.Form.get('sku').value == null) {
      alert("Enter SKU");
      return;
    }

    if (this.Form.get('mastername').value == null) {
      alert("Select Master Category");
      return;
    }

    if (this.Form.get('majorname').value == null) {
      alert("Select Major Category");
      return;
    }

    if (this.Form.get('subcatname').value == null) {
      alert("Select Sub Category");
      return;
    }

    if (this.Form.get('agerange').value == null) {
      alert("Select Age Range");
      return;
    }

    if (this.Form.get('isvariant').value == null) {
      alert("Select Variant");
      return;
    }

    // if (this.imgResultMultiple.length == 0 && imageData.length == 0) {
    //   alert("Select Pictures");
    //   return;
    // }

    var tableImg = document.querySelectorAll("span.img table tr");
    var imageData = [];
    for (var i = 1; i < tableImg.length; i++) {
      var row = tableImg[i].querySelectorAll(".image td");
      imageData.push({
        image_id: row[2].innerHTML
      });
    }
    //console.log(imageData);

    // let imageId = imageData.map(itm => itm.imageId);
    // console.log(imageId)


    var table = document.querySelectorAll("span.var table tr");
    var variantData = [];
    for (var i = 1; i < table.length; i++) {
      var row = table[i].querySelectorAll(".var-tbl td");
      variantData.push({
        product_varient_id: row[7].innerHTML,
        product_varient_group_id: row[6].innerHTML,
        purchase_price: row[2].innerHTML,
        varient_price: row[3].innerHTML,
        // sale_price: row[4].innerHTML,
        purchase_date: row[4].innerHTML.slice(0, 11),
        stock_in: row[5].innerHTML
      });
    }
    //console.log(variantData);

    var obj = {
      id: this.Form.get('productId').value,
      name: this.Form.get('title').value,
      sku: this.Form.get('sku').value,
      cart_description: this.Form.get('shortdesc_').value,
      short_description: this.Form.get('shortdesc_').value,
      long_description: this.Form.get('longdesc_').value,
      sub_category_id: this.Form.get('subcatname').value,
      age_group_id: this.Form.get('agerange').value,
      brand_id: this.Form.get('brand').value,
      outlet_id: this.Form.get('outlet').value,
      is_featured: this.Form.get('featured').value,
      is_varient: this.Form.get('isvariant').value,
      //  media_array: [fileUplodVM.ImageBaseData],
      media_array: this.imgResultMultiple,//this.localCompressedURl,
      product_images:imageData,
      retail_price: this.Form.get('retailprice').value,
      sale_price: this.Form.get('saleprice').value,
      product_prices: variantData
    }
    //console.log(obj);

    this._masterProductService.updateProductData(obj)
      .subscribe(response => {
        //console.log(response);
        this.router.navigate([this.returnUrl]);

      });
  }







}

export class FileUplodVM {
  ImageBaseData: string;
}