import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  ImageBaseData: string | ArrayBuffer = null;

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  localUrl: any;

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  modalContent: undefined
  labelBox: string;
  labelImg: string;
  BrandList: {};

  AddForm = new FormGroup({
    brandname: new FormControl(),
    brandId: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, 
    private http: HttpClient, 
    private _masterProductService: ProductApiService, 
    private imageCompress: NgxImageCompressService,
    private renderer: Renderer2,el: ElementRef) {

    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    //console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.AddForm.patchValue({
        brandname: tableRow.name,
        brandId: tableRow.id,
        status: tableRow.deleted_at
      });

      //console.log(this.AddForm);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.AddForm.patchValue({
        brandname: null,
        brandId: null,
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /*
  handleFileInput(files: FileList) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      me.ImageBaseData = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
*/

selectFile(event: any) {
  var fileName: any;
  let file = event.target.files[0];
  fileName = file['name'];
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.localUrl = event.target.result;
      this.compressFiles(this.localUrl, fileName)
    }
    reader.readAsDataURL(event.target.files[0]);
  }
}

localCompressedURl: any;
sizeOfOriginalImage: number;
sizeOFCompressedImage: number;

compressFiles(image, fileName) {
  var orientation = -1;
  this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
  console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
  this.imageCompress.compressFile(image, orientation, 50, 50).then(
    result => {
      this.imgResultAfterCompress = result;
      this.localCompressedURl = result;
      this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
      console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
      // create file from byte
      const imageName = fileName;
      // call method that creates a blob from dataUri
      const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
      //imageFile created below is the new compressed file which can be send to API in form data
      const imageFile = new File([result], imageName, { type: 'image/jpeg' });
    });
}
dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/jpeg' });
  return blob;
}

  ngOnInit(): void {
    this.getBrandsList();
  }


  getBrandsList() {
    this._masterProductService.getAllBrandsList()
      .subscribe(
        data => this.BrandList = data
      );
  }


  CreateBrand() {
    if (this.AddForm.get('brandname').value == null) {
      alert('Enter Brand Name');
      return;
    }

    if (this.AddForm.valid) {

      // if (this.ImageBaseData == null) {
      //   var fileUplodVM: FileUplodVM = {
      //     ImageBaseData: ""
      //   }
      // }
      // else {
      //   var fileUplodVM: FileUplodVM = {
      //     ImageBaseData: this.ImageBaseData.toString()
      //   }
      // }

      // this.compressImage(base64, 100, 100).then(compressed => {
      //   this.resizedBase64 = compressed;
      // })

      var obj = {
        name: this.AddForm.get('brandname').value,
        media_string: this.localCompressedURl
        // media_string: fileUplodVM.ImageBaseData
      }

      //console.log(obj);

      this._masterProductService.insertBrands(obj)
        .subscribe(response => {
         // console.log(response);
          window.location.reload();
        });
    }
  }

  UpdateBrand() {
    if (this.AddForm.valid) {
      // if (this.ImageBaseData == null) {
      //   var fileUplodVM: FileUplodVM = {
      //     ImageBaseData: ""
      //   }
      // }
      // else {
      //   var fileUplodVM: FileUplodVM = {
      //     ImageBaseData: this.ImageBaseData.toString()
      //   }
      // }
      var obj = {
        id: this.AddForm.get('brandId').value,
        name: this.AddForm.get('brandname').value,
        media_string: this.localCompressedURl
        // media_string: fileUplodVM.ImageBaseData
      }

      this._masterProductService.updateBrands(obj)
        .subscribe(response => {
         // console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }


  DeleteBrand() {

    // var id = this.AddForm.get('brandId').value
    var obj = {
      id: this.AddForm.get('brandId').value,
      status: this.AddForm.get('status').value
    }

    // console.log(obj);

    if (JSON.stringify(obj.status) == 'null') {
    this._masterProductService.deleteBrandData(obj)
      .subscribe(response => {
        //console.log(response)
        this.modalService.dismissAll();
        window.location.reload();
      });
    }
    else{
      this._masterProductService.restoreBrandData(obj)
      .subscribe(response => {
        //console.log(response)
        this.modalService.dismissAll();
        window.location.reload();
      });
    }
    
  }


  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }




}

export class FileUplodVM {
  ImageBaseData: string;
}
