import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../service/nav.service';
import { DataServiceService } from '../../service/data-service.service';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  notificationList: {};
  notificationCount: {};
  username: string;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, private router: Router,
    private _attributeService: DataServiceService) { 

      // this._attributeService.getNotificationData()
      // .subscribe(response => {
      //   this.notificationList = response;
      //  // this.notificationCount = response.le

      //   console.log(response);
      // });
      
    }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout() {
    // remove user from local storage and set current user to null
    console.log("LogOut");
    localStorage.removeItem('E-Com-Admin-user');
    this.router.navigate(['/auth/login']);
  }
  ngOnInit() { 

    const obj = JSON.parse(localStorage.getItem('E-Com-Admin-user'));
    //  console.log("USERLIST Home: " + localStorage.getItem('E-Com-Admin-user'));

    if (localStorage.getItem('E-Com-Admin-user') == null) {
      localStorage.removeItem('E-Com-Admin-user');
      this.router.navigate(['/auth/login']);
    }
    else {
      this.username = obj.user.name;
      CONSTANTS.USER_TOKEN = obj.access_token;
      //console.log("SIDEBAR: " + CONSTANTS.USER_TOKEN);
    }


  }

}
