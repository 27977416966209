import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-product-variant-group-add',
  templateUrl: './product-variant-group-add.component.html',
  styleUrls: ['./product-variant-group-add.component.scss']
})
export class ProductVariantGroupAddComponent implements OnInit {

  returnUrl: string;
  ImageBaseData: string | ArrayBuffer = null;
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  localUrl: any;

  Form = new FormGroup({
    title: new FormControl(),
    image: new FormControl()
  });

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private _masterProductService: ProductApiService,
    private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef) {

    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/products/digital/productsvariantgroup';
  }

  /*
    handleFileInput(files: FileList) {
      let me = this;
      let file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        console.log(reader.result);
        me.ImageBaseData = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    */

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  Create() {
    if (this.Form.valid) {
      if (this.ImageBaseData == null) {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: ""
        }
      }
      else {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: this.ImageBaseData.toString()
        }
      }
      var obj = {
        name: this.Form.get('title').value,
        // media_string: fileUplodVM.ImageBaseData
        media_string: this.localCompressedURl
      }

      this._masterProductService.insertProductVariantGroupData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          this.router.navigate([this.returnUrl]);
        });
    }
  }



}

export class FileUplodVM {
  ImageBaseData: string;
}
