<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="User Detail">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="Form" class="needs-validation user-add" novalida>
                                        
                                        <input type="hidden" formControlName="userId" value="{{userId}}">
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                First Name</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom0"
                                                type="text" formControlName="first" (keypress)="special_char($event)" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span> Last
                                                Name</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom1"
                                                type="text" formControlName="last" (keypress)="special_char($event)" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom2" class="col-xl-3 col-md-4"><span>*</span>
                                                Email</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom2"
                                                type="text" formControlName="email" required="" (change)="emailcheck()">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom3" class="col-xl-3 col-md-4"><span>*</span>
                                                Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom3"
                                                type="password" formControlName="password" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Confirm Password</label>
                                            <input class="form-control col-xl-8 col-md-7" id="validationCustom4"
                                                type="password" formControlName="password2" required=""
                                                (change)="passwordcheck()">
                                        </div>

                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Roles</label>
                                            <select class="col-xl-3 col-md-4 form-control select2" id="role"
                                                formControlName="role">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Roles ---
                                                </option>
                                                <option *ngFor="let l of RoleList" [value]="l.id">
                                                    {{l.name | uppercase}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Country</label>
                                            <select class="col-xl-3 col-md-4 form-control" id="country"
                                                formControlName="country" (change)="getProvinceByCountryId($event,0)">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Country ---
                                                </option>
                                                <option *ngFor="let l of CountryList" [value]="l.id">
                                                    {{l.name | uppercase}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Province</label>
                                            <select class="col-xl-3 col-md-4 form-control" id="province"
                                                formControlName="province" (change)="getCityByProvinceId($event,0)">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Province ---
                                                </option>
                                                <option *ngFor="let l of ProvinceList" [value]="l.id">
                                                    {{l.name | uppercase}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                City</label>
                                            <select class="col-xl-3 col-md-4 form-control" id="city"
                                                formControlName="city" (change)="getOutletByCityId($event,0)">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select City ---
                                                </option>
                                                <option *ngFor="let l of CitiesList" [value]="l.id">
                                                    {{l.name | uppercase}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Outlet</label>
                                            <select class="col-xl-3 col-md-4 form-control" id="outlet"
                                                formControlName="outlet">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Outlet ---
                                                </option>
                                                <option *ngFor="let l of OutletList" [value]="l.id">
                                                    {{l.name | uppercase}}
                                                </option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>

                    </ngb-tabset>
                    <div class="pull-left">
                        <span class="space-span" *ngIf="userId == null; else elseBlock_update">
                            <button type="button" class="btn btn-primary" style="cursor:pointer;" (click)="create()"
                                [disabled]="errormode">Save</button>
                        </span>
                        <ng-template #elseBlock_update>
                            <span class="space-span">
                                <button type="button" class="btn btn-primary" style="cursor: pointer;" (click)="update()"
                                    [disabled]="errormode">Update</button>
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->