import { listPagesDB } from 'src/app/shared/tables/list-pages';

import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WebsiteContentService } from 'src/app/shared/service/website-content.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent implements OnInit {
  public list_pages = [];
  public selected = [];

  // constructor() {
  //   this.list_pages = listPagesDB.list_pages;
  // }
  public closeResult: string;
  MenusList: any = {}
  modalContent: undefined
  labelBox: string;
  labelImg: string;


  Form = new FormGroup({
    id: new FormControl(),
    status: new FormControl()
  });


  // constructor() {
  //   this.menus = menuDB.data;
  // }

  constructor(private http: HttpClient, private modalService: NgbModal,
    private _masterContentService: WebsiteContentService,
    private router: Router, private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {

  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {

      this.labelBox = "Delete";

      this.Form.patchValue({
        id: tableRow.id,
        status: tableRow.deleted_at
      });

      //console.log(this.Form);
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  delete({ selected }) {
   // console.log(selected)

  }

  ngOnInit() {
    this.getAllListContent();
  }

  getAllListContent(): void {

    this._masterContentService.getContentLists()
      .subscribe(response => {
        // console.log(response);
        this.MenusList = response;
      }),
      (error) => {
       // console.log(error);
      }
  }

  Delete() {
    
    var obj = {
      id: this.Form.get('id').value,
      status: this.Form.get('status').value
    }

    // console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterContentService.deleteContentData(obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterContentService.restoreContentData(obj)
        .subscribe(response => {
         // console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }

  }

}

