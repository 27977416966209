// import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, Renderer2, ElementRef, ViewChild, ChangeDetectorRef, NgModule } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

/*
  https://www.youtube.com/watch?v=zL58GoOx_F8
  https://github.com/Rinlama/AngularTools/tree/checkbox/src/app
  https://github.com/Rinlama/AngularTools/blob/checkbox/src/app/app.component.html
  https://github.com/Rinlama/AngularTools/blob/checkbox/src/app/app.component.ts
*/

@Component({
  selector: 'app-digital-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss']
})
export class DigitalListComponent implements OnInit {

  public product = [];
  public temp = [];
  public productCollection: any;
  public digital_list = []
  public closeResult: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  readonly CONSTANTS = CONSTANTS;

  ImageBaseData: string | ArrayBuffer = null;
  labelBox: string;
  labelImg: string;
  modalContent: undefined
  ProductList: any = {}
  MasterCategories: {};
  MajorCategories: {};
  SubCategories: {};

  Form = new FormGroup({
    productId: new FormControl(),
    status: new FormControl()
  });

  filterForm = new FormGroup({
    mastername: new FormControl(),
    majorname: new FormControl(),
    subcatname: new FormControl(),
    productname: new FormControl(),
    productid: new FormControl(),
    sort: new FormControl(),
    review: new FormControl(),
    status: new FormControl()
  });

  parentSelector: boolean = false;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private _masterProductService: ProductApiService,
    private _masterCategoryService: CategoriesApiService,
    private cf: ChangeDetectorRef, private router: Router,
    private route: ActivatedRoute, private viewScroller: ViewportScroller,
    private spinner: NgxSpinnerService) {

    this.route.queryParams.subscribe(params => {
      // console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getAllListProducts();
    })
  }
  
  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {

      this.labelBox = "Delete";

      this.Form.patchValue({
        productId: tableRow.id,
        status: tableRow.deleted_at
      });
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onChangeFood($event) {
    const id = $event.target.value;
    const isChecked = $event.target.checked;

    // console.log(id,isChecked)

    this.ProductList = this.ProductList.map((d) => {
      if (d.id == id) {
        d.select = isChecked;
        this.parentSelector = false;
        return d;
      }
      if (id == -1) {
        d.select = this.parentSelector;
        return d;
      }
      return d;
    });
    console.log(this.ProductList);
  }

  ngOnInit() {
    this.getMasterCategory();
    this.getAllListProducts();
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.product = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  getAllListProducts(): void {

    this.showSpinner();
   // const limit = CONSTANTS.PAGINATION;
    const limit = 25;
    // let limit
    // if(event == 0)
    // {
    //   limit = 23;
    // }
    // else{
    //   limit = event.target.value;
    // }

    this._masterProductService.getAllProductsLists(this.pageNo)
      .subscribe(response => {

        this.ProductList = response;
        this.paginate = this._masterProductService.getPager(this.ProductList.totalRecords, +this.pageNo);     // get paginate object from service
        this.hideSpinner();

        // this.ProductList.push({select:false});

      //  console.log(response)

      }),
      (error) => {
        //console.log(error);
      }
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('orders'); // Anchore Link
    });
  }

  // Show Master Category
  getMasterCategory() {
    this.showSpinner();
    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => {
          this.MasterCategories = data;
          this.hideSpinner();
        }
      );
  }

  // Show Major Category List on DropDown
  getMasterCategoryById(event: any) {
    this.showSpinner();
    this._masterCategoryService.getMajorCategoriesById(event.target.value)
      .subscribe(
        data => {
          this.MajorCategories = data;
          this.hideSpinner();
        }
      );
  }

  // Show Major Category List on DropDown
  getSubCategoryById(event: any) {
    this.showSpinner();
    this._masterCategoryService.getSubCategoriesById(event.target.value)
      .subscribe(
        data => {
          this.SubCategories = data;
          this.hideSpinner();
        }
      );
  }

  DeleteProduct() {
    this.showSpinner();
    var obj = {
      id: this.Form.get('productId').value,
      status: this.Form.get('status').value
    }

    if (JSON.stringify(obj.status) == 'null') {
      this._masterProductService.deleteProductData(obj)
        .subscribe(response => {
          this.hideSpinner();
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterProductService.restoreProductData(obj)
        .subscribe(response => {
          // console.log(response)
          this.hideSpinner();
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  SearchProduct() {
    this.showSpinner();
    var obj = {
      mastername: this.filterForm.get('mastername').value,
      majorname: this.filterForm.get('majorname').value,
      subcatname: this.filterForm.get('subcatname').value,
      productname: this.filterForm.get('productname').value,
      productid: this.filterForm.get('productid').value,
      sort: this.filterForm.get('sort').value,
      is_reviewed: this.filterForm.get('review').value,
      is_status: this.filterForm.get('status').value
    };

    // var products = 0;
    this._masterProductService.getProductFilter(obj)
      .subscribe(data => {
        this.ProductList = data;
        this.hideSpinner();
      },
        err => {
          // console.log(err);
        });
  }


}
