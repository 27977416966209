import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProductsRoutingModule } from './products-routing.module';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { MajorCategoryComponent } from './digital/major-category/major-category.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ProductsvariantgroupComponent } from './digital/productsvariantgroup/productsvariantgroup.component';
import { ProductsvariantComponent } from './digital/productsvariant/productsvariant.component';
import { ProductVariantGroupAddComponent } from './digital/product-variant-group-add/product-variant-group-add.component';
import { ProductVariantpAddComponent } from './digital/product-variantp-add/product-variantp-add.component';
import { ProductInventoryComponent } from './product-inventory/product-inventory.component';
import { BrandsComponent } from './brands/brands.component';
import { ProductReviewComponent } from './digital/product-review/product-review.component';
import { ProductReviewDetailComponent } from './digital/product-review-detail/product-review-detail.component';
import { SharedModule } from './../../shared/shared.module';
import { AgeGroupComponent } from './digital/age-group/age-group.component';
import { PriceRangeComponent } from './digital/price-range/price-range.component';

// import { NgxPaginationModule } from 'ngx-pagination';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [CategoryComponent, SubCategoryComponent, ProductListComponent, AddProductComponent, DigitalCategoryComponent, DigitalAddComponent, ProductDetailComponent, MajorCategoryComponent, ProductsvariantgroupComponent, ProductsvariantComponent, ProductVariantGroupAddComponent, ProductVariantpAddComponent, BrandsComponent, ProductReviewComponent, ProductReviewDetailComponent, AgeGroupComponent, PriceRangeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ProductsRoutingModule,
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    SharedModule
    // PaginationComponent
    // NgxPaginationModule,
  ],
  // providers: [
  //   {
  //     provide: DROPZONE_CONFIG,
  //     useValue: DEFAULT_DROPZONE_CONFIG,
  //   },
  //   NgbActiveModal
  // ]
})
export class ProductsModule { }
