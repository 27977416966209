import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Category', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/digital/digital-category', title: 'Master Category', type: 'link' },
				{ path: '/products/digital/major-category', title: 'Major Category', type: 'link' },
				{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
			]
		},
		{
			title: 'Age Group', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/digital/age-group', title: 'Age Group', type: 'link' },
			]
		},
		{
			title: 'Price Range', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/digital/price-range', title: 'Price Range', type: 'link' },
			]
		},
		{
			title: 'Products', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
				//{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
				{ path: '/products/digital/productsvariantgroup', title: 'Product Variant Group', type: 'link' },
				{ path: '/products/digital/productsvariant', title: 'Product Variant', type: 'link' },
				{ path: '/products/brands/brands', title: 'Product Brands', type: 'link' },
				{ path: '/products/digital/product-review', title: 'Product Review', type: 'link' },
			]
		},
		{
			title: 'Product Inventory', icon: 'bookmark', type: 'sub', active: false, children: [
				{ path: '/products/product-inventory/product-inventory', title: 'Product Inventory', type: 'link' },
			]
		},
		{
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/orders', title: 'Orders', type: 'link' },
				// { path: '/sales/transactions', title: 'Transactions', type: 'link' },
				{ path: '/sales/deliverymode', title: 'Delivery Mode', type: 'link' },
				{ path: '/sales/payment-gateway', title: 'Payment Gateway', type: 'link' },
			]
		},
		{
			title: 'ThirdParty Courier', icon: 'chrome', type: 'sub', children: [
				{ path: '/sales/book-courier-company', title: 'Book to Courier', type: 'link' },
			]
		},
		{
			title: 'Reports', icon: 'bar-chart', type: 'sub', active: false, children: [
				{ path: '/reports/orders-report', title: 'Orders Report', type: 'link' },
				{ path: '/reports/return-orders-report', title: 'Return Orders Report', type: 'link' },
				{ path: '/reports/inventory-report', title: 'Inventory Report', type: 'link' },
				{ path: '/reports/visit-products', title: 'Visit Products', type: 'link' },
				{ path: '/reports/purchase-order-report', title: 'Purchase Order', type: 'link' },
			]
		},
		{
			title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
				{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
				{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
			]
		},
		{
			title: 'Sliders', icon: 'align-left', type: 'sub', active: false, children: [
				{ path: '/localization/slider-banner', title: 'Sliders', type: 'link' },
				{ path: '/localization/slider-type', title: 'Slider Type', type: 'link' },
			]
		},
		{
			title: 'Menus', icon: 'bar-chart', type: 'sub', active: false, children: [
				{ path: '/menus/list-menu', title: 'Menu List', type: 'link' },
			]
		},
		{
			title: 'CMS', icon: 'bar-chart', type: 'sub', active: false, children: [
				{ path: '/pages/list-page', title: 'Pages List', type: 'link' },
				// { path: '/users/list-user', title: 'Admin User', type: 'link' },
			]
		},
		

		// {
		// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		
		{
			title: 'Master Data', icon: 'chrome', type: 'sub', children: [
				// { path: '/localization/translations', title: 'Translations', type: 'link' },
				// { path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
				// { path: '/localization/taxes', title: 'Taxes', type: 'link' },
				{ path: '/localization/Country', title: 'Country', type: 'link' },
				{ path: '/localization/Province', title: 'Province', type: 'link' },
				{ path: '/localization/City', title: 'City', type: 'link' },
				{ path: '/localization/Outlet', title: 'Outlet', type: 'link' },
				{ path: '/localization/Banks', title: 'Banks', type: 'link' },
				{ path: '/localization/social-media', title: 'Social Media', type: 'link' },

			]
		},

		{
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/front-customers', title: 'Front Customers', type: 'link' },
				{ path: '/users/list-user', title: 'Admin User', type: 'link' },
				{ path: '/users/permissions', title: 'Permissions', type: 'link' },
				{ path: '/users/roles', title: 'User Roles', type: 'link' },
			]
		},

		



		/*
		{
			title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		},
				///
				{
					title: 'Products', icon: 'box', type: 'sub', active: false, children: [
						{
							title: 'Physical', type: 'sub', children: [
								{ path: '/products/physical/category', title: 'Category', type: 'link' },
								{ path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
								{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
								{ path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
								{ path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
							]
						},
						{
							title: 'digital', type: 'sub', children: [
								{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
								{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
								{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
								{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
							]
						},
					]
				},
				*/

		/*
		{
			title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
				{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
				{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
			]
		},
		{
			title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
				{ path: '/pages/list-page', title: 'List Page', type: 'link' },
				{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
			]
		},
		{
			title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
		},
		{
			title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
				{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
				{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
			]
		},
		{
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'User List', type: 'link' },
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
				{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
			]
		},
		
		{
			title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		},
		{
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Profile', type: 'link' },
			]
		},
		{
			title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
		},
		
		{
			title: 'Logout', path: '/auth/login', icon: 'log-in', type: 'link', active: false
		}
		*/
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
