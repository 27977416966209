<!-- Container-fluid starts-->
<div class="container-fluid" [formGroup]="Form">
    <div class="row">
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="input-group col-md-12 input-grp-p">
                <label for="validationCustom01" class="mb-1" style="line-height: 34px;margin: 0 10px 0 0;">
                    <strong>Date:</strong></label>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="start_date"
                    #f1="ngbDatepicker" (click)="f1.toggle()">
                <span class="input-group-addon">to</span>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="end_date"
                    #f2="ngbDatepicker" (click)="f2.toggle()">
            </div>
        </div>

        <div class="col-xl-4 col-md-6 xl-50">
            <button type="button" class="btn btn-primary" (click)="getLists()">Search</button>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="overflow-x:auto;">
                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button>
                            <table id="excel-table" class="table table-striped report-tbl users">
                                <tr>
                                    <th width="8%" class="center-align left-tbl">#</th>
                                    <th width="10%" class="center-align">Image</th>
                                    <th width="72%" class="center-align">Product Name</th>
                                    <th width="10%" class="center-align">Visit Count</th>
                                </tr>
                                <ng-container *ngFor="let list of ReportList.products; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">
                                            <img [src]="list.product_images[0].url" class="mr-2"
                                                style="width: 43px">
                                        </td>
                                        <td class="center-left">{{list.name}}</td>
                                        <td class="center-align">{{list.count}}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <app-pagination [products]="ReportList.orders" [paginate]="paginate"
                                (setPage)="setPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->