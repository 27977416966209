import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class OutletApiService {

  constructor(
    private http: HttpClient
  ) { }

  getAllListOutlets() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/outlets', option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        //  catchError()
      )
  }



}
