<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <!-- <div class="col-sm-12" [formGroup]="permissionForm"> -->
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add Role</h5>
                </div>

                <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">

                    <div class="card-body tab2-card">
                        <div class="form-group row">
                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                Role Name</label>
                            <input class="form-control col-xl-8 col-md-7" formControlName="rolename" type="text"
                            (keypress)="special_char($event)" required="">
                        </div>
                        <ngb-tabset class="tab-coupon">
                            <ngb-tab>
                                <ng-template ngbTabTitle>Permission
                                    <ng-template ngbTabContent>

                                        <!-- <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                Permission Name</label>
                                            <select class="form-control col-xl-8 col-md-7" id="permissionname"
                                                formControlName="permissionname" (change)="getPermissionById($event)"
                                                type="search" contenteditable="true">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Permission ---
                                                </option>
                                                <option *ngFor="let List of PermissionObjList;" [value]="List.id">
                                                    {{List.name | uppercase}}
                                                </option>
                                            </select>
                                        </div> -->


                                        <form [formGroup]="permissionListForm">

                                            <!-- <div class="attribute-blocks {{divLabelCondition}}"
                                                    *ngFor="let m of PermissionObjList2;">
                                                    <input type="checkbox" formControlName="precheck"
                                                        (change)="addPermission(m.id, m.name, $event.target.checked)" />
                                                    {{m.name}}
                                                </div> -->








                                            <div class="needs-validation user-add" novalidate="">
                                                <div class="permission-block">
                                                    <div class="attribute-blocks "
                                                        *ngFor="let List of PermissionObjList;">
                                                        <!-- <span *ngIf="List.name != null; else elseBlock_delete"> -->
                                                        <h5 class="f-w-600 mb-3" *ngIf="List.name != null">
                                                            {{List.name}}
                                                        </h5>
                                                        <!-- </span>
                                                            <ng-template #elseBlock_delete></ng-template> -->
                                                        <div class="row hide{{List.id}}">
                                                            <div class="col-xl-9 col-sm-8 ">
                                                                <input type="checkbox" formControlName="precheck"
                                                                    (change)="addPermission(List.id, List.name2, $event.target.checked)" />
                                                                {{List.name2}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
<br><br><br>

                                            <span>
                                                <table id="users" class="var-tbl">
                                                    <tr>
                                                        <th width="20%" class="center-align">Permission Name</th>
                                                        <th width="5%" class="center-align">Action</th>
                                                    </tr>
                                                    <tr *ngFor="let m of Per_List; let i = index;">
                                                        <td class="bnm">
                                                            {{m.permissionName}}
                                                        </td>
                                                        <td class="bnm" style="display: none;">
                                                            {{m.permissionId}}
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-primary pull-right"
                                                                (click)="deleteRow(i)"
                                                                style='cursor: pointer;'>Remove</button>
                                                        </td>
                                                    </tr>
                                                    <!-- </tbody> -->
                                                </table>

                                            </span>


                                        </form>







                                        <!-- <div class="needs-validation user-add" novalidate="">

                                        <div class="permission-block">


                                            <div class="attribute-blocks" *ngFor="let List of PermissionObjList;">
                                                <span *ngIf="List.name != null; else elseBlock_delete">
                                                    <h5 class="f-w-600 mb-3" *ngIf="List.name != null">{{List.name}}
                                                    </h5>
                                                </span>
                                                <ng-template #elseBlock_delete></ng-template>
                                                <div class="row hide{{List.id}}">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>{{List.name2}}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8 ">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani1">
                                                                <input class="radio_animated " id="edo-ani1" 
                                                                    type="radio" name="{{List.name2}}" value="{{List.id}}">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani2">
                                                                <input class="radio_animated" id="edo-ani2" type="radio"  
                                                                    name="{{List.name2}}" value="0">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>







                                            
                                        </div>
                                    </div> -->
                                    </ng-template>
                                </ng-template>
                            </ngb-tab>
                        </ngb-tabset>
                        <div class="pull-right">
                            <!-- <button type="button" class="btn btn-primary" (click)="CreateRole()">Save</button> -->
                            <!-- <button type="button" class="btn btn-primary" >Save</button> -->
                            <input type="submit" value="Send">

                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->