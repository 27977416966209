import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class CategoriesApiService {

  ImageBaseData: string | ArrayBuffer = null;

  constructor(
    private http: HttpClient
  ) { }

  // API File

  // ======= Master Category =======

  getAllMasterCategoriesList() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/major-categories', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  getMaterCategoriesById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(obj);

    return this.http
      .get(CONSTANTS.BASE_URL + '/master-categories/' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  getAllCategories() {
    let option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http
      .get(CONSTANTS.BASE_URL + '/master-categories', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  insertCategoryData(obj): Observable<any> {
    let option  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/master-categories', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Update Master Category
  updateCategoryData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    obj['_method'] = 'PUT';
    return this.http
      .post(CONSTANTS.BASE_URL + '/master-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Delete Master Category
  deleteCategoryData(obj): Observable<any> {
    // console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/master-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Restore Master Category
  restoreCategoryData(obj): Observable<any> {
    console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
     // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/master-categories/'+ obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // ======= Major Category =======

  getMasterCategories() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/master-categories', option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        //  catchError()
      )
  }


// Get Major Category by ID
  getMajorCategoriesById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/master-categories/' + obj + '/major-categories', option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        //  catchError()
      )
  }


  insertMajorCategoryData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/major-categories', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Update Major Category
  updateMajorCategoryData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    obj['_method'] = 'PUT';
    
    return this.http
      .post(CONSTANTS.BASE_URL + '/major-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Delete Master Category
  deleteMajorCategoryData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    // const obj = {
    //   '_method':'DELETE'
    // };
    
    obj['_method'] = 'DELETE';
    
    return this.http
      .post(CONSTANTS.BASE_URL + '/major-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Restore Major Category
  restoreMajorCategoryData(obj): Observable<any> {
    console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
     // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/major-categories/'+ obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // ======= Sub Category =======

  getSubCategories() {
    let option = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http
      .get(CONSTANTS.BASE_URL + '/sub-categories', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  getAllSubCategoriesList() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/sub-categories', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  // Get Sub Category by ID
  getSubCategoriesById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/major-categories/' + obj + '/sub-categories', option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  insertSubCategoryData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/sub-categories', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Update Sub Category
  updateSubCategoryData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    obj['_method'] = 'PUT';
    
    return this.http
      .post(CONSTANTS.BASE_URL + '/sub-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Delete Master Category
  deleteSubCategoryData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/sub-categories/'+ obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Restore Major Category
  restoreSubCategoryData(obj): Observable<any> {
    console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    
     // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/major-categories/'+ obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }




}
