<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Add Menu</h5>
                </div>
                <div class="card-body" [formGroup]="Forms">
                    <input type="hidden" formControlName="menuId" value="{{menuId}}">
                    <div class="form-group row">
                        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span> Menu Name</label>
                        <input class="form-control col-md-8" id="validationCustom0" type="text" formControlName="name"
                            required (keypress)="special_char($event)">
                    </div>

                    <div class="form-group row">
                        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span> URL</label>
                        <input class="form-control col-md-8" id="validationCustom0" type="text" formControlName="url"
                            required="">
                    </div>

                    <div class="form-group row">
                        <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span> Menu Type</label>
                        <select class="form-control select2 select2-hidden-accessible col-xl-3 col-md-4" id="status"
                            formControlName="type">
                            <option value="" class="text-primary font-weight-bolder">
                                --- Select Menu Type ---
                            </option>
                            <option value="0">Front End</option>
                            <option value="1">Admin Panel</option>
                        </select>
                    </div>
                    <!-- <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Status</label>
                            <div class="checkbox checkbox-primary col-xl-9 col-md-8">
                                <input id="checkbox-primary-2" type="checkbox" data-original-title="" title="">
                                <label for="checkbox-primary-2">Enable the Menu</label>
                            </div>
                        </div> -->
                    

                    <div class="col-xl-6">
                        <span *ngIf="menuId == null else elseBlock_update">
                            <button type="button" class="btn btn-primary" (click)="Create()">Save</button>
                        </span>
                        <ng-template #elseBlock_update>
                            <button type="button" class="btn btn-primary" (click)="Update()">Update</button>
                        </ng-template>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->