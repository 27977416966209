import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { orderDB } from "../../../shared/tables/order-list";
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ActivatedRoute, Router } from '@angular/router';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public order = [];
  public temp = [];
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only

  public model: NgbDateStruct;
  public date: { year: number, month: number };
  orderList: any = {}

  paymentGateway: any = {};
  deliveryMode: any = {};
  orderStatus: any = {};

  filterForm = new FormGroup({
    order_number: new FormControl(),
    payment_gateway: new FormControl(),
    delivery_mode: new FormControl(),
    order_start_date: new FormControl(),
    order_end_date: new FormControl(),
    tracking_number: new FormControl(),
    order_status: new FormControl(),
    phone: new FormControl(),
    page: new FormControl(),
  });

  title = 'angular-app';
  fileName= 'Orders.xlsx';


  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(private modalService: NgbModal, private http: HttpClient,
    private _orderService: OrderApiService, private route: ActivatedRoute,
    private router: Router, private productService: ProductApiService, private calendar: NgbCalendar,
    private viewScroller: ViewportScroller) {

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getAllOrderLists();
    })

    //  this.order = orderDB.list_order;
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.order = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit() {
    this.getAllOrderLists();
    this.getPaymentGateway();
    this.getDeliveryMode();
    this.getOrderStatus();
  }


  getAllOrderLists() {
    //let limitcount = event.target.value
    //console.log(limitcount)
    var obj = {
      order_number: this.filterForm.get('order_number').value,
      payment_gateway: this.filterForm.get('payment_gateway').value,
      delivery_mode: this.filterForm.get('delivery_mode').value,
      order_start_date: this.filterForm.get('order_start_date').value,
      order_end_date: this.filterForm.get('order_end_date').value,
      order_status: this.filterForm.get('order_status').value,
      tracking_number: this.filterForm.get('tracking_number').value,
      phone: this.filterForm.get('phone').value
    };
    console.log(obj)

    this._orderService.getAllOrderFilterLists(this.pageNo, obj)
      .subscribe(
        data => {
          this.orderList = data;
          this.paginate = this.productService.getPager(this.orderList.totalRecords, +this.pageNo);     // get paginate object from service
        }
      );
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('orders'); // Anchore Link
    });
  }

  getDeliveryMode() {
    this._orderService.getAllDeliveryModeLists()
      .subscribe(
        data => {
          this.deliveryMode = data;
        }
      );
  }

  getOrderStatus() {
    this._orderService.getOrderStatusLists()
      .subscribe(
        data => {
          this.orderStatus = data;
        }
      );
  }

  getPaymentGateway() {
    this._orderService.getAllPaymentGatewayLists()
      .subscribe(
        data => {
          this.paymentGateway = data;
        }
      );
  }

  ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    console.log(input);
    return numeric;
  }

  exportexcel(): void
  {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const year = currentDate.getFullYear();
    this.fileName = `Orders-${day}-${month}-${year}.xlsx`;

    let element = document.getElementById('excel-table');
    // Create the workbook and worksheet (Assuming you have the appropriate data)
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName  );
  }



}
