import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.scss']
})
export class OrdersReportComponent implements OnInit {

  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  ReportList: any = {};
  API: string = "orders-report";

  title = 'angular-app';
  fileName= 'Order-Report.xlsx';

  Form = new FormGroup({
    start_date: new FormControl(),
    end_date: new FormControl(),
  });

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(private http: HttpClient, private _masterService: MasterApiService,
    private route: ActivatedRoute, private router: Router,
    private productService: ProductApiService, private viewScroller: ViewportScroller,
    private calendar: NgbCalendar) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
    //  this.getLists();
    })
  }

  ngOnInit(): void {
   // this.getLists();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  getLists() {
    var obj = {
      start_date: this.Form.get('start_date').value,
      end_date: this.Form.get('end_date').value,
    }

    this._masterService.getAllLists(this.API, this.pageNo, obj)
      .subscribe(
        data => {
          this.ReportList = data;
          this.paginate = this.productService.getPager(this.ReportList.totalRecords, +this.pageNo);
        }
      );
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('orders'); // Anchore Link
    });
  }

  exportexcel(): void
  {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'short' });
    const year = currentDate.getFullYear();
    this.fileName = `Order-Report-${day}-${month}-${year}.xlsx`;

    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
  }

}

