<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">

                    <!-- Delete Div -->
                    <ng-template #delcontent let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete Front Customer</h5>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body" [formGroup]="Form">
                            <input formControlName="userId" type="hidden">
                            <input formControlName="status" type="hidden">
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="DeleteFrontCustomer()">Yes</button>
                            <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                (click)="modal.dismiss('Cross click')">No</button>
                        </div>
                    </ng-template>

                    
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">

                            <!-- <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                                <div class="table-responsive">
                                    <app-front-customers></app-front-customers>
                                </div>
                            </div> -->

                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="15%" class="center-align">First Name</th>
                                    <th width="15%" class="center-align">Last Name</th>
                                    <th width="20%" class="center-align">Email</th>
                                    <th width="10%" class="center-align">Verified</th>
                                    <th width="10%" class="center-align">Status</th>
                                    <th width="5%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let list of UserList; index as i">
                                    <tr class="left-align">
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>

                                        <td>{{list.name}}</td>
                                        <td>{{list.last_name}}</td>
                                        <td>{{list.email}}</td>
                                        <td class="center-align">
                                            <div *ngIf="list.is_email_verified == 0; else elseVerifiedBlock">
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">Not Verified</span></span>
                                            </div>
                                            <ng-template #elseVerifiedBlock>
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Verified</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <div *ngIf="list.deleted_at == null; else elseBlock">
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span"
                                                *ngIf="list.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, list)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, list)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>