import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { digitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ResourceLoader } from '@angular/compiler';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Router, ActivatedRoute } from "@angular/router";
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';


@Component({
  selector: 'app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss']
})
export class PriceRangeComponent implements OnInit {

  ImageBaseData: string | ArrayBuffer = null;

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultUpload: DataUrl = '';
  localUrl: any;
  returnUrl: string;
  base64textString = [];
  API: string = "price-ranges";

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []
  modalContent: undefined

  labelBox: string;
  labelImg: string;

  catForm = new FormGroup({
    name: new FormControl(),
    min: new FormControl(),
    max: new FormControl(),
    img: new FormControl(),
    id: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  Groups: {};

  constructor(private modalService: NgbModal, private http: HttpClient,
    private _masterService: MasterApiService,
    private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef) {
    this.digital_categories = digitalCategoryDB.digital_category;

    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.catForm.patchValue({
        name: tableRow.group_name,
        min: tableRow.minRange,
        max: tableRow.maxRange,
        id: tableRow.id,
        status: tableRow.deleted_at
      });
    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.catForm.patchValue({
        name: null,
        id: null,
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getAgeGroup();
  }

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 100, 100).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  // ======= List Of Age Group ======= 
  getAgeGroup() {
    this._masterService.getAllListsFromAPI(this.API)
      .subscribe(
        data => this.Groups = data
      );
  }

  //  ======= Age Group Edit  =======  
  getAgeGroupById(event: any) {
    this._masterService.recordByIdAPI(this.API,event)
      .subscribe(response => {
        // console.log(response)
      });
  }

  //  ======= Add Age Group ======= 
  CreateAgeGroup() {

    if (this.catForm.valid) {
      var obj = {
        group_name: this.catForm.get('name').value,
        minRange: this.catForm.get('min').value,
        maxRange: this.catForm.get('max').value,
        media_string: this.localCompressedURl
      }

      this._masterService.insertAPI(this.API,obj)
        .subscribe(response => {
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }

  UpdateAgeGroup() {
    if (this.catForm.valid) {
      if (this.ImageBaseData == null) {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: ""
        }
      }
      else {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: this.ImageBaseData.toString()
        }
      }
      var obj = {
        group_name: this.catForm.get('name').value,
        minRange: this.catForm.get('min').value,
        maxRange: this.catForm.get('max').value,
        id: this.catForm.get('id').value,
        media_string: this.localCompressedURl
      }

      console.log(obj)

      this._masterService.updateAPI(this.API,obj)
        .subscribe(response => {
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }


  DeleteAgeGroup() {
    var obj = {
      id: this.catForm.get('id').value,
      status: this.catForm.get('status').value
    }

   // console.log(obj)

    if (JSON.stringify(obj.status) == 'null') {
      this._masterService.deleteAPI(this.API,obj)
        .subscribe(response => {
         // console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
    else {
      this._masterService.restoreAPI(this.API,obj)
        .subscribe(response => {
          // console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }
}

export class FileUplodVM {
  ImageBaseData: string;
}
