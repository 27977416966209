import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(
    private http: HttpClient
  ) { }


  // List of Master API
  getAllLists(string) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + string, option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  // getSingleDataById(string, obj): Observable<any> {
  //   let option = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
  //     })
  //   };

  //   return this.http
  //     .get(CONSTANTS.BASE_URL + '/' + string + '/' + obj, option)
  //     .pipe(
  //       map(res => {
  //         //  console.log(res)
  //         return res;
  //       }),
  //       catchError(this.handleError)
  //     )
  // }

  // Insert Master API
  insertAPI(string, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Update Master API
  updateAPI(string, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Delete Master API
  deleteAPI(string, obj): Observable<any> {
    // console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Restore Master API
  restoreAPI(string, obj): Observable<any> {
    console.log("ID:" + obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // ======== Handle Error Start ========

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      //errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = `Error Code: ${error.status}`;
    }
    //console.log(errorMessage);
    window.alert(errorMessage);
    return throwError(() => {
      return;
    });
  }

  // ======== Handle Error End ========


}

