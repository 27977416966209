import { Component, OnInit } from '@angular/core';
import { digitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SlidersApiService } from 'src/app/shared/service/sliders-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ResourceLoader } from '@angular/compiler';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';


@Component({
  selector: 'app-slider-banner',
  templateUrl: './slider-banner.component.html',
  styleUrls: ['./slider-banner.component.scss']
})
export class SliderBannerComponent implements OnInit {

  API: string = "sliders";
  APIs: string = "slider-types";
  ImageBaseData: string | ArrayBuffer = null;
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultUpload: DataUrl = '';
  localUrl: any;

  base64textString = [];

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []
  modalContent: undefined

  labelBox: string;
  labelImg: string;

  Form = new FormGroup({
    name: new FormControl(),
    img: new FormControl(),
    url: new FormControl(),
    type: new FormControl(),
    id: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  List: {};
  siderTypeList: {};

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private _masterSliderService: SlidersApiService,
    private imageCompress: NgxImageCompressService) {
    this.digital_categories = digitalCategoryDB.digital_category;
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      //console.log(this.modalContent)
      // console.log(CONSTANTS.IMG_URL + '/' + tableRow.url)

      this.labelBox = "Edit";
      this.labelImg = tableRow.image;
      // this.Id = tableRow.id;

      this.Form.patchValue({
        name: tableRow.name,
        id: tableRow.id,
        type: tableRow.slider_type_id,
        url: tableRow.link,
        status: tableRow.deleted_at
      });

      //console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        name: null,
        id: null,
        //catimg: tableRow.url
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getSliderBanner();
    this.getSliderType();
  }

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 100, 100).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }



  // ======= List Of Master Category ======= 
  getSliderBanner() {
    this._masterSliderService.getSliderBanner(this.API)
      .subscribe(
        data => this.List = data
      );
  }

  getSliderType() {
    this._masterSliderService.getSliderType(this.APIs)
      .subscribe(
        data => {
          this.siderTypeList = data;
        }
      );
  }

  //  ======= Master Category Edit  =======  
  getMasterDataById(event: any) {
    this._masterSliderService.getSliderBannerById(this.API, event)
      .subscribe(response => {
        //console.log(response)
      });
  }

  //  ======= Add Master Category ======= 
  Create() {

    if (this.Form.valid) {
      if (this.localCompressedURl == null) {
        return alert("Select Image");
      }

      var obj = {
        name: this.Form.get('name').value,
        slider_type_id: this.Form.get('type').value,
        link: this.Form.get('url').value,
        image: this.localCompressedURl
      }

      this._masterSliderService.insertData(this.API, obj)
        .subscribe(response => {
          //console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  Update() {
    //console.log("UPDATE")
    if (this.Form.valid) {
      // if (this.localCompressedURl == null) {
      //   return alert("Select Image");
      // }

      var obj = {
        name: this.Form.get('name').value,
        slider_type_id: this.Form.get('type').value,
        link: this.Form.get('url').value,
        image: this.localCompressedURl,
        id: this.Form.get('id').value
      }
      //console.log(obj)

      this._masterSliderService.updateData(this.API, obj)
        .subscribe(response => {
          //console.log(response);
         this.modalService.dismissAll();
         window.location.reload();
        });
    }
  }

  Delete() {
    var obj = {
      id: this.Form.get('id').value,
      status: this.Form.get('status').value
    }

    //console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterSliderService.deleteData(this.API, obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterSliderService.restoreData(this.API, obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}

export class FileUplodVM {
  ImageBaseData: string;
}

