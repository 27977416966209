import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  errorMsg: string;

  constructor(
    private http: HttpClient
  ) { }


  // ============ Front Customer Start ============

  // List of Front Customers
  getAllListFrontCustomers() {
    // const data = [];
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/users', option)
      .pipe(
        map(res => {
          //console.log(res);
          // data.push(res);
          // return data;
          return res;
        }),
        //  catchError()
      )
  }

  // Delete Product Variant Group
  deleteFrontCustomerData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/users/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  restoreFrontCustomerData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/users/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  getAllLists(string) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + string, option)
      .pipe(
        map(res => {
          //console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  getDataById(api, link, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + api + link + obj, option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  getEmailCheck(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin-users/email?q=' + obj, option)
      .pipe(
        map(res => {
             console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  // Add Role
  createAdminUser(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/admin-users', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  updateAdminUser(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';
    
    return this.http
      .post(CONSTANTS.BASE_URL + '/admin-users/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  changePasswordForAdminUser(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

   // obj['_method'] = 'PUT';
    
    return this.http
      .post(CONSTANTS.BASE_URL + '/admin-users/change-password', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  getSingleAdminUserById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin-users/' + obj, option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  getProfileById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin-users/' + obj, option)
      .pipe(
        map(res => {
          return JSON.stringify(res);
        }),
        //  catchError()
      )
  }

  // ============ Front Customer Start ============


  // ============ Admin User Start ============

  // List of Admin User
  getAllListAdminUser() {
    // const data = [];
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin-users', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  // Delete Admin User
  deleteAdminUserData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/admin-users/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Restore Admin User
  restoreAdminUserData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/admin-users/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // ============ Admin User Start ============

  // ============ Admin User Permission Start ============

  // List of Admin User
  getAllAdminUserPermissionList() {
    // const data = [];
    // console.log(data);
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/permission-groups', option)
      .pipe(
        map(res => {
          return JSON.stringify(res);
        }),
        //  catchError()

        // catchError(
        //   error => {
        //     if (error.error instanceof ErrorEvent) {
        //       this.errorMsg = `Error: ${error.error.message}`;
        //     } else {
        //       this.errorMsg = `Error: ${error.message}`;
        //     }
        //     return of([]);

        //   })

      )
  }


  getPermissionbyIdList(obj): Observable<any> {
    // const data = [];
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/permission-groups/' + obj, option)
      .pipe(
        map(res => {
          // console.log(JSON.stringify(res));
          return JSON.stringify(res);
        }),
        //  catchError()
      )
  }

  // List of Admin Permission
  getAllAdminUserPermissionLists() {
    // const data = [];
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/permission-groups', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }


  // Add Permission
  insertPermissionData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/permission-groups', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Update Permission
  updatePermissionData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/permission-groups/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // ============ Admin User Role End ============


  // ============ Admin User Role Start ============

  // List of Admin User
  getAllAdminUserRoleList() {
    // const data = [];
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/roles', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  // Add Role
  insertRoleData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/roles', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // ============ Admin User Role End ============

}
