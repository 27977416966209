class Constants {
    public BASE_URL: string;
    public USER_TOKEN: string;
    public IMG_URL: string;
    public TBL_HEADER: string;
    public WEBSITE_NAME: string;
    public PAGINATION: number;
}

export const CONSTANTS: Constants = {
    // BASE_URL: "https://api.bazaario.pk/api",
    // IMG_URL: "https://api.bazaario.pk/storage",

    BASE_URL: "https://apiportal.kidznteen.pk/api",
    IMG_URL: "https://apiportal.kidznteen.pk/storage",
    TBL_HEADER: "#000",
    //WEBSITE_NAME: "Bazaario",
    WEBSITE_NAME: "KidznTeen",
    USER_TOKEN: "",
    PAGINATION: 300
}