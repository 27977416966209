import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  constructor(
    private http: HttpClient
  ) { }


  // API File

  getDashboardData(obj) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    if (obj.start_date == undefined) {
      obj.start_date = "";
    }
    else {
      obj.start_date = obj.start_date.year + '-' + obj.start_date.month + '-' + obj.start_date.day;
    }

    if (obj.end_date == undefined) {
      obj.end_date = "";
    }
    else {
      obj.end_date = obj.end_date.year + '-' + obj.end_date.month + '-' + obj.end_date.day;
    }

    //console.log(CONSTANTS.BASE_URL + '/dashboard?start_date=' + obj.start_date+ '&end_date=' + obj.end_date);

    return this.http
      .get(CONSTANTS.BASE_URL + '/dashboard?start_date=' + obj.start_date + '&end_date=' + obj.end_date, option)
      .pipe(
        map(res => {
          //console.log(res)
          return JSON.stringify(res);
        }),
        //  catchError()
      )
  }

  getNotificationData() {

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/order-notifications', option)
      .pipe(
        map(res => {
          console.log(res)
          return res;
        }),
        //catchError(this.handleError)
      )
  }

  




  /*
    postData(obj): Observable<any> {
      let option = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3NqbWNhcmdvLmNvbS9zaGFoL3B1YmxpYy9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTY0MjQ0MDI2MSwiZXhwIjoxNjQyNDQzODYxLCJuYmYiOjE2NDI0NDAyNjEsImp0aSI6IlZ4a1pJUW0wZGx1RktBeDciLCJzdWIiOiIxIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.KXGtGQa7st131eJfMh4olmJosSkGmNcygyKIseaOBK4`
          //'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
        })
      };
  
      console.log(JSON.stringify(obj));
  
      return this.http
        .post(CONSTANTS.BASE_URL+'/master-categories', JSON.stringify(obj), option)
        .pipe(
          map(res => {
            return res;
          }),
          //catchError(this.global.handleError)
        )
    }
  */
}
