<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" [formGroup]="catForm">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-secondary" data-toggle="modal"
                            data-original-title="Add Master Category" data-target="#exampleModal"
                            (click)="open(content, 0)">Add Age Group</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Age Group</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" >
                                <!-- <div class="modal-body" [formGroup]="catForm"> -->
                                <input formControlName="id" type="hidden">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Age Group Name:</label>
                                        <input class="form-control" id="name" formControlName="name" type="text"
                                            placeholder="Age Group Name">
                                    </div>
                                    <div class="form-group mb-0">
                                        <label for="validationCustom02" class="mb-1">Image :</label>
                                        <input type="file" accept=".jpg,.png,.jpeg" id="image" name="image" (change)="selectFile($event)">
                                        <img src="{{labelImg}}" class="mr-2" style="width: 30px">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div *ngIf="labelBox== 'Edit'; else elseBlock">
                                    <button type="button" class="btn btn-primary"
                                        (click)="UpdateAgeGroup()">Update</button>
                                </div>

                                <ng-template #elseBlock>
                                    <button type="button" class="btn btn-primary"
                                        (click)="CreateAgeGroup()">Save</button>
                                </ng-template>

                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>

                        <!-- Delete Div -->
                        <ng-template #delcontent let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete Age Group</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                                <div class="modal-body" >
                                <input formControlName="id" type="hidden">
                                <input formControlName="status" type="hidden">
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="DeleteAgeGroup()">Yes</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">No</button>
                            </div>
                        </ng-template>
                    </div>

                    <!-- <div class="btn-popup pull-right">

                        <ng-template #contentEdit let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Edit Age Group</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                                <div class="modal-body" >
                                <div class="form">
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Age Group Name:</label>
                                        <input class="form-control" id="validationCustom01" formControlName="catname"
                                            type="text" value="" placeholder="Master Category Name">
                                    </div>
                                    <div class="form-group mb-0">
                                        <label for="validationCustom02" class="mb-1">Image :</label>
                                        <input type="file" accept=".jpg,.png,.jpeg" id="catImage" name="catImage" (change)="selectFile($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="CreateAgeGroup()">Save</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>
                    </div> -->


                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="10%" class="center-align">Image</th>
                                    <th width="50%">Age Group Name</th>
                                    <th width="10%" class="center-align">Status</th>
                                    <th width="20%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let Data of Groups; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">
                                            <img [src]="CONSTANTS.IMG_URL +'/'+ Data.url" class="mr-2"
                                                style="width: 30px">
                                        </td>
                                        <td>{{Data.group_name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="Data.deleted_at == null; else elseBlock">
                                                <span title="Active" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="InActive" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span"
                                                *ngIf="Data.deleted_at == null;">
                                                <a (click)="open(content, Data)" class="badge badge-success">
                                                    Edit
                                                </a>
                                            </span>
                                            <span class="space-span"
                                                *ngIf="Data.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, Data)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, Data)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>


                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->