import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit {

  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultUpload: DataUrl = '';
  localUrl: any;

  base64textString = [];

  modalContent: undefined
  labelBox: string;
  labelImg: string;
  BankList: any = {};
  API: string = "banks";

  Form = new FormGroup({
    name: new FormControl(),
    Id: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, 
    private _masterService: MasterApiService, private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute, private router: Router,
    private productService: ProductApiService, private viewScroller: ViewportScroller) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getLists();
    })
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    //console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";

      this.Form.patchValue({
        name: tableRow.name,
        Id: tableRow.id,
        status: tableRow.deleted_at
      });

      //console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.Form.patchValue({
        name: null,
        Id: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  ngOnInit(): void {
    this.getLists();
  }

  getLists() {
    this._masterService.getAllLists(this.API,this.pageNo,0)
      .subscribe(
        data => {
          this.BankList = data;
          this.paginate = this.productService.getPager(this.BankList.totalRecords, +this.pageNo);
        }
      );
  }

  Create() {
    if (this.Form.get('name').value == null) {
      alert('Enter Bank Name');
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('name').value,
        media_string: this.localCompressedURl
      }

      //console.log(obj);

      this._masterService.insertAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response);
          window.location.reload();
        });
    }
  }

  Update() {
    if (this.Form.valid) {

      var obj = {
        id: this.Form.get('Id').value,
        name: this.Form.get('name').value
      }

      this._masterService.updateAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  Delete(){
    var obj = {
      id: this.Form.get('CountryId').value,
      status: this.Form.get('status').value
    }

    if (JSON.stringify(obj.status) == 'null') {
      this._masterService.deleteAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterService.restoreAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}
