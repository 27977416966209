import { Component, OnInit } from '@angular/core';
import { digitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []
  modalContent: undefined

  labelBox: string;
  labelImg: string;

  Form = new FormGroup({
    catname: new FormControl(),
    catimg: new FormControl(),
    catId: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  RoleList: {};

  constructor(private modalService: NgbModal, 
    private http: HttpClient, 
    private _masterUserService: UsersApiService) {
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      console.log(this.modalContent)

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.Form.patchValue({
        catname: tableRow.name,
        catId: tableRow.id,
        status: tableRow.deleted_at
      });

      console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        catname: null,
        catId: null,
        //catimg: tableRow.url
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getRolesList();
  }

  // ======= List Of Role ======= 
  getRolesList() {
    this._masterUserService.getAllAdminUserRoleList()
      .subscribe(
        data => this.RoleList = data
      );
  }

}

