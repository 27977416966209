import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class SlidersApiService {

  constructor(private http: HttpClient) { }


  getSliderType(API) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + API, option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        //  catchError()
      )
  }

  // ======= Slider Banner =======

  getSliderBanner(API) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + API, option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        //  catchError()
      )
  }


  // Get Major Category by ID
  getSliderBannerById(API, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + API + '/' + obj + '/sliders', option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        //  catchError()
      )
  }


  insertData(API, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + API, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Update Major Category
  updateData(API, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + API + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Delete Master Category
  deleteData(API, obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + API + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // Restore Major Category
  restoreData(API, obj): Observable<any> {
    console.log("ID:" + obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + API + '/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }




}
