import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-product-inventory',
  templateUrl: './product-inventory.component.html',
  styleUrls: ['./product-inventory.component.scss']
})

export class ProductInventoryComponent implements OnInit {

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  prodinventories: any[];
  // ProductList: {};
  ProductList: any = {}
  ProductDropDownList: {};
  ProductVariantGroupList: {};
  ProductVariantList: {};
  MasterCategories: {};
  MajorCategories: {};
  SubCategories: {};


  AddForm = new FormGroup({
    productname: new FormControl(),
    variantgroupname: new FormControl(),
    variantname: new FormControl(),
    stockin: new FormControl(),
    purchaseprice: new FormControl()

  });

  Form = new FormGroup({
    stockout: new FormControl(),
    checkbox: new FormControl(),
    checkArray: this.fb.array([], [Validators.required]),
    //stockout: this.fb.array([], [Validators.required])
  });

  filterForm = new FormGroup({
    mastername: new FormControl(),
    majorname: new FormControl(),
    subcatname: new FormControl(),
    productname: new FormControl(),
    review: new FormControl(),
    pname: new FormControl()
  });

  constructor(private modalService: NgbModal, private fb: FormBuilder,
    private http: HttpClient,
    private _masterProductService: ProductApiService,
    private _masterCategoryService: CategoriesApiService,
    private renderer: Renderer2,
    el: ElementRef, private spinner: NgxSpinnerService) {

    this.Form.get('stockout').value == "0";

    this.Form = this.fb.group({
      // username: ['superadmin@gmail.com', Validators.required],
      // password: ['12345678', Validators.required]

      stockout: ['0', Validators.required],
    });

    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );

  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  CheckAllOptions() {
    if (this.prodinventories.every(val => val.checked == true))
      this.prodinventories.forEach(val => { val.checked = false });
    else
      this.prodinventories.forEach(val => { val.checked = true });
  }

  // hideSpinner() {
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 1500);
  // }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getMasterCategory();
    //this.getAllListProducts();
    this.getAllListProductVariantGroup();
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement(),
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  // ====== Filter List Start ====== 

  getAllListProducts() {
    this._masterProductService.getAllProductsList()
      .subscribe(data => {
        this.ProductList = data;
        // console.log(data.products)
      },
        err => {
          console.log(err);
        });
  }

  // Show Master Category
  getMasterCategory() {
    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => this.MasterCategories = data
      );
  }

  // Show Major Category List on DropDown
  getMasterCategoryById(event: any) {
    this.showSpinner();
    this._masterCategoryService.getMajorCategoriesById(event.target.value)
      .subscribe(
        data => {
          this.MajorCategories = data,
            this.hideSpinner();
        }
      );
  }

  // Show Major Category List on DropDown
  getSubCategoryById(event: any) {
    this.showSpinner();
    this._masterCategoryService.getSubCategoriesById(event.target.value)
      .subscribe(
        data => {
          this.SubCategories = data
          this.hideSpinner();
        }
      );
  }

  getCategoriesByProducts(event: any) {
    this.showSpinner();
    var obj = {
      mastername: this.filterForm.get('mastername').value,
      majorname: this.filterForm.get('majorname').value,
      subcatname: this.filterForm.get('subcatname').value
    };

    this._masterProductService.getCategoriesByProductsFilter(obj)
      .subscribe(data => {
        this.hideSpinner();
        this.ProductList = data;
        //console.log(data)
      },
        err => {
          //console.log(err);
        });

  }

  // ====== Filter List End ====== 


  // ====== POP Product DropDown List ====== 
  // getAllListProducts() {
  //   this._masterProductService.getAllProductsList()
  //     .subscribe(
  //       data => this.ProductList = data
  //     );
  // }

  getAllProductInventories() {
    this._masterProductService.getAllProductInventoriesList()
      .subscribe(
        // data => this.prodinventories = data
        data => this.ProductList = data
      );
  }

  getAllListProductVariantGroup() {
    this._masterProductService.getAllListProductVariantGroup()
      .subscribe(
        data => this.ProductVariantGroupList = data
      );
  }

  // getAllListProductVariant() {
  //   this._masterProductService.getAllListProductVariant()
  //     .subscribe(
  //       data => this.ProductVariantList = data
  //     );
  // }

  getProductVariantGroupById(event: any) {
    this._masterProductService.getProductVariantGroupById(event.target.value)
      .subscribe(
        data => this.ProductVariantList = data
      );
  }

  // ====== POP Product List ====== 
  SearchProduct(event: any) {
    this.showSpinner();
    var productId = this.filterForm.get('pname').value;
    this.Form.get('stockout').value == 0;
    this._masterProductService.getAllProductInventoriesListById(productId)
      .subscribe(
        data => {
          //console.log(data)
          this.prodinventories = data;

          var prodData = {
            productname: data[0].product_id,
          }
          this.AddForm.patchValue(prodData);
          this.AddForm.get('productname').disable();
          this.hideSpinner();
        }
      );
  }


  // onCheckboxChange(e: any) {
  //   const checkArray: FormArray = this.Form.get('checkArray') as FormArray;
  // //  const stockout: FormArray = this.Form.get('stockout') as FormArray;
  //   if (e.target.checked) {
  //     checkArray.push(new FormControl(e.target.value));
  //    // stockout.push(new FormControl(e.target.value));
  //     console.log(checkArray)
  //   } else {
  //     let i: number = 0;
  //     checkArray.controls.forEach((item: any) => {
  //       if (item.value == e.target.value) {
  //         checkArray.removeAt(i);
  //         return;
  //       }
  //       i++;
  //     });
  //   }
  // }

  // submitForm() {
  //   console.log(this.Form.value);
  //   console.log("Bilal")
  // }


  updateRow(event: any) {
    this.showSpinner();

    if (parseInt(this.Form.get('stockout').value) <= parseInt(event.stock_remaining)) {
      var obj = {
        product_inventory_id: event.id,
        product_id: event.product_id,
        stock_out: this.Form.get('stockout').value,
        stock_out_date: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
        product_varient_group_id: event.product_varient_group_id,
        product_varient_id: event.product_varient_id,
      };

      this._masterProductService.insertProductInventoryOutflows(obj)
        .subscribe(
          res => {
            // console.log("Bilal" + res);
            this.hideSpinner();
            // this.Form.get('stockout').value == "0";
            // window.location.reload();


            this.SearchProduct(0);

            // if (res.errors.stock_out > 0) {
            //   alert(res.errors.stock_out)
            // }

            //alert(JSON.parse(JSON.stringify(error.error)))

            // const errorObjectAsString = JSON.stringify(res.error);
            // alert(errorObjectAsString)
            // res.console.error();

          }
        );
    }
    else {
      console.log("Error");
      this.hideSpinner();
    }

  }


  CreateProductInventory() {
    if (this.AddForm.get('productname').value == null) {
      alert('Select Product');
      return;
    }

    // if (this.AddForm.get('variantgroupname').value == null) {
    //   alert('Select Variant Group');
    //   return;
    // }

    // if (this.AddForm.get('variantname').value == null) {
    //   alert('Select Variant');
    //   return;
    // }

    if (this.AddForm.get('stockin').value == null) {
      alert('Enter Stock In');
      return;
    }

    if (this.AddForm.get('purchaseprice').value == null) {
      alert('Enter Purchase Price');
      return;
    }

    if (this.AddForm.valid) {
      var obj = {
        product_id: this.AddForm.get('productname').value,
        product_varient_group_id: this.AddForm.get('variantgroupname').value,
        product_varient_id: this.AddForm.get('variantname').value,
        stock_in: this.AddForm.get('stockin').value,
        purchase_price: this.AddForm.get('purchaseprice').value,
        purchase_date: "2022-02-01"//this.Form.get('purchasedate').value,
      }

      this._masterProductService.insertProductInventory(obj)
        .subscribe(response => {
          this.modalService.dismissAll();
          //console.log(response);
          // window.location.reload();
        });
    }


  }


  // SearchProduct(event: any) {
  //   var productId = this.Form.get('pname').value;
  //   console.log("productId: ", productId);

  //   this._masterProductService.getAllProductInventoriesListById(productId)
  //   .subscribe(
  //     data => this.prodinventories = data
  //   );

  // }




}
