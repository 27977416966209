import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  returnUrl: string;
  userId: string;
  RoleList: {};
  CountryList: {};
  ProvinceList: {};
  CitiesList: {};
  OutletList: {};

  Form = new FormGroup({
    userId: new FormControl(),
    first: new FormControl(),
    last: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    password2: new FormControl(),
    role: new FormControl(),
    country: new FormControl(),
    province: new FormControl(),
    city: new FormControl(),
    outlet: new FormControl()
  });


  constructor(private formBuilder: FormBuilder,
    private _masterUserService: UsersApiService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef) {

    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  returnedData$: any;
  ngOnInit() {
    this.getRoleLists();
    this.getCountryLists();

    this.userId = this.activatedRoute.snapshot.paramMap.get("id");

    if (parseInt(this.userId) > 0) {
      //alert('yes')
      this.returnedData$ = this.getAdminUserById(this.userId)
      // this.getProvinceByCountryId(0,this.userId);
    }

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/users/list-user';
  }

  getRoleLists() {
    this._masterUserService.getAllLists("roles")
      .subscribe(
        data => this.RoleList = data
      );
  }

  getCountryLists() {
    this._masterUserService.getAllLists("countries")
      .subscribe(
        data => this.CountryList = data
      );
  }

  getProvinceByCountryId(event: any, string) {
    //this.showSpinner(); 

    var value;
    if (string == 0) {
      value = event.target.value;
    }
    else {
      value = string;
    }
    this._masterUserService.getDataById("provinces?", "country_id=", value)
      .subscribe(
        data => {
          this.ProvinceList = data;
          // this.hideSpinner();
        }
      );
  }

  getCityByProvinceId(event: any, string) {
    var value;
    if (string == 0) {
      value = event.target.value;
    }
    else {
      value = string;
    }
    this._masterUserService.getDataById("cities?", "province_id=", value)
      .subscribe(
        data => {
          this.CitiesList = data;
          // this.hideSpinner();
        }
      );
  }

  getOutletByCityId(event: any, string) {
    var value;
    if (string == 0) {
      value = event.target.value;
    }
    else {
      value = string;
    }
    this._masterUserService.getDataById("outlets?", "city_id=", value)
      .subscribe(
        data => {
          this.OutletList = data;
          // this.hideSpinner();
        }
      );
  }
  errormode: boolean = true;
  emailcheck() {
    if (this.userId == null) {
      var emailchk = this.Form.get('email').value;
      this.errormode = (emailchk as HTMLInputElement).value === '';
      this._masterUserService.getEmailCheck(emailchk)
        .subscribe(
          data => {
            console.log(data.errors.email.toString())
            console.log(data)
            if (data.errors.email.toString() == "This email is already exist") {
              this.errormode = true;
              alert(emailchk + " email is already exist")
            }
          }
        );
    }
    else {
      this.Form.get('email').disable();
    }
  }

  passwordcheck() {
    var emailchk = this.Form.get('password').value;

    if (this.Form.get('password').value != this.Form.get('password2').value) {
      this.errormode = true;
      alert("Both Passwords can't matched");
    }
    else {
      this.errormode = (emailchk as HTMLInputElement).value === '';
    }
  }
  getAdminUserById(event: any) {

    console.log("Product Id: " + event);
    this._masterUserService.getSingleAdminUserById(event)

      .subscribe(data => {
        var prodData = {
          first: data.name,
          last: data.last_name,
          email: data.email,
          role: data.roles[0].id,
          country: data.country_id,
          province: data.province_id,
          city: data.city_id,
          outlet: data.outlet_id,
          userId: data.id
        };

        console.log(prodData)

        this.Form.patchValue(prodData);

        this.userId = prodData.userId;
        this.getProvinceByCountryId(0, prodData.country);
        this.getCityByProvinceId(0, prodData.province);
        this.getOutletByCityId(0, prodData.city);

        this.Form.get('email').disable();

      },
        err => {
          console.log(err);
        });
  }

  create() {

    if (this.Form.get('first').value == null) {
      alert("Enter First Name");
      return;
    }

    if (this.Form.get('email').value == null) {
      alert("Enter Email Address");
      return;
    }

    if (this.Form.get('role').value == null) {
      alert("Select Role");
      return;
    }

    if (this.Form.get('country').value == null) {
      alert("Select Country");
      return;
    }

    if (this.Form.get('province').value == null) {
      alert("Select Province");
      return;
    }

    if (this.Form.get('city').value == null) {
      alert("Select City");
      return;
    }

    if (this.Form.get('outlet').value == null) {
      alert("Select Outlet");
      return;
    }

    if (this.Form.get('password').value == this.Form.get('password2').value) {

      var obj = {
        name: this.Form.get('first').value,
        last_name: this.Form.get('last').value,
        email: this.Form.get('email').value,
        password: this.Form.get('password').value,
        role_id: this.Form.get('role').value,
        country: this.Form.get('country').value,
        province: this.Form.get('province').value,
        city: this.Form.get('city').value,
        outlet_id: this.Form.get('outlet').value
      }

      console.log(obj);

      this._masterUserService.createAdminUser(obj)
        .subscribe(response => {
          this.router.navigate([this.returnUrl]);
        });
    } else {
      alert("Password & Confirm Password can't match");
    }

  }

  update() {

    if (this.Form.get('first').value == null) {
      alert("Enter First Name");
      return;
    }

    if (this.Form.get('email').value == null) {
      alert("Enter Email Address");
      return;
    }

    if (this.Form.get('role').value == null) {
      alert("Select Role");
      return;
    }

    if (this.Form.get('country').value == null) {
      alert("Select Country");
      return;
    }

    if (this.Form.get('province').value == null) {
      alert("Select Province");
      return;
    }

    if (this.Form.get('city').value == null) {
      alert("Select City");
      return;
    }

    if (this.Form.get('outlet').value == null) {
      alert("Select Outlet");
      return;
    }

    if (this.Form.get('password').value == this.Form.get('password2').value) {

      var obj = {
        name: this.Form.get('first').value,
        last_name: this.Form.get('last').value,
        email: this.Form.get('email').value,
        password: this.Form.get('password').value,
        role_id: this.Form.get('role').value,
        country: this.Form.get('country').value,
        province: this.Form.get('province').value,
        city: this.Form.get('city').value,
        outlet_id: this.Form.get('outlet').value,
        id: this.Form.get('userId').value
      }

      console.log(obj);

      this._masterUserService.updateAdminUser(obj)
        .subscribe(response => {
          this.router.navigate([this.returnUrl]);
        });
    } else {
      alert("Password & Confirm Password can't match");
    }

  }



}
