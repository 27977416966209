import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebsiteContentService {

  constructor(
    private http: HttpClient, private router: Router
  ) { }


  // ======== Website Menu Start ========

  getMenusLists() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/website-menus', option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  insertMenu(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-menus', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Update Product
  updateMenu(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-menus/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getSingleMenuById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/website-menus/' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Delete Menus
  deleteMenuData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-menus/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  restoreMenuData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-menus/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // ======== Website Menu End ========


  // ======== Website Content Start ========

  getLoginContentList(string) {
    return this.http
      .get<any>(CONSTANTS.BASE_URL + '/'+string)
      .pipe(map(data => data));
  }

  getContentLists() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/website-management-systems', option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  deleteContentData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-management-systems/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  restoreContentData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-management-systems/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  insertContent(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-management-systems', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  updatePage(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/website-management-systems/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getSinglePageById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/website-management-systems/' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // ======== website Content End ========


  


  // ======== Handle Error Start ========

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      //errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = `Error Code: ${error.status}`;
    }
    //console.log(errorMessage);
    window.alert(errorMessage);
    return throwError(() => {
      return;
    });
  }

  // ======== Handle Error End ========


}
