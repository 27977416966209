<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Product Lists</h5>
                </div> -->



                <div class="card-body">
                    <!-- Delete Div -->
                    <ng-template #delcontent let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete Product Variant Group</h5>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body" [formGroup]="Form">
                            <input formControlName="productvariantgroupId" type="hidden">
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="DeleteProductVariantGroup()">Yes</button>
                            <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                (click)="modal.dismiss('Cross click')">No</button>
                        </div>
                    </ng-template>


                    <div class="btn-popup pull-right">
                        <a routerLink="/products/digital/product-variant-group-add">
                            <button class="btn btn-secondary">
                                Add Product Variant Group
                            </button>
                        </a>
                    </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">

                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="10%" class="center-align">Image</th>
                                    <th width="55%">Name</th>
                                    <th width="10%" class="center-align">Status</th>
                                    <th width="15%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container
                                    *ngFor="let ProductVariantGroupData of ProductVariantGroupList; index as i">
                                    <tr class="left-align">
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">
                                            <div *ngIf="ProductVariantGroupData.url == null; else elseBlockImg"></div>
                                            <ng-template #elseBlockImg>
                                                <span>
                                                    <img [src]="CONSTANTS.IMG_URL +'/'+ ProductVariantGroupData.url"
                                                        class="mr-2" style="width: 30px">
                                                </span>
                                            </ng-template>
                                        </td>
                                        <td>{{ProductVariantGroupData.name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="ProductVariantGroupData.deleted_at == null; else elseBlock">
                                                <span title="Active" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="InActive" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span">
                                                <a routerLink="/products/digital/digital-add-product"><button class="badge badge-success"> Edit
                                                </button></a>
                                            </span>
                                            <!-- <span class="space-span">
                                                <a (click)="open(delcontent, ProductVariantGroupData)" class="delete"> Delete
                                                </a>
                                            </span> -->

                                            <span class="space-span"
                                                *ngIf="ProductVariantGroupData.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, ProductVariantGroupData)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, ProductVariantGroupData)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>