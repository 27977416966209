<!-- Container-fluid starts-->
<div class="container-fluid" [formGroup]="Form">
    <div class="row">
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="input-group col-md-12 input-grp-p">
                <label for="validationCustom01" class="mb-1" style="line-height: 34px;margin: 0 10px 0 0;">
                    <strong>Date:</strong></label>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="start_date"
                    #f1="ngbDatepicker" (click)="f1.toggle()">
                <span class="input-group-addon">to</span>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="end_date"
                    #f2="ngbDatepicker" (click)="f2.toggle()">
            </div>
        </div>

        <div class="col-xl-4 col-md-6 xl-50">
            <button type="button" class="btn btn-primary" (click)="getLists()">Search</button>
        </div>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="overflow-x:auto;">
                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button>
                            <table id="excel-table" class="table table-striped report-tbl users">
                                <tr>
                                    <th width="20%" class="center-align left-tbl">#</th>
                                    <th width="20%" class="center-align">Order No.</th>
                                    <th width="30%" class="center-align">Order Date</th>
                                    <th width="13%" class="center-align">Order Amount</th>
                                    <th width="13%" class="center-align">Net Amount</th>
                                    <th width="12%" class="center-align">Discount Amount</th>
                                    <th width="12%" class="center-align">Customer Name</th>
                                    <th width="12%" class="center-align">Customer Email</th>
                                    <th width="12%" class="center-align">Order Status</th>
                                    <th width="12%" class="center-align">Payment Gateway</th>
                                    <th width="12%" class="center-align">Delivery Mode</th>
                                    <th width="12%" class="center-align">City</th>
                                    <th width="12%" class="center-align right-tbl">Source Type</th>
                                </tr>
                                <ng-container *ngFor="let list of ReportList.orders; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">{{list.id}}</td>
                                        <td class="center-align">{{list.date | date:'fullDate'}}</td>
                                        <td class="center-align">{{list.amount | currency:'Rs. '}}</td>
                                        <td class="center-align">{{list.net_amount | currency:'Rs. '}}</td>
                                        <td class="center-align">{{list.discount_amount | currency:'Rs. '}}</td>
                                        <td class="center-align">{{list.shipper_name}}</td>
                                        <td class="center-align">{{list.email}}</td>
                                        <td class="center-align">{{list.order_status_name}}</td>
                                        <td class="center-align">{{list.payment_gateway_name}}</td>
                                        <td class="center-align">{{list.delivery_mode_name}}</td>
                                        <td class="center-align">{{list.city_name}}</td>
                                        <td class="center-align">{{list.source_type_name}}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <app-pagination [products]="ReportList.orders" [paginate]="paginate"
                                (setPage)="setPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->