import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'


@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []
  modalContent: undefined

  labelBox: string;
  labelImg: string;

  Form = new FormGroup({
    name: new FormControl(),
    Id: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  PermissionList: {};

  constructor(private modalService: NgbModal, 
    private http: HttpClient, 
    private _masterUserService: UsersApiService) {
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      console.log(this.modalContent)

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.Form.patchValue({
        catname: tableRow.name,
        catId: tableRow.id,
        status: tableRow.deleted_at
      });

      console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        catname: null,
        catId: null,
        //catimg: tableRow.url
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getPermissionList();
  }

  // ======= List Of Permission ======= 
  getPermissionList() {
    this._masterUserService.getAllAdminUserPermissionLists()
      .subscribe(
        data => this.PermissionList = data
      );
  }

  //  ======= Add Permission ======= 
  CreatePermission() {

    if (this.Form.valid) {
      var obj = {
        name: this.Form.get('name').value,
        is_crud: true
      }

      this._masterUserService.insertPermissionData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  UpdatePermission() {
    if (this.Form.valid) {
      var obj = {
        name: this.Form.get('name').value,
        id: this.Form.get('Id').value,
      }

      this._masterUserService.updatePermissionData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  /*
  //  ======= Master Category Edit  =======  
  getMasterDataById(event: any) {

    // console.log("Call Function: " + event);
    this._masterUserService.getMaterCategoriesById(event)
      .subscribe(response => {
        console.log(response)
      });
  }

  

  UpdateMasterCategory() {
    if (this.Form.valid) {
      
      
      var obj = {
        name: this.Form.get('catname').value,
        id: this.Form.get('catId').value,
      }

      this._masterUserService.updateCategoryData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }


  DeleteMasterCategory() {
    var obj = {
      id: this.Form.get('catId').value,
      status: this.Form.get('status').value
    }

    console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterUserService.deleteCategoryData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterUserService.restoreCategoryData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  */

}


