import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { DeliverymodeComponent } from './deliverymode/deliverymode.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { BookCourierCompanyComponent } from './book-courier-company/book-courier-company.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        data: {
          title: "Orders",
          breadcrumb: "Orders"
        }
      },
      {
        path: 'orderdetail',
        component: OrderdetailComponent,
        data: {
          title: "Order Detail",
          breadcrumb: "Order Detail"
        }
      },
      {
        path: 'book-courier-company',
        component: BookCourierCompanyComponent,
        data: {
          title: "Book to Courier",
          breadcrumb: "Book to Courier"
        }
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: {
          title: "Transactions",
          breadcrumb: "Transactions"
        }
      },
      {
        path: 'deliverymode',
        component: DeliverymodeComponent,
        data: {
          title: "Delivery Mode",
          breadcrumb: "Delivery Mode"
        }
      },
      {
        path: 'orders/orderdetail/:id', 
        component: OrderdetailComponent, 
        data: {
          title: "Order Detail",
          breadcrumb: "Order Detail"
        }
      },
      {
        path: 'payment-gateway', 
        component: PaymentGatewayComponent, 
        data: {
          title: "Payment Gateway",
          breadcrumb: "Payment Gateway"
        }
      }

      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
