<ngx-spinner type="line-spin-clockwise-fade">
    <p class="loader"></p>
</ngx-spinner>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <!-- Delete Div -->
                    <ng-template #delcontent let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Product</h5>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" [formGroup]="Form">
                            <input formControlName="productId" type="hidden">
                            <input formControlName="status" type="hidden">
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="DeleteProduct()">Yes</button>
                            <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                (click)="modal.dismiss('Cross click')">No</button>
                        </div>
                    </ng-template>

                    <div class="btn-popup pull-left" style="width: 100%;" [formGroup]="filterForm">
                        <table class="filter-tbl">
                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Master
                                            Category:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="mastername"
                                        formControlName="mastername" (change)="getMasterCategoryById($event)">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Master Category ---
                                        </option>
                                        <option *ngFor="let MasterCategory of MasterCategories"
                                            [value]="MasterCategory.id">
                                            {{MasterCategory.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Major
                                            Category:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="majorname"
                                        formControlName="majorname" (change)="getSubCategoryById($event)">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Master Category ---
                                        </option>
                                        <option *ngFor="let MajorCategory of MajorCategories"
                                            [value]="MajorCategory.id">
                                            {{MajorCategory.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Sub
                                            Category:</strong></label>
                                </td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="subcatname"
                                        formControlName="subcatname">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Sub Category ---
                                        </option>
                                        <option *ngFor="let SubCat of SubCategories" [value]="SubCat.id">
                                            {{SubCat.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Product
                                            Review:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="review"
                                        formControlName="review">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Product Review ---
                                        </option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Product
                                            Name:</strong></label></td>
                                <td class="text">
                                    <input class="form-control" id="validationCustom01" formControlName="productname"
                                        type="text" value="" placeholder="Product Name">
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Product
                                            Id:</strong></label></td>
                                <td class="text">
                                    <input class="form-control" id="validationCustom01" formControlName="productid"
                                        type="text" value="" placeholder="Product Id">
                                </td>
                            </tr>

                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Sort:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="status"
                                        formControlName="sort">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Product Sorting ---
                                        </option>
                                        <option value="0">Product Name DESC</option>
                                        <option value="1">Product Name ASC</option>
                                        <option value="2">Product Id DESC</option>
                                        <option value="3">Product Id ASC</option>
                                    </select>
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Product
                                            Status:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="status"
                                        formControlName="status">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Product Active / InActive ---
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">InActive</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="3">
                                    <button type="button" class="btn btn-primary"
                                        (click)="SearchProduct()">Search</button>
                                </td>
                                <td colspan="3" class="right-tbl">
                                    <a routerLink="/products/digital/digital-add-product" class="btn btn-secondary">Add
                                        Product</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <br>

                    <!-- <label><strong>Per Page: </strong></label>
                    <select (change)="getAllListProducts($event)">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select> -->

                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">

                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="1%" class="center-align left-tbl">#</th>
                                    <!-- <th scope="col">
                                        <input
                                          class="center-align" style="width:20px"
                                          type="checkbox"
                                          [(ngModel)]="parentSelector"
                                          [value]="-1"
                                          (change)="onChangeFood($event)"
                                        />
                                      </th> -->
                                    <th width="10%" class="center-align">Product Id</th>
                                    <th width="10%" class="center-align">Image</th>
                                    <!-- <th width="15%" class="center-align">Master Category Name</th> -->
                                    <th width="15%" class="center-align">Major Category Name</th>
                                    <th width="15%" class="center-align">Sub Category Name</th>
                                    <th width="35%" class="center-align">Product Name</th>
                                    <th width="10%" class="center-align">Status</th>
                                    <th width="10%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let ProductData of ProductList.products
                                let i = index">
                                    <tr class="left-align">
                                        <td scope="row" class="center-align">{{ i + 1 }}</td>
                                        <!-- <td scope="row" class="center-align"class="center-align">
                                            <input
                                              class="" style="width:20px"
                                              type="checkbox"
                                              [value]="ProductData.id"
                                              [checked]="ProductData.select"
                                              (change)="onChangeFood($event)"
                                            />
                                          </td> -->

                                        <td scope="row" class="center-align">{{ ProductData.id }}</td>
                                        <td class="center-align">
                                            <img [src]="ProductData.product_images[0].url" class="mr-2"
                                                style="width: 43px">
                                        </td>
                                        <!-- <td>{{ProductData.master_category_name}}</td> -->
                                        <td>{{ProductData.major_category_name}}</td>
                                        <td>{{ProductData.sub_category_name}}</td>
                                        <td>{{ProductData.name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="ProductData.deleted_at == null; else elseBlock">
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <span class="space-span" *ngIf="ProductData.deleted_at == null;" >
                                                <a [routerLink]="['/products/digital/digital-add-product',ProductData.id]"
                                                    class="badge badge-success">Edit</a>
                                            </span>
                                            <span class="space-span"
                                                *ngIf="ProductData.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, ProductData)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, ProductData)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>
                                            <span class="space-span"
                                                *ngIf="ProductData.is_reviewed == 'Yes'; else elseBlock_Review">
                                                <a [routerLink]="['/products/digital/product-review-detail',ProductData.id]"
                                                    class="review">Review</a>
                                            </span>
                                            <ng-template #elseBlock_Review>

                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                            <app-pagination [products]="ProductList.products" [paginate]="paginate" (setPage)="setPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>