<!-- Container-fluid starts-->
<script>
// function ConvertStringToNumber(input: string) {
//     var numeric = Number(input);
//     return numeric;
// }
</script>
<div class="container-fluid" [formGroup]="Form">
    <div class="row">
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="input-group col-md-12 input-grp-p">
                <label for="validationCustom01" class="mb-1" style="line-height: 34px;margin: 0 10px 0 0;">
                    <strong>Date:</strong></label>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="start_date"
                    #f1="ngbDatepicker" (click)="f1.toggle()">
                <span class="input-group-addon">to</span>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="end_date"
                    #f2="ngbDatepicker" (click)="f2.toggle()">
            </div>
        </div>

        <div class="col-xl-4 col-md-6 xl-50">
            <button type="button" class="btn btn-primary" (click)="getLists()">Search</button>
        </div>
        <br><br><br>

        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="overflow-x:auto;">
                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button>
                            <table id="excel-table" class="table table-striped report-tbl users">
                                <tr>
                                    <th width="20%" class="center-align left-tbl">#</th>
                                    <th width="20%" class="center-align">Image</th>
                                    <th width="20%" class="center-align">Product Id</th>
                                    <th width="20%" class="center-align">Product Name</th>
                                    <th width="20%" class="center-align">Order No.</th>
                                    <th width="20%" class="center-align">CN No.</th>
                                    <th width="12%" class="center-align">Customer Name</th>
                                    <th width="12%" class="center-align">Customer Email</th>
                                    <th width="12%" class="center-align">City</th>
                                    <th width="12%" class="center-align">Contact No</th>
                                    <th width="30%" class="center-align">Order Date</th>
                                    <th width="12%" class="center-align">Order Status</th>
                                    <th width="12%" class="center-align">Delivery Mode</th>
                                    <th width="13%" class="center-align">Order Amount</th>
                                    <th width="13%" class="center-align">Product Amount</th>
                                    <th width="12%" class="center-align">Discount Amount</th>
                                    <th width="12%" class="center-align">Payment Gateway</th>
                                    <th width="12%" class="center-align">Source Type</th>
                                    <th width="12%" class="center-align">Shipping Charges</th>
                                    <th width="12%" class="center-align">FAF Percentage</th>
                                    <th width="12%" class="center-align">Fuel Surcharge</th>
                                    <th width="12%" class="center-align">GST</th>
                                    <th width="12%" class="center-align">Total Invoice Amount</th>
                                    <th width="12%" class="center-align">Total Amount Collect</th>
                                    <th width="12%" class="center-align right-tbl">Cheque No</th>
                                </tr>
                                <ng-container *ngFor="let list of ReportList.orders; index as i">
                                    <ng-container *ngFor="let detail of list.order_details; index as x">
                                        <tr>
                                            <td class="center-align" scope="row">{{ i + 1 }}</td>
                                            <td class="center-align">
                                                <img src="{{detail.product_image}}" alt="." width="30" height="30">
                                            </td>
                                            <td class="center-align">{{detail.product_id}}</td>
                                            <td>{{detail.product_name}}</td>
                                            <td class="center-align">{{list.id}}</td>
                                            <td class="center-align">{{list.tracking_name}}</td>
                                            <td class="center-align">{{list.shipper_name}}</td>
                                            <td class="center-align">{{list.email}}</td>
                                            <td class="center-align">{{list.city_name}}</td>
                                            <td class="center-align">{{list.phone}}</td>
                                            <td class="center-align">{{list.date | date:'fullDate'}}</td>
                                            <td class="center-align">{{list.order_status_name}}</td>
                                            <td class="center-align">{{list.delivery_mode_name}}</td>
                                            <td class="center-align">
                                                {{(list.net_amount + ConvertStringToNumber(list.shipping)) | currency:'Rs. '}}
                                            </td>
                                            <td class="center-align">{{detail.price | currency:'Rs. '}}</td>
                                            <td class="center-align">{{list.discount_amount | currency:'Rs. '}}</td>
                                            <td class="center-align">{{list.payment_gateway_name}}</td>
                                            <td class="center-align">{{list.source_type_name}}</td>
                                            <td class="center-align">{{list.courier_shipping}}</td>
                                            <td class="center-align">{{list.faf_percentage}}</td>
                                            <td class="center-align">{{list.courier_shipping * (list.faf_percentage/100)}}</td>
                                            <td class="center-align">{{(list.courier_shipping + (list.courier_shipping * (list.faf_percentage/100)))*0.13 | number}}</td>
                                            <td class="center-align">{{(list.courier_shipping) + (list.courier_shipping * (list.faf_percentage/100)) + ((list.courier_shipping + (list.courier_shipping * (list.faf_percentage/100)))*0.13) }}</td>
                                            <td class="center-align">
                                                {{((list.net_amount + ConvertStringToNumber(list.shipping)) - ((list.courier_shipping) + (list.courier_shipping * (list.faf_percentage/100)) + ((list.courier_shipping + (list.courier_shipping * (list.faf_percentage/100)))*0.13)) | number : '1.0-0') }}
                                            </td>
                                            <td class="center-align">{{list.cheque_no}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </table>
                            <app-pagination [products]="ReportList.orders" [paginate]="paginate"
                                (setPage)="setPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->