import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { digitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ResourceLoader } from '@angular/compiler';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Router, ActivatedRoute } from "@angular/router";
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-digital-category',
  templateUrl: './digital-category.component.html',
  styleUrls: ['./digital-category.component.scss']
})
export class DigitalCategoryComponent implements OnInit {
  ImageBaseData: string | ArrayBuffer = null;

  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultUpload: DataUrl = '';
  localUrl: any;
  returnUrl: string;
  base64textString = [];

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []
  modalContent: undefined

  labelBox: string;
  labelImg: string;

  catForm = new FormGroup({
    catname: new FormControl(),
    catimg: new FormControl(),
    catId: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  Categories: {};

  constructor(private modalService: NgbModal, private http: HttpClient, 
    private _masterCategoryService: CategoriesApiService, 
    private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2,el: ElementRef) {
    this.digital_categories = digitalCategoryDB.digital_category;
  
    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
     // console.log(this.modalContent)
      //console.log(CONSTANTS.IMG_URL + '/' + tableRow.url)

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;
      // this.Id = tableRow.id;

      this.catForm.patchValue({
        catname: tableRow.name,
        catId: tableRow.id,
        status: tableRow.deleted_at
      });

     // console.log(this.catForm);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.catForm.patchValue({
        catname: null,
        catId: null,
        //catimg: tableRow.url
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getMasterCategory();
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/products/digital/digital-category';
  }

  /*
    handleFileInput(files: FileList) {
      this.compressFile();
      let me = this;
      let file = files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
       // console.log(reader.result);
        me.ImageBaseData = reader.result;
      };
      reader.onerror = function (error) {
       // console.log('Error: ', error);
      };
    }
  */

/*

  handleFileInput(files: FileList) {
    //this.compressFile();

    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      //  console.log(reader.result);
      // me.ImageBaseData = reader.result;
      this.localUrl = event.target.result;
      me.ImageBaseData = this.compressFile(reader.result, file)

      //console.log("COMPRESS: "+this.compressFile(reader.result, file))
    }



    reader.onerror = function (error) {
     // console.log('Error: ', error);
    };
  }
  */
  
  
  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 100, 100).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }



  // ======= List Of Master Category ======= 
  getMasterCategory() {
    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => this.Categories = data
      );
  }

  //  ======= Master Category Edit  =======  
  getMasterDataById(event: any) {

    // console.log("Call Function: " + event);
    this._masterCategoryService.getMaterCategoriesById(event)
      .subscribe(response => {
       // console.log(response)
      });
  }




  //  ======= Add Master Category ======= 
  CreateMasterCategory() {
    // console.log("Upload" + this.ImageBaseData.toString())

    // var fileUplodVM: FileUplodVM = {
    //   ImageBaseData: this.ImageBaseData.toString()
    // }

    // console.log("Final IMG: " + this.localCompressedURl )
    // return;

    if (this.catForm.valid) {
      var obj = {
        name: this.catForm.get('catname').value,
        media_string: this.localCompressedURl
      }

      this._masterCategoryService.insertCategoryData(obj)
        .subscribe(response => {
         // console.log(response);
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }

  UpdateMasterCategory() {
    if (this.catForm.valid) {
      if (this.ImageBaseData == null) {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: ""
        }
      }
      else {
        var fileUplodVM: FileUplodVM = {
          ImageBaseData: this.ImageBaseData.toString()
        }
      }
      var obj = {
        name: this.catForm.get('catname').value,
        id: this.catForm.get('catId').value,
        media_string: this.localCompressedURl
        // media_id: fileUplodVM.ImageBaseData
      }

      this._masterCategoryService.updateCategoryData(obj)
        .subscribe(response => {
         // console.log(response);
          this.modalService.dismissAll();
          this.ngOnInit();
          //window.location.reload();
          //this.router.navigate([this.returnUrl]);
        });
    }
  }


  DeleteMasterCategory() {
    var obj = {
      id: this.catForm.get('catId').value,
      status: this.catForm.get('status').value
    }

   // console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterCategoryService.deleteCategoryData(obj)
        .subscribe(response => {
         // console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
    else {
      this._masterCategoryService.restoreCategoryData(obj)
        .subscribe(response => {
         // console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }

}

export class FileUplodVM {
  ImageBaseData: string;
}
