import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  Order_Id: string;
  first: string;
  last: string;
  email: string;
  role: string;
  country: string;
  province: string;
  city: string;
  outlet: string;
  userId: string;

  public show: boolean = false;

  isEditEnable: boolean = false; // to show and hide the edit button

  PasswordForm = new FormGroup({
    oldpassword: new FormControl(),
    password: new FormControl(),
    cpassword: new FormControl(),
  });

  Form = new FormGroup({
    userId: new FormControl(),
    first: new FormControl(),
    last: new FormControl(),
    email: new FormControl(),
    // oldpassword: new FormControl(),
    // password: new FormControl(),
    // cpassword: new FormControl(),
    role: new FormControl(),
    country: new FormControl(),
    province: new FormControl(),
    city: new FormControl(),
    outlet: new FormControl()
  });

  constructor(private formBuilder: FormBuilder,
    private _masterUserService: UsersApiService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const obj = JSON.parse(localStorage.getItem('E-Com-Admin-user'));
    this.userId = obj.user.id;

    // console.log(this.userId)
    this.getAdminUserById(this.userId);
  }

  onEdit() {
    this.isEditEnable = !this.isEditEnable;
  }

  getAdminUserById(event: any) {

    console.log("Product Id: " + event);
    this._masterUserService.getProfileById(event)
      .subscribe(data => {
        data = JSON.stringify(JSON.parse(data));
        const myObj = JSON.parse(data);

        this.first = myObj.name;
        this.last = myObj.last_name;
        this.email = myObj.email;
        this.role = myObj.roles[0].id;
        this.country = myObj.country_id;
        this.province = myObj.province_id;
        this.city = myObj.city_id;
        this.outlet = myObj.outlet_id;
        this.userId = myObj.id;
      },
        err => {
          console.log(err);
        });
  }

  update() {

    if (this.Form.get('first').value == null) {
      alert("Enter First Name");
      return;
    }

    var obj = {
      name: this.Form.get('first').value,
      last_name: this.Form.get('last').value,
      id: this.Form.get('userId').value
    }

    console.log(obj);

    this._masterUserService.updateAdminUser(obj)
      .subscribe(response => {
        console.log(response);
        //  window.location.reload();
      });
  }

  changepassword() {

    if (this.PasswordForm.get('password').value == this.PasswordForm.get('cpassword').value) {

      var obj = {
        current_password: this.PasswordForm.get('oldpassword').value,
        new_password: this.PasswordForm.get('password').value,
        new_password_confirmation: this.PasswordForm.get('cpassword').value,
      }

      console.log(obj);

      this._masterUserService.changePasswordForAdminUser(obj)
        .subscribe(response => {
          alert("Password successfully changed")
          //console.log(response);
          //  window.location.reload();
        });
    } else {
      alert("Password & Confirm Password can't match");
    }

  }



}
