<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Page Lists</h5>
                </div>
                <div class="card-body" [formGroup]="Form">

                    <ng-template #delcontent let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Page</h5>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input formControlName="id" type="hidden">
                            <input formControlName="status" type="hidden">
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="Delete()">Yes</button>
                            <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                (click)="modal.dismiss('Cross click')">No</button>
                        </div>
                    </ng-template>


                    <div class="custom-datatable">
                        <a routerLink="/pages/create-page/" class="btn btn-secondary">Add
                            Page</a>
                        <!-- <button class="btn btn-primary mr-1 mb-3" 
                            (click)="delete($event)">Delete</button> -->
                        <br><br>
                        <div class="clearfix"></div>
                        <!-- <ngx-datatable class="bootstrap" [rows]="menus" [columnMode]="'force'" [headerHeight]="50"
                            [footerHeight]="50" [rowHeight]="'auto'" [limit]="15" [selected]="selected"
                            [selectionType]="'checkbox'" [selectAllRowsOnPage]="false" (select)='onSelect($event)'>
                            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                                [checkboxable]="true">
                                <button type="button" class="btn btn-primery">Delete</button>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Name"></ngx-datatable-column>
                            <ngx-datatable-column name="Status"></ngx-datatable-column>
                            <ngx-datatable-column prop="created on"></ngx-datatable-column>
                        </ngx-datatable> -->



                        <table id="users" class="table table-striped">
                            <thead>
                                <th width="1%" class="center-align left-tbl">#</th>
                                <th width="10%" class="center-align">Name</th>
                                <th width="15%" class="center-align">Menu Type</th>
                                <th width="10%" class="center-align">Status</th>
                                <th width="10%" class="center-align right-tbl">Action</th>
                            </thead>
                            <ng-container *ngFor="let Data of MenusList
                            let i = index">
                                <tr class="left-align">
                                    <td scope="row" class="center-align">{{ i + 1 }}</td>
                                    <td scope="row" class="center-align">{{ Data.website_menu_name }}</td>
                                    <td scope="row" class="center-align">
                                        <div *ngIf="Data.menu_type == 0; else elseTypeBlock">
                                            FrontEnd
                                        </div>
                                        <ng-template #elseTypeBlock>
                                            Admin Panel
                                        </ng-template>
                                    </td>
                                    <td class="center-align">
                                        <div *ngIf="Data.deleted_at == null; else elseBlock">
                                            <span title="Delivered" class="ng-star-inserted"
                                                style="user-select: auto;"><span class="badge badge-success"
                                                    style="user-select: auto;">Active</span></span>
                                        </div>
                                        <ng-template #elseBlock>
                                            <span title="Delivered" class="ng-star-inserted"
                                                style="user-select: auto;"><span class="delete"
                                                    style="user-select: auto;">InActive</span></span>
                                        </ng-template>
                                    </td>
                                    <td>
                                        <span class="space-span">
                                            <a [routerLink]="['/pages/create-page/',Data.slug]"
                                                class="badge badge-success">Edit</a>
                                        </span>
                                        <span class="space-span" *ngIf="Data.deleted_at == null; else elseBlock_delete">
                                            <a (click)="open(delcontent, Data)" class="delete"> Delete
                                            </a>
                                        </span>
                                        <ng-template #elseBlock_delete>
                                            <span class="space-span">
                                                <a (click)="open(delcontent, Data)" class="orange"> Restore
                                                </a>
                                            </span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>