<div class="container-fluid">
    <div class="row product-adding">

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <div class="digital-add needs-validation col-xl-6" [formGroup]="Form">
                        <div class="form-group">
                            <label for="validationCustom01" class="col-form-label pt-0"><span>*</span> Title</label>
                            <input class="form-control" id="validationCustom01" type="text" formControlName="title"
                                required="" (keypress)="special_char($event)">
                        </div>
                        <div class="form-group">

                            <label for="validationCustom01" class="mb-1">Variant Group Name :</label>
                            <select class="form-control " id="catmajorname" formControlName="variantgroup">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Product Variant Group ---
                                </option>
                                <option *ngFor="let Category of VariantGroupDowndown" [value]="Category.id">
                                    {{Category.name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"> Product Upload</label>
                            <div class="card-body dropzone-custom p-0">
                                <!-- <div class="dropzone dropzone-primary" [dropzone]="config1"
                                    (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </div> -->
                                <!-- <input type="file" id="image" name="image" accept=".png, .jpg, .jpeg"
                                    (change)="handleFileInput($event.target.files)"> -->

                                    <input type="file" accept=".jpg,.png,.jpeg" id="image" name="image"
                                            (change)="selectFile($event)">
                            </div>
                        </div>

                        <button type="button" class="btn btn-primary" (click)="Create()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>