import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
// import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormArray } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {

  returnUrl: string;
  isDisabled: boolean;
  PermissionList: {};
  PermissionObjList = [];
  PermissionObjList2 = [];
  Per_List = [];
  // Per_List: any;
  permissionListArray: any;



  // public permissionForms: FormGroup;

  // permissionListForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private _masterUserService: UsersApiService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, private router: Router,) {
  }

  isNonvariant: boolean = false; // hidden by default


  permissionListForm = new FormGroup({
    name: new FormControl(),
    permissionId: new FormControl(),
    permissionName: new FormControl(),
    precheck: new FormControl()
  });

  permissionForm = new FormGroup({
    rolename: new FormControl(),
    permissionId: new FormControl()
  });


  ngOnInit() {
    this.getPermissionList_New();

    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/users/list-user';
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  divLabel: boolean;
  getPermissionList() {
    this._masterUserService.getAllAdminUserPermissionList()
      .subscribe(data => {

        data = JSON.stringify(JSON.parse(data));
        const myObj = JSON.parse(data);

        for (var i = 0; i < myObj.length; i++) {
          var orderDetails2 = {
            name: myObj[i].name,
            id: myObj[i].id
          };
          this.PermissionObjList.push(orderDetails2);
        }

        //this.isNonvariant.removeAt = false;


        // if ($event.target.value == 0) {
        //   this.isNonvariant = !this.isNonvariant;
        // }

      },
        err => {
          console.log(err);
        });
  }

  divLabelCondition: String;
  getPermissionById(event: any) {

    var orderDetails;


    // this.divLabel = "div_0";// = true;

    // this.divLabel = false;

    // console.log(event.target.value);

    // this.divLabel = true;
    // localStorage.removeItem('Permission_Session');

    // this.divLabelCondition = 'div_' + event.target.value;
    this.divLabelCondition = "hide"
    console.log("1:" + JSON.parse(localStorage.getItem('Permission_Session')));
    console.log("2:" + this.divLabelCondition);


    if (JSON.parse(localStorage.getItem('Permission_Session')) === this.divLabelCondition) {
      //  this.divLabelCondition = "show"
      alert("1");
    }
    else {
      JSON.parse(localStorage.getItem('Permission_Session')) == "hide";
      alert("2");
      this.divLabelCondition = "show";
    }

    this._masterUserService.getPermissionbyIdList(event.target.value)
      .subscribe(data => {

        data = JSON.stringify(JSON.parse(data));
        const myObj = JSON.parse(data);

        for (var i = 0; i < myObj.permissions.length; i++) {
          orderDetails = {
            name: myObj.permissions[i].name,
            id: myObj.permissions[i].id
          };
          console.log(orderDetails);
          this.PermissionObjList2.push(orderDetails);
        }
        // this.divLabelCondition = 'div_' + event.target.value;

        this.divLabelCondition = "show";
        localStorage.setItem('Permission_Session', JSON.stringify(this.divLabelCondition));
        // localStorage.setItem('Permission_Session', "show");
        let session_value = JSON.parse(localStorage.getItem("Permission_Session"));


        console.log("3:" + session_value);
        //  console.log("4:" + this.divLabelCondition);
        // this.divLabel = true;
        // this.divLabelCondition = 'div_' + orderDetails.id;
        // if (this.divLabel == true) {
        //   this.divLabelCondition = 'div_' + orderDetails.id;
        // }
        // else {
        //   this.divLabelCondition = 'div_0';
        // }


        // console.log(this.divLabel)

      },
        err => {
          console.log(err);
        });
  }

  // ======= List Of Permission ======= 
  getPermissionList_New() {
    this._masterUserService.getAllAdminUserPermissionList()
      .subscribe(data => {

        data = JSON.stringify(JSON.parse(data));
        const myObj = JSON.parse(data);

        console.log("orderDetails" + myObj[1].permissions.length)


        for (var i = 0; i < myObj.length; i++) {

          var orderDetails2 = {
            name: myObj[i].name
          };
          this.PermissionObjList.push(orderDetails2);

          for (var j = 0; j < myObj[i].permissions.length; j++) {
            var orderDetails = {
              name2: myObj[i].permissions[j].name,
              id: myObj[i].permissions[j].id
            };

            this.PermissionObjList.push(orderDetails);
          }
        }
      },
        err => {
          console.log(err);
        });
  }

  addPermission(id: string, name: string, isChecked: boolean) {

    const dataCheck = this.Per_List.find((x) => x.permissionId === id)
    var customer;
    if (dataCheck) {
      alert(name + ' already exists');
      return
    }

    if (isChecked) {
      customer = {
        permissionId: id,
        permissionName: name
      };
    }
    else {
      const index = customer.controls.findIndex(x => x.value === name);
    }

    this.Per_List.push(customer);
  }

  deleteRow(index: any) {
    this.Per_List.splice(index, 1);
  }


  onSubmit() {

    var table = document.querySelectorAll("span table tr");
    var permissionData = [];

    for (var i = 1; i < table.length; i++) {
      var row = table[i].querySelectorAll(".var-tbl td");
      permissionData.push({
        permissionId: row[1].innerHTML
      });
    }

    //let permissionId = permissionData.map(itm => itm.permissionId).join(',');
    let permissionId = permissionData.map(itm => itm.permissionId);
    console.log(permissionId)

    var obj = {
      name: this.permissionForm.get('rolename').value,
      permissions: permissionId
    }

    this._masterUserService.insertRoleData(obj)
      .subscribe(response => {
        this.router.navigate([this.returnUrl]);
      });
  }




  /*
    CreateRole(event: any, model: any) {
      console.log(' model ' + JSON.stringify(model)); 
  
      // if (this.permissionForm.get('name').value == null) {
      //   alert("Enter Role");
      //   return;
      // }
  
  
      this.permissionForms = this.formBuilder.group({
        rdoani21: ['rdoani21'],
      })
  
      console.log(this.permissionForms)
  
      if (this.permissionForm.valid) {
        var obj = {
          name: this.permissionForm.get('name').value,
          rdoani21: this.permissionForm.get('rdoani21').value
  
        }
  
        console.log(obj)
      }
  
  
    }
    */





  // create() {
  //   this.permissionListForm = this.formBuilder.group({
  //     name: ['']
  //   });
  // }


  // createAccountForm() {
  //   this.accountForm = this.formBuilder.group({
  //     fname: [''],
  //     lname: [''],
  //     email: [''],
  //     password: [''],
  //     confirmPwd: ['']
  //   })
  // }
  // createPermissionForm() {
  //   this.permissionForm = this.formBuilder.group({
  //   })
  // }

}

