<ngx-spinner type="line-spin-clockwise-fade">
    <p class="loader"></p>
</ngx-spinner>

<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-body">

                    <div class="btn-popup pull-left" style="width: 100%;" [formGroup]="filterForm">
                        <table class="filter-tbl">
                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Master
                                            Category:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="mastername"
                                        formControlName="mastername" (change)="getMasterCategoryById($event)">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Master Category ---
                                        </option>
                                        <option *ngFor="let MasterCategory of MasterCategories"
                                            [value]="MasterCategory.id">
                                            {{MasterCategory.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Major
                                            Category:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="majorname"
                                        formControlName="majorname" (change)="getSubCategoryById($event)">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Master Category ---
                                        </option>
                                        <option *ngFor="let MajorCategory of MajorCategories"
                                            [value]="MajorCategory.id">
                                            {{MajorCategory.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Sub
                                            Category:</strong></label>
                                </td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="subcatname"
                                        formControlName="subcatname" (change)="getCategoriesByProducts($event)">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Sub Category ---
                                        </option>
                                        <option *ngFor="let SubCat of SubCategories" [value]="SubCat.id">
                                            {{SubCat.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td class="label"><label for="validationCustom01" class="mb-1"><strong>Product
                                            Name:</strong></label></td>
                                <td class="text">
                                    <select class="form-control select2 select2-hidden-accessible" id="pname"
                                        formControlName="pname">
                                        <option value="" class="text-primary font-weight-bolder">
                                            --- Select Product ---
                                        </option>
                                        <option *ngFor="let List of ProductList.products" [value]="List.id">
                                            {{List.name | uppercase}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary"
                                        (click)="SearchProduct($event)">Search</button>
                                </td>
                            </tr>
                        </table>
                    </div>


                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-secondary" data-toggle="modal"
                            data-original-title="Add Master Category" data-target="#exampleModal"
                            (click)="open(content)">Add Product Inventory</button>



                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Product Inventory</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" [formGroup]="AddForm">
                                <div class="form">
                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Product:</label></strong>
                                        <select class="custom-select" id="productname" formControlName="productname" >
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Product ---
                                            </option>
                                            <!-- <option *ngFor="let Products of ProductList" [value]="Products.id">
                                                {{Products.name}}
                                            </option> -->
                                            <option *ngFor="let List of ProductList.products" [value]="List.id">
                                                {{List.name | uppercase}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <strong> <label for="validationCustom01" class="mb-1">Variant
                                                Group:</label></strong>
                                        <select class="custom-select" id="variantgroupname"
                                            formControlName="variantgroupname"
                                            (change)="getProductVariantGroupById($event)">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Group Variant ---
                                            </option>
                                            <option *ngFor="let Group of ProductVariantGroupList" [value]="Group.id">
                                                {{Group.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Variant:</label></strong>
                                        <select class="custom-select" id="variantname" formControlName="variantname">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Variant ---
                                            </option>
                                            <option *ngFor="let Variant of ProductVariantList" [value]="Variant.id">
                                                {{Variant.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Stock In:</label></strong>
                                        <input class="form-control" type="text" name="stockin" id="stockin"
                                            formControlName="stockin" placeholder="Stock In" maxlength="3"
                                            (keypress)="numberOnly($event)" />
                                    </div>

                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Purchase
                                                Price:</label></strong>
                                        <input class="form-control" type="text" name="purchaseprice" id="purchaseprice"
                                            formControlName="purchaseprice" placeholder="Purchase Price" maxlength="7"
                                            (keypress)="numberOnly($event)" />
                                    </div>

                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Purchase
                                                Date:</label></strong>
                                        <!-- <input class="datepicker-here form-control digits" ngbDatepicker readonly
                                                formControlName="purchasedate" #f="ngbDatepicker" (click)="f.toggle()"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="CreateProductInventory()">Save</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>
                    </div>

                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive" [formGroup]="Form">
                            <!-- <form  (ngSubmit)="submitForm()" novalidate> -->
                            <!-- <input type="submit" value="Update"> -->
                            <table id="users" class="table table-striped">
                                <thead>
                                    <!-- <th width="5%" class="center-align left-tbl">
                                        <input type="checkbox" (click)="CheckAllOptions()">
                                    </th> -->
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="10%" class="center-align">Product Name</th>
                                    <th width="10%" class="center-align">Variant Group</th>
                                    <th width="10%" class="center-align">Variant</th>
                                    <th width="8%" class="center-align">Stock In</th>
                                    <th width="8%" class="center-align">Stock Remaining</th>
                                    <th width="10%" class="center-align">Stock Out</th>
                                    <th width="10%" class="center-align">Purchase Price</th>
                                    <th width="16%" class="center-align">Purchase Date</th>
                                    <th width="5%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let inventories of prodinventories; index as i">
                                    <tr>
                                        <!-- <td class="center-align">
                                            <input type="checkbox" [(ngModel)]="inventories.checked"
                                                value="{{inventories.id}}" formControlName="checkbox"
                                                (change)="onCheckboxChange($event)"
                                                >
                                        </td> -->
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td>{{inventories.product_name}}</td>
                                        <td class="center-align">
                                            {{inventories.product_varient_group_name}}
                                        </td>
                                        <td class="center-align">
                                            {{inventories.product_varient_name}}
                                        </td>
                                        <td class="center-align">
                                            {{inventories.stock_in}}
                                        </td>
                                        <td class="center-align">
                                            {{inventories.stock_remaining}}
                                        </td>
                                        <td class="center-align">
                                            <input class="form-control" type="text" name="stockout" id="stockout"
                                                formControlName="stockout" placeholder="Stock Out" value="0" maxlength="3"
                                                (keypress)="numberOnly($event)" />
                                        </td>
                                        <td class="center-align">
                                            {{inventories.purchase_price| currency: " "}}
                                        </td>
                                        <td class="center-align">
                                            {{inventories.purchase_date| date:'fullDate'}}
                                        </td>
                                        <td class="center-align">
                                            <button class="badge badge-success" id="{{inventories.id}}"
                                                (click)="updateRow(inventories)"
                                                style='cursor: pointer;'>Update</button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        <!-- </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->