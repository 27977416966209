import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {

  ProductReviewList: {};
  public closeResult: string;
  readonly CONSTANTS = CONSTANTS;
  modalContent: undefined

  Form = new FormGroup({
    productreviewId: new FormControl(),
    status: new FormControl()
  });

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      this.Form.patchValue({
        productreviewId: tableRow.id,
        status: tableRow.deleted_at
      });
    }

   console.log(this.modalContent);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterProductService: ProductApiService,config: NgbRatingConfig) {
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit(): void {
    this.getAllListProductReview();
  }

  getAllListProductReview() {
    this._masterProductService.getAllListProductReview()
      .subscribe(
        data => this.ProductReviewList = data
      );
  }

  DeleteProductReview(){
    var obj = {
      id: this.Form.get('productreviewId').value,
      status: this.Form.get('status').value
    }

     console.log("Delete Function: " + JSON.stringify(obj.id));
     console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterProductService.deleteProductReviewData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterProductService.restoreProductReviewData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}
