import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  ImageBaseData: string | ArrayBuffer = null;
  errorMsg: string;

  constructor(
    private http: HttpClient, private router: Router
  ) { }

  // List of Product Variant Group
  getAllListProductVariantGroup() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-varient-groups', option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // List of Product Variant Group By Id
  getProductVariantGroupById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-varient-groups/' + obj + '/product-varients', option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }



  // Add Product Variant Group
  insertProductVariantGroupData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varient-groups', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Delete Product Variant Group
  deleteProductVariantGroupData(obj): Observable<any> {
    // console.log("ID:" + id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method': 'DELETE'
    // };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varient-groups/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  restoreProductVariantGroupData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varient-groups/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // List of Product Variant
  getAllListProductVariant() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-varients', option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Add Product Variant Group
  insertProductVariantData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varients', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Delete Product Variant
  deleteProductVariantData(obj): Observable<any> {
    console.log("ID:");

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varients/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  restoreProductVariantData(obj): Observable<any> {
    // console.log("ID:____");

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-varients/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Get All Product List
  getAllProductsLists(offset: number) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    let limitcount = CONSTANTS.PAGINATION
    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products?limit=' + '&limit=' + limitcount + '&offset=' + (offset - 1) * limitcount, option)
      .pipe(
        map(res => {
          //   console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getAllProductsList() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products', option)
      .pipe(
        map(res => {
          //   console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getAllProductsListByPagination(limit, offset) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products?limit=' + limit + '&offset=' + offset, option)
      .pipe(
        map(res => {
          //  console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }



  // Add Product Variant Group
  insertProductData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/products', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Update Product
  updateProductData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/products/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Delete Product
  deleteProductData(obj): Observable<any> {
    //  console.log("ID:" + obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/products/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Retore Product
  restoreProductData(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/products/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          //  console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  /* ========== Product Inventory Start ========== */


  // Get All Product Inventory List
  getAllProductInventoriesList() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-inventories', option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getAllProductInventoriesListById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/products/' + obj + '/product-inventories', option)
      .pipe(
        map(res => {
          // console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getProductVariantInventoriesListById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    if (obj.variantGroupId == undefined) {
      obj.variantGroupId = "";
    }

    if (obj.variantId == undefined) {
      obj.variantId = "";
    }

    console.log(CONSTANTS.BASE_URL + '/products/' + obj.productId + '/product-inventories?product_varient_group_id=' + obj.variantGroupId + '&product_varient_id=' + obj.variantId)
    return this.http
      .get(CONSTANTS.BASE_URL + '/products/' + obj.productId + '/product-inventories?product_varient_group_id=' + obj.variantGroupId + '&product_varient_id=' + obj.variantId, option)
      .pipe(
        map(res => {
          console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Product Filter
  getProductFilter(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    if (obj.mastername == undefined) {
      obj.mastername = "";
    }

    if (obj.majorname == undefined) {
      obj.majorname = "";
    }

    if (obj.subcatname == undefined) {
      obj.subcatname = "";
    }

    if (obj.productname == undefined) {
      obj.productname = "";
    }

    if (obj.productid == undefined) {
      obj.productid = "";
    }

    if (obj.sort == undefined) {
      obj.sort = "";
    }

    if (obj.is_reviewed == undefined) {
      obj.is_reviewed = "";
    }

    if (obj.is_status == undefined) {
      obj.is_status = "";
    }

    //console.log(CONSTANTS.BASE_URL + '/admin/products?' + 'product_name=' + obj.productname + '&master_category_id=' + obj.mastername + '&major_category_id=' + obj.majorname + '&sub_category_id=' + obj.subcatname + '&is_reviewed=' + obj.is_reviewed + '&is_status=' + obj.is_status)
    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products?' + 'product_name=' + obj.productname + '&master_category_id=' + obj.mastername + '&major_category_id=' + obj.majorname + '&sub_category_id=' + obj.subcatname + '&is_reviewed=' + obj.is_reviewed + '&status=' + obj.is_status + '&product_id=' + obj.productid + '&sort_by=' + obj.sort, option)
      .pipe(
        map(res => {
          //console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }



  // Add Product Inventory
  insertProductInventory(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/product-inventories', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          //console.log(res);
          return res;

        }),
        catchError(this.handleError)
      )
  }

  // Add Product Inventory Outflow
  insertProductInventoryOutflows(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/product-inventory-outflows', JSON.stringify(obj), option)
      .pipe(
        map(
          res => {
            // console.log(res);
            return res;
          }
        ),
        catchError(this.handleError)

        // catchError(
        //   error => {
        //     if (error.error instanceof ErrorEvent) {
        //       this.errorMsg = `Error: ${error.error.message}`;

        //     } else {
        //       this.errorMsg = `Error: ${error.message}`;
        //     }
        //     alert(this.errorMsg)
        //     return of([]);

        //   })
      )
  }

  // Product Filter
  getCategoriesByProductsFilter(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    if (obj.mastername == undefined) {
      obj.mastername = "";
    }

    if (obj.majorname == undefined) {
      obj.majorname = "";
    }

    if (obj.subcatname == undefined) {
      obj.subcatname = "";
    }

    //console.log(CONSTANTS.BASE_URL + '/admin/products?' + 'master_category_id=' + obj.mastername + '&major_category_id=' + obj.majorname + '&sub_category_id=' + obj.subcatname)

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products?' + 'master_category_id=' + obj.mastername + '&major_category_id=' + obj.majorname + '&sub_category_id=' + obj.subcatname, option)
      .pipe(
        map(res => {
          //   console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }



  /* ========== Product Inventory End ========== */

  // ======== Edit Product Start ========

  getSingleProductById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/admin/products/' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // ======== Edit Product End ========




  // ======== Brand End ========

  // Add Brand
  insertBrands(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/brands', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Update Brand
  updateBrands(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/brands/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }



  // Delete Brand
  deleteBrandData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method':'DELETE'
    // };
    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/brands/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  restoreBrandData(obj): Observable<any> {
    // console.log("ID:"+id);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/brands/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Get All Brands List
  getAllBrandsList() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/brands', option)
      .pipe(
        map(res => {
          //   console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // ======== Brand End ========



  // ======== Product Reviews Start ========

  // List of Product Reviews
  getAllListProductReview() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-reviews', option)
      .pipe(
        map(res => {
          //  console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getProductReviewById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/product-reviews?product_id=' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Delete Product Review
  deleteProductReviewData(obj): Observable<any> {
    //  console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-reviews/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          //console.log("RES: "+res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Restore Product Review
  restoreProductReviewData(obj): Observable<any> {
    console.log("ID:____");

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // const obj = {
    //   '_method':'DELETE'
    // };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/product-reviews/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          // console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }




  // ======== Product Reviews End ========



  // ======== Handle Error Start ========

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      //errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = `Error Code: ${error.status}`;
    }
    //console.log(errorMessage);
    window.alert(errorMessage);
    return throwError(() => {
      return;
    });
  }

  // ======== Handle Error End ========

  //Pagination
  public getPager_(totalItems: number, currentPage: number = 1, pageSize: number) {
    // public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 25) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 25) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
