<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" [formGroup]="Form">
        <input type="hidden" formControlName="orderid" value="{{Order_Id}}">
        <div class="col-sm-7">

            <div class="card">
                <div class="card-header">
                    <h5>Customer Personal Detail</h5>
                </div>
                <div class="card-body">

                    <div class="digital-add needs-validation ">
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Order Number: </strong></label>
                            {{Order_Id}}
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Order Date: </strong></label>
                            {{Order_Date | date:'fullDate'}}
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Customer Name: </strong></label>
                            <span>{{Shipper_Name}}</span>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Customer Email: </strong></label>
                            {{User_Email}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Contact Number: </strong></label>
                            {{Phone_Number}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0" style="vertical-align: top;"><strong>Billing Address:
                                </strong></label>
                            <span style="width: 70%;display: inline-block;">{{Billing_Address}}</span>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>City: </strong></label>
                            {{City}}
                        </div>

                        

                        <div class="form-group">
                            <label class="col-form-label pt-0" style="vertical-align: top;"><strong>Shipper Address:
                                </strong></label>
                            <span style="width: 70%;display: inline-block;">{{Shipper_Address}}</span>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Tracking Number: </strong></label>
                            {{Tracking_Number}}
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->


        <div class="col-sm-5">

            <div class="card">
                <div class="card-header">
                    <h5>Order Billing Detail</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation right">
                        <div class="form-group" style="background-color: lightgreen;">
                            <label class="col-form-label pt-0"><strong>Net Amount: </strong></label>
                            {{Net_Amount | currency:'Rs. '}}
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Order Amount: </strong></label>
                            {{Order_Amount | currency:'Rs. '}}
                        </div>
                        
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Discount Amount: </strong></label>
                            {{Discount_Amount | currency:'Rs. '}}
                        </div>
                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Shipment Amount: </strong></label>
                            {{Shipment_Amount | currency:'Rs. '}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Bank Charges: </strong></label>
                            {{Bank_Charges | currency:'Rs. '}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>FED Charges: </strong></label>
                            {{FED_Charges | currency:'Rs. '}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Tax Charges: </strong></label>
                            {{Tax | currency:'Rs. '}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Order From: </strong></label>
                            {{Source_Type}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Payment Gateway: </strong></label>
                            {{Payment_Gateway}}
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Delivery Mode: </strong></label>
                            <select class="col-sm-7 select2 select2-hidden-accessible" id="deliverymode"
                                formControlName="deliverymode">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Delivery Mode ---
                                </option>
                                <option *ngFor="let list of DeliveryModeList" [value]="list.id">
                                    {{list.name | uppercase}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label pt-0"><strong>Order Status: </strong></label>
                            <!-- {{Order_Status}} -->
                            <select class="col-sm-7 select2 select2-hidden-accessible" id="orderstatus"
                                formControlName="orderstatus">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Order Status ---
                                </option>
                                <option *ngFor="let list of OrderStatusList.orderStatuses" [value]="list.id">
                                    {{list.name | uppercase}}
                                </option>
                            </select>
                        </div>

                        <button type="button" class="btn btn-primary" (click)="Update()">Update</button>

                    </div>
                </div>
            </div>
        </div>


        <div class="col-sm-12">

            <div class="card">
                <div class="card-header">
                    <h5>Products Detail</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation right">

                        <table id="users" class="var-tbl">
                            <tr>
                                <th width="10%" class="center-align">Image</th>
                                <th width="30%" class="center-align">Product Name</th>
                                <th width="20%" class="center-align">Varient Group</th>
                                <th width="20%" class="center-align">Varient</th>
                                <th width="10%" class="center-align">Unit Price</th>
                                <th width="5%" class="center-align">Quantity</th>
                                <th width="15%" class="center-align">Sub Total</th>
                            </tr>

                            <tr *ngFor="let m of orderList; let i = index;">
                                <td class="center-align">
                                    <img src="{{m.product_image}}" width="100">
                                </td>
                                <td>
                                    {{m.product_name}}
                                </td>
                                <td>
                                    <table id="users" class="var-tbl">
                                        <tr *ngFor="let mm of m.order_detail_varients;let j = index;">
                                            <td style="border:0">
                                                {{mm.product_varient_group_name}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table id="users" class="var-tbl">
                                        <tr *ngFor="let mm of m.order_detail_varients;let j = index;">
                                            <td style="border:0">
                                                {{mm.product_varient_name}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="center-align">
                                    {{m.price | currency: " "}}
                                </td>
                                <td class="center-align">
                                    {{m.quantity}}
                                </td>
                                <td class="center-align">
                                    {{m.price * m.quantity | currency: " "}}
                                </td>

                            </tr>

                        </table>


                    </div>
                </div>
            </div>
        </div>




    </div>
</div>