import { Component, OnInit } from '@angular/core';
import { digitalListDB } from 'src/app/shared/tables/digital-list';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

@Component({
  selector: 'app-productsvariant',
  templateUrl: './productsvariant.component.html',
  styleUrls: ['./productsvariant.component.scss']
})
export class ProductsvariantComponent implements OnInit {

  ImageBaseData: string | ArrayBuffer = null;

  public digital_list = []
  public closeResult: string;
  readonly CONSTANTS = CONSTANTS;
  modalContent: undefined
  
  ProductVariantGroupList: {};

  Form = new FormGroup({
    productvariantId: new FormControl(),
    status: new FormControl()
  });

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      this.Form.patchValue({
        productvariantId: tableRow.id,
        status: tableRow.deleted_at
      });
    }

    console.log(this.modalContent);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterProductService: ProductApiService) {

  }

  ngOnInit() {
    this.getAllListProductVariant();
  }


  getAllListProductVariant() {
    this._masterProductService.getAllListProductVariant()
      .subscribe(
        data => this.ProductVariantGroupList = data
      );
  }

  DeleteProductVariant() {
      var obj = {
        id: this.Form.get('productvariantId').value,
        status: this.Form.get('status').value
      }
  
       console.log("Delete Function: " + JSON.stringify(obj.status));
  
      if (JSON.stringify(obj.status) == 'null') {
        this._masterProductService.deleteProductVariantData(obj)
          .subscribe(response => {
            console.log(response)
            this.modalService.dismissAll();
            window.location.reload();
          });
      }
      else {
        this._masterProductService.restoreProductVariantData(obj)
          .subscribe(response => {
            console.log(response)
            this.modalService.dismissAll();
            window.location.reload();
          });
      }
      
  }



}
