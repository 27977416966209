<!-- <ngx-spinner type = "line-spin-clockwise-fade">
    <p class="loader"></p>
</ngx-spinner> -->

<div class="container-fluid" [formGroup]="Form">


    <div class="row product-adding" >
        <input type="hidden" formControlName="productId" value="{{Product_Id}}">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>General</h5>
                </div>

                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <label for="validationCustom01" class="col-form-label pt-0"><span>*</span> Title</label>
                            <input class="form-control" id="validationCustom01" type="text" formControlName="title"
                                required>
                        </div>
                        <div class="form-group">
                            <label for="validationCustomtitle" class="col-form-label pt-0"><span>*</span> SKU</label>
                            <input class="form-control" id="validationCustomtitle" type="text" formControlName="sku"
                                required="">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Master Category</label>
                            <select class="form-control select2 select2-hidden-accessible" id="mastername"
                                formControlName="mastername" (change)="getMasterCategoryById($event,0)">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Master Category ---
                                </option>
                                <option *ngFor="let MasterCategory of MasterCategories" [value]="MasterCategory.id">
                                    {{MasterCategory.name | uppercase}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Major Category</label>
                            <select class="form-control select2 select2-hidden-accessible" id="majorname"
                                formControlName="majorname" (change)="getSubCategoryById($event,0)">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Major Category ---
                                </option>
                                <option *ngFor="let MajorCategory of MajorCategories" [value]="MajorCategory.id">
                                    {{MajorCategory.name | uppercase}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Sub Category</label>
                            <select class="form-control select2 select2-hidden-accessible" id="subcatname"
                                formControlName="subcatname">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Sub Category ---
                                </option>
                                <option *ngFor="let SubCat of SubCategories" [value]="SubCat.id">
                                    {{SubCat.name | uppercase}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span>Age Range</label>
                            <select class="form-control select2 select2-hidden-accessible" id="agerange"
                                formControlName="agerange" multiple>
                                <!-- <option value="" class="text-primary font-weight-bolder">
                                    --- Select Sub Category ---
                                </option> -->
                                <option *ngFor="let List of AgeRangeList" [value]="List.id">
                                    {{List.group_name | uppercase}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Brand</label>
                            <select class="form-control select2 select2-hidden-accessible" id="brand"
                                formControlName="brand">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Brand ---
                                </option>
                                <option *ngFor="let brand of BrandList" [value]="brand.id">
                                    {{brand.name | uppercase}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Outlet</label>
                            <select class="form-control select2 select2-hidden-accessible" id="outlet"
                                formControlName="outlet">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Outlet ---
                                </option>
                                <option *ngFor="let outlet of MasterOutlets" [value]="outlet.id">
                                    {{outlet.name | uppercase}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Is Featured</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                <select class="form-control select2 select2-hidden-accessible" id="featured"
                                    formControlName="featured">
                                    <option value="" class="text-primary font-weight-bolder">
                                        --- Select Featured ---
                                    </option>
                                    <option [value]="1">YES</option>
                                    <option [value]="0">NO</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span>Is Variant</label>
                            <select class="form-control select2 select2-hidden-accessible" id="isvariant"
                                formControlName="isvariant" (change)="toggleShow($event)">
                                <option value="" class="text-primary font-weight-bolder">
                                    --- Select Is Variant ---
                                </option>
                                <option [value]="1">YES</option>
                                <option [value]="0">NO</option>
                            </select>
                        </div>


                    </div>
                </div>
            </div>
        </div>


        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>Add Description</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group mb-0">
                            <label class="col-form-label">Short Description</label>
                            <div class="description-sm">
                                <textarea id="shortdesc_" name="shortdesc_" formControlName="shortdesc_" cols="100"
                                    rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group mb-0">
                            <label class="col-form-label">Long Description</label>
                            <div class="description-sm">
                                <ck-editor id="longdesc_" name="longdesc_" language="en" formControlName="longdesc_"
                                    [fullPage]="false">
                                </ck-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!-- <div class="card">
                <div class="card-header">
                    <h5>Meta Data</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <label for="validationCustom05" class="col-form-label pt-0"> Meta Title</label>
                            <input class="form-control" id="validationCustom05" formControlName="metatitle" type="text" required="">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Meta Description</label>
                            <textarea rows="4" cols="12"></textarea>
                        </div>
                        <div class="form-group mb-0">
                            <div class="product-buttons text-center">
                                <button type="button" class="btn btn-primary mr-1">Add</button>
                                <button type="button" class="btn btn-light">Discard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>


        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>Product Price</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <label class="col-form-label"><span>*</span> Retail Price</label>
                            <input class="form-control" type="text" name="retailprice" formControlName="retailprice"
                                formControlName="retailprice" (keypress)="numberOnly($event)" required="" />
                        </div>
                        <div>
                            <label class="col-form-label">Sale Price</label>
                            <input class="form-control" type="text" name="saleprice" formControlName="saleprice"
                                formControlName="saleprice" (keypress)="numberOnly($event)" required="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>Picture Upload</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="form-group">
                            <!-- <input type="file" accept=".jpg,.png,.jpeg" id="catImage" name="catImage"
                                multiple="multiple" (click)="uploadMultipleFiles()"> -->
                                <button class="btn btn-warning mb-3" type="button" (click)="uploadMultipleFiles()">Upload Files</button>

                            <img src="{{labelImg}}" class="mr-2" style="width: 30px">

                            <span class="img">
                                <table id="users" class="image">
                                    <tr>
                                        <th width="10%" class="center-align">#</th> 
                                        <th width="75%" class="center-align">Images</th>
                                        <th width="15%" class="center-align">Action</th>
                                    </tr>
                                    <tr *ngFor="let row of productImage; let i = index;">
                                        <td class="center-align">{{i+1}}</td>
                                        <td>
                                            <img [src]="row.url" class="mr-2" style="width: 30px">
                                        </td>
                                        <td class="bnm" style="display: none;">
                                            {{row.imgId}}
                                        </td>
                                        <td>
                                            <button class="btn btn-primary pull-right" (click)="deleteImageRow(i)"
                                                style='cursor: pointer;'>Remove</button>
                                        </td>
                                    </tr>
                                </table>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h5>Product Variants</h5>
                </div>


                <!-- <input name="type" [(ngModel)]="isType" type="radio" [value]="1" [checked]="isOptions" /> Admin
                <input name="type" [(ngModel)]="isType" type="radio" [value]="0" [checked]="!isOptions" /> User

                <h2 *ngIf="isType == 1">Admin</h2>
                <h2 *ngIf="isType == 0">User</h2> -->




                <!-- <div class="showDiv" *ngIf="isVisible == 1"> -->

                <div class="card-body">




                    <form [formGroup]="contactForm" (ngSubmit)="addVariant()">

                        <div *ngIf="isVariant">
                            <table id="users" class="table table-striped">
                                <thead class="text text-primary">
                                    <tr>
                                        <th width="20%" class="center-align">Products Variant Group</th>
                                        <th width="20%" class="center-align">Products Variant</th>
                                        <th width="10%" class="center-align">Purchase Price</th>
                                        <th width="10%" class="center-align">Variant Price</th>
                                        <!-- <th width="10%" class="center-align">Retail Price</th>
                                    <th width="10%" class="center-align">Sale Price</th> -->
                                        <th width="10%" class="center-align">Purchase Date</th>
                                        <th width="10%" class="center-align">Quantity</th>
                                        <th width="5%" class="center-align">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of rows; let i = index;">
                                        <td>
                                            <select formControlName="variantgroup" class="custom-select variantgroup"
                                                id="variantgroup" (change)="getProductVariantById($event)"
                                                formControlName="variantgroup">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Variant Group ---
                                                </option>
                                                <option *ngFor="let VariantGroup of VariantGroupList"
                                                    [value]="VariantGroup.id">
                                                    {{VariantGroup.name | uppercase}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="custom-select" id="variant" formControlName="variant">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Variant ---
                                                </option>
                                                <option *ngFor="let Variant of VariantList" [value]="Variant.id">
                                                    {{Variant.name | uppercase}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" name="purchaseprice"
                                                id="purchaseprice" formControlName="purchaseprice"
                                                (keypress)="numberOnly($event)" required="" preventCutCopyPaste />
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" name="variantprice"
                                                id="variantprice" formControlName="variantprice"
                                                (keypress)="numberOnly($event)" required="" />
                                        </td>
                                        <!-- <td>
                                        <input class="form-control" type="text" name="saleprice" id="saleprice"
                                            formControlName="saleprice" (keypress)="numberOnly($event)" required="" />
                                    </td> -->
                                        <td>
                                            <input class="datepicker-here form-control digits" ngbDatepicker readonly
                                                formControlName="purchasedate" #f="ngbDatepicker" (click)="f.toggle()">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" name="qty" id="qty"
                                                formControlName="qty" (keypress)="numberOnly($event)" required="" />
                                        </td>
                                        <td>
                                            <button type="submit" class="btn btn-primary pull-right"
                                                style="cursor: pointer;">Add</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div *ngIf="isNonvariant">

                            <table id="users" class="table table-striped">
                                <thead class="text text-primary">
                                    <tr>
                                        <!-- <th width="20%" class="center-align">Products Variant Group (Optional)</th>
                                        <th width="20%" class="center-align">Products Variant (Optional)</th> -->
                                        <th width="10%" class="center-align">Purchase Price</th>
                                        <!-- <th width="10%" class="center-align">Variant Price (Optional)</th> -->
                                        <!-- <th width="10%" class="center-align">Retail Price</th>
                                    <th width="10%" class="center-align">Sale Price</th> -->
                                        <th width="10%" class="center-align">Purchase Date</th>
                                        <th width="10%" class="center-align">Quantity</th>
                                        <th width="5%" class="center-align">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of rows; let i = index;">
                                        <!-- <td>
                                            <select formControlName="variantgroup" class="custom-select variantgroup"
                                                id="variantgroup" (change)="getProductVariantById($event)"
                                                formControlName="variantgroup">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Variant Group ---
                                                </option>
                                                <option *ngFor="let VariantGroup of VariantGroupList"
                                                    [value]="VariantGroup.id">
                                                    {{VariantGroup.name | uppercase}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="custom-select" id="variant" formControlName="variant">
                                                <option value="" class="text-primary font-weight-bolder">
                                                    --- Select Variant ---
                                                </option>
                                                <option *ngFor="let Variant of VariantList" [value]="Variant.id">
                                                    {{Variant.name | uppercase}}
                                                </option>
                                            </select>
                                        </td> -->
                                        <td>
                                            <input class="form-control" type="text" name="purchaseprice"
                                                id="purchaseprice" formControlName="purchaseprice"
                                                (keypress)="numberOnly($event)" required="" preventCutCopyPaste />
                                        </td>
                                        <!-- <td>
                                            <input class="form-control" type="text" name="variantprice"
                                                id="variantprice" formControlName="variantprice"
                                                (keypress)="numberOnly($event)" required="" />
                                        </td> -->
                                        <!-- <td>
                                        <input class="form-control" type="text" name="saleprice" id="saleprice"
                                            formControlName="saleprice" (keypress)="numberOnly($event)" required="" />
                                    </td> -->
                                        <td>
                                            <input class="datepicker-here form-control digits" ngbDatepicker readonly
                                                formControlName="purchasedate" #f="ngbDatepicker" (click)="f.toggle()">
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" name="qty" id="qty"
                                                formControlName="qty" (keypress)="numberOnly($event)" required="" />
                                        </td>
                                        <td>
                                            <button type="submit" class="btn btn-primary pull-right"
                                                style="cursor: pointer;padding: 0.2rem 1rem;">Add</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>

                    <span class="var">
                        <table id="users" class="var-tbl">
                            <tr>
                                <th width="20%" class="center-align">Variant Group</th>
                                <th width="20%" class="center-align">Variant</th>
                                <th width="10%" class="center-align">Purchase Price</th>
                                <th width="10%" class="center-align">Variant Price</th>
                                <th width="10%" class="center-align">Purchase Date</th>
                                <th width="10%" class="center-align">Quantity</th>
                                <th width="5%" class="center-align">Action</th>
                            </tr>
                            <tr *ngFor="let m of Customers; let i = index;">
                                <td class="bnm">
                                    {{m.variantgroupName}}
                                </td>
                                <td>
                                    {{m.variantName}}
                                </td>
                                <td>
                                    {{m.purchaseprice}}
                                </td>
                                <td>
                                    {{m.variantprice}}
                                </td>
                                <td>
                                    <!-- {{m.purchasedate.year}}-{{m.purchasedate.month}}-{{m.purchasedate.day}} -->
                                    <span *ngIf="m.purchasedate.year != null else elseBlock_date">
                                        {{m.purchasedate.year}}-{{m.purchasedate.month}}-{{m.purchasedate.day}}
                                    </span>
                                    <ng-template #elseBlock_date>
                                        {{m.purchasedate}}
                                    </ng-template>
                                </td>
                                <td>
                                    {{m.qty}}
                                </td>
                                <td class="bnm" style="display: none;">
                                    {{m.variantgroup}}
                                </td>
                                <td style="display: none;">
                                    {{m.variant}}
                                </td>
                                <td>
                                    <button class="btn btn-primary pull-right" (click)="deleteRow(i)"
                                        style='cursor: pointer;padding: 0.2rem 1rem;'>Remove</button>
                                </td>
                            </tr>
                        </table>

                    </span>
                </div>
            </div>
        </div>

        <div class="col-xl-6">
            <span *ngIf="Product_Id == null else elseBlock_update">
                <button type="button" class="btn btn-primary" (click)="Create()">Save</button>
            </span>
            <ng-template #elseBlock_update>
                <button type="button" class="btn btn-primary" (click)="Update()">Update</button>
            </ng-template>
        </div>

    </div><br>
</div>