import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { LoginApiService } from 'src/app/shared/service/login/login-api.service';
import { WebsiteContentService } from 'src/app/shared/service/website-content.service';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { User } from 'src/app/shared/service/login/user-model.model'
// import { Session } from 'inspector';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  readonly CONSTANTS = CONSTANTS;

  public UserList: User
  public content: string;
  public socialLinks: {};

  userName: string
  password: string

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  apiLink_About: string = "website-management-systems";
  apiLink_Social: string = "social-media-links";

  constructor(private formBuilder: FormBuilder, 
    private _loginservice: LoginApiService, 
    private _contentservice: WebsiteContentService,
    private route: ActivatedRoute, 
    private router: Router,) {
    this.createLoginForm();
    this.createRegisterForm();

    // Social Links
    this._contentservice.getLoginContentList(this.apiLink_Social).subscribe(data => {
      this.socialLinks = data;
    });

    // About Us Content
    this._contentservice.getLoginContentList(this.apiLink_About).subscribe(data => {
      let list_content = data.filter(item => item.website_menu_name === "About Us");
      this.content = list_content[0].meta_description.substr(0, 250).replace(/<[^>]*>/g, '');
    

    console.log(this.content);

    this.owlcarousel = [
      {
        title: "Welcome to " + CONSTANTS.WEBSITE_NAME,
        desc: this.content,
      }
    ]
    this.owlcarouselOptions = {
      loop: true,
      items: 1,
      dots: true
    };

  });
  }

  owlcarousel = [
    {
      title: "Welcome to " + CONSTANTS.WEBSITE_NAME,
      desc: "",
    },
    // {
    //   title: "Welcome to " + CONSTANTS.WEBSITE_NAME,
    //   desc: "2.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // },
    // {
    //   title: "Welcome to " + CONSTANTS.WEBSITE_NAME,
    //   desc: "3.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    // }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
     // username: ['superadmin@gmail.com', Validators.required],
     // password: ['12345678', Validators.required]

     username: ['', Validators.required],
     password: ['', Validators.required]
    });

    localStorage.removeItem('user');
    this.router.navigate(['/auth/login']);

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/default';
  }

  get f() { return this.loginForm.controls; }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this._loginservice.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {

          data = JSON.stringify(JSON.parse(data));
          const myObj = JSON.parse(data);

          //console.log("USER DATA: " + data);
          //console.log("USER NAME: " + myObj.user.name);


          // this.UserList.firstName = myObj.user.name;
          // Session["firstName"]= data.user.name;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.loading = false;
          this.error = "Invalid Username & Password"
        });


  }

}
