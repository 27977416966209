import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  modalContent: undefined
  labelBox: string;
  CitiesList: any={};
  ProvincesList: {};
  API: string = "cities";

  Form = new FormGroup({
    name: new FormControl(),
    Id: new FormControl(),
    provincename: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, 
    private _masterService: MasterApiService, 
    private route: ActivatedRoute, private router: Router,
    private productService: ProductApiService, private viewScroller: ViewportScroller) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getLists();
    })
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    //console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";

      this.Form.patchValue({
        name: tableRow.name,
        Id: tableRow.id,
        status: tableRow.deleted_at,
        provincename:tableRow.province_id,
      });

      //console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.Form.patchValue({
        name: null,
        Id: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getLists();
    this.getProvincesLists();
  }

  getLists() {
    this._masterService.getAllListsFromAPI(this.API)
    .subscribe(
      data => {
        this.CitiesList = data;
        this.paginate = this.productService.getPager(this.CitiesList.totalRecords, +this.pageNo);
      }
    );
  }

  getProvincesLists() {
    this._masterService.getAllLists("provinces",0,0)
      .subscribe(
        data => this.ProvincesList = data
      );
  }

  Create() {
    if (this.Form.get('name').value == null) {
      alert('Enter Country Name');
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('name').value,
        province_id: this.Form.get('provincename').value
      }

      //console.log(obj);

      this._masterService.insertAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response);
          window.location.reload();
        });
    }
  }

  Update() {
    if (this.Form.valid) {

      var obj = {
        id: this.Form.get('Id').value,
        name: this.Form.get('name').value,
        province_id: this.Form.get('provincename').value
      }
      console.log(obj);
      this._masterService.updateAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response);
          this.modalService.dismissAll();
         // window.location.reload();
        });
    }
  }

  Delete(){
    var obj = {
      id: this.Form.get('Id').value,
      status: this.Form.get('status').value
    }

    if (JSON.stringify(obj.status) == 'null') {
      this._masterService.deleteAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterService.restoreAPI(this.API,obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}
