import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { NgbModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WebsiteContentService } from 'src/app/shared/service/website-content.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.scss']
})
export class CreateMenuComponent implements OnInit {

  menuId: string;
  Forms = new FormGroup({
    menuId: new FormControl(),
    name: new FormControl(),
    url: new FormControl(),
    type: new FormControl(),
  });

  constructor(private http: HttpClient, private modalService: NgbModal,
    private _masterContentService: WebsiteContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this.menuId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getMenuById(this.menuId);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  getMenuById(event: any) {
    //this.showSpinner();
    this._masterContentService.getSingleMenuById(event)
      .subscribe(data => {
        var prodData = {
          name: data.name,
          url: data.url,
          type: data.menu_type,
          menuId: data.id
        };
        this.Forms.patchValue(prodData);
         //console.log(prodData)
      },
        err => {
          //console.log(err);
        });
  }


  Create() {

    if (this.Forms.get('name').value == null) {
      alert("Enter Menu Name");
      return;
    }

    if (this.Forms.get('url').value == null) {
      alert("Enter URL");
      return;
    }

    if (this.Forms.get('type').value == null) {
      alert("Select Menu Type");
      return;
    }

    if (this.Forms.valid) {

      var obj = {
        name: this.Forms.get('name').value,
        url: this.Forms.get('url').value,
        menu_type: this.Forms.get('type').value,
        sub_menu_name: 0

      }
     // console.log(obj);

      this._masterContentService.insertMenu(obj)
        .subscribe(response => {
          //console.log(response);
          //window.location.reload();
          this.router.navigate(['/menus/list-menu']);
        });
    }
  }

  Update(){
    if (this.Forms.get('name').value == null) {
      alert("Enter Menu Name");
      return;
    }

    if (this.Forms.get('url').value == null) {
      alert("Enter URL");
      return;
    }

    if (this.Forms.get('type').value == null) {
      alert("Select Menu Type");
      return;
    }

    if (this.Forms.valid) {

      var obj = {
        name: this.Forms.get('name').value,
        url: this.Forms.get('url').value,
        menu_type: this.Forms.get('type').value,
        sub_menu_name: 0,
        id: this.Forms.get('menuId').value
      }
      //console.log(obj);

      this._masterContentService.updateMenu(obj)
        .subscribe(response => {
          //console.log(response);
          //window.location.reload();
          this.router.navigate(['/menus/list-menu']);
        });
    }
  }
  



}


