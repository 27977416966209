import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RatesComponent } from './rates/rates.component';
import { TranslationsComponent } from './translations/translations.component';
import { TaxesComponent } from './taxes/taxes.component';
import { BanksComponent } from './banks/banks.component';
import { CitiesComponent } from './cities/cities.component';
import { CountriesComponent } from './countries/countries.component';
import { OutletComponent } from './outlet/outlet.component';
import { ProvincesComponent } from './provinces/provinces.component';
import { SliderBannerComponent } from './slider-banner/slider-banner.component';
import { SliderTypeComponent } from './slider-type/slider-type.component';
import { SocialMediaComponent } from './social-media/social-media.component';


const routes: Routes = [
  {
    path: 'translations',
    component: TranslationsComponent,
    data: {
      title: "Translations",
      breadcrumb: "Translations"
    }
  },
  {
    path: 'currency-rates',
    component: RatesComponent,
    data: {
      title: "Currency Rates",
      breadcrumb: "Currency Rates"
    }
  },
  {
    path: 'taxes',
    component: TaxesComponent,
    data: {
      title: 'Taxes',
      breadcrumb: 'Taxes'
    }
  },
  {
    path: 'Banks',
    component: BanksComponent,
    data: {
      title: 'Banks',
      breadcrumb: 'Banks'
    }
  },
  {
    path: 'City',
    component: CitiesComponent,
    data: {
      title: 'City',
      breadcrumb: 'City'
    }
  },
  {
    path: 'Country',
    component: CountriesComponent,
    data: {
      title: 'Country',
      breadcrumb: 'Country'
    }
  },
  {
    path: 'Outlet',
    component: OutletComponent,
    data: {
      title: 'Outlet',
      breadcrumb: 'Outlet'
    }
  },
  {
    path: 'Province',
    component: ProvincesComponent,
    data: {
      title: 'Province',
      breadcrumb: 'Province'
    }
  },
  {
    path: 'slider-banner',
    component: SliderBannerComponent,
    data: {
      title: 'Slider Banner',
      breadcrumb: 'Slider Banner'
    }
  },
  {
    path: 'slider-type',
    component: SliderTypeComponent,
    data: {
      title: 'Slider Type',
      breadcrumb: 'Slider Type'
    }
  },
  {
    path: 'social-media',
    component: SocialMediaComponent,
    data: {
      title: 'Social Media',
      breadcrumb: 'Social Media'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalizationRoutingModule { }
