import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { orderDB } from "../../../shared/tables/order-list";
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { CourierApiService } from 'src/app/shared/service/courier-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { ActivatedRoute, Router } from '@angular/router';
import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-book-courier-company',
  templateUrl: './book-courier-company.component.html',
  styleUrls: ['./book-courier-company.component.scss']
})
export class BookCourierCompanyComponent implements OnInit {

  public order = [];
  public temp = [];
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only

  public model: NgbDateStruct;
  public date: { year: number, month: number };
  orderList: any = {}

  paymentGateway: any = {};
  deliveryMode: any = {};
  orderStatus: any = {};

  OrderId: string;

  Form = new FormGroup({
    // order_number: new FormControl(),
    // payment_gateway: new FormControl(),
    // delivery_mode: new FormControl(),
    // order_start_date: new FormControl(),
    // order_end_date: new FormControl(),
    // tracking_number: new FormControl(),
    // order_status: new FormControl(),
    // phone: new FormControl(),
    // page: new FormControl(),
    
    service: new FormControl(),
    id: new FormControl(),
    shipper_name: new FormControl(),
    phone: new FormControl(),
    address: new FormControl(),
    city: new FormControl(),
    amount: new FormControl(),
    productDetail: new FormControl(),
    weight: new FormControl(),
  });

  title = 'angular-app';
  fileName = 'Orders.xlsx';


  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(private modalService: NgbModal, private http: HttpClient,
    private _orderService: OrderApiService, private route: ActivatedRoute,
    private router: Router, private productService: ProductApiService, private calendar: NgbCalendar,
    private _courierService: CourierApiService,
    private viewScroller: ViewportScroller) {

    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getAllOrderLists();
    })

    //  this.order = orderDB.list_order;
  }

  // updateFilter(event) {
  //   const val = event.target.value.toLowerCase();

  //   // filter our data
  //   const temp = this.temp.filter(function (d) {
  //     return d.name.toLowerCase().indexOf(val) !== -1 || !val;
  //   });

  //   // update the rows
  //   this.order = temp;
  //   // Whenever the filter changes, always go back to the first page
  //   this.table.offset = 0;
  // }

  // selectToday() {
  //   this.model = this.calendar.getToday();
  // }

  ngOnInit() {
    this.getAllOrderLists();
    // this.getPaymentGateway();
    // this.getDeliveryMode();
    // this.getOrderStatus();
    // this.getCourierService();
  }

  // ngOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  // ngDropdown = 1;

  // courierServicedata = [
  //   {
  //     id: 'overnight',
  //     name: 'Overnight',
  //   },
  //   {
  //     id: 'Second Day',
  //     name: 'Second Day',
  //   },
  // ];

  // mySelect = [];
  // selectedValue: any;
  // multiple = true;

  // getCourierService() {
  //   console.log("getCourierService");
  //   this.selectedValue = (this.mySelect, this.courierServicedata)[0].name;

  //   console.log(this.selectedValue);
  // }

  getAllOrderLists() {
    //let limitcount = event.target.value
    //console.log(limitcount)
    var obj = {
      // order_number: this.filterForm.get('order_number').value,
      // payment_gateway: this.filterForm.get('payment_gateway').value,
      // delivery_mode: this.filterForm.get('delivery_mode').value,
      // order_start_date: this.filterForm.get('order_start_date').value,
      // order_end_date: this.filterForm.get('order_end_date').value,
      // order_status: this.filterForm.get('order_status').value,
      // tracking_number: this.filterForm.get('tracking_number').value,
      // phone: this.filterForm.get('phone').value
    };
    // console.log(obj)



    this._courierService.getAllOrderLists()
      .subscribe(data => {

        //   console.log(data);

        var prodData = {
          OrderId: data.id,
         
        };

        console.log(prodData)
        this.Form.patchValue(prodData);
        console.log(prodData)

        // this.Form.patchValue(prodData);

        // this.OrderId = prodData.OrderId;

        // console.log(prodData.OrderId);
        // console.log(this.OrderId);
        this.OrderId = data.id;
        
        this.orderList = data;
       // console.log(Number(data.net_amount) + Number(data.shipping));

        // this.paginate = this.productService.getPager(this.orderList.totalRecords, +this.pageNo);     // get paginate object from service
      }
      );
  }





  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('orders'); // Anchore Link
    });
  }

  getDeliveryMode() {
    this._orderService.getAllDeliveryModeLists()
      .subscribe(
        data => {
          this.deliveryMode = data;
        }
      );
  }

  // getCourierService() {
  //   this._masterCategoryService.getMasterCategories()
  //     .subscribe(
  //       data => this.Categories = data
  //     );
  // }

  getOrderStatus() {
    this._orderService.getOrderStatusLists()
      .subscribe(
        data => {
          this.orderStatus = data;
        }
      );
  }

  getPaymentGateway() {
    this._orderService.getAllPaymentGatewayLists()
      .subscribe(
        data => {
          this.paymentGateway = data;
        }
      );
  }

  ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    // console.log(input);
    return numeric;
  }

  exportexcel(): void {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  submitForm() {
    if (this.Form.valid) {

      var obj = {
        "username": "bilal_4k5829",
        "password": "Systechlogic2022!!",
        consigneeName: this.Form.get('shipper_name').value,
        "consigneeAddress": this.Form.get('address').value,
        "consigneeMobNo": this.Form.get('phone').value,
        "consigneeEmail": "",
        "destinationCityName": this.Form.get('city').value,
        "pieces": 1,
        "weight": this.Form.get('weight').value,
        "codAmount": this.Form.get('amount').value,
        "custRefNo": this.Form.get('id').value,
        "productDetails": this.Form.get('productDetail').value,
        "fragile": "no",
        "service": this.Form.get('service').value,
        "remarks": "",
        "insuranceValue": "",
        "locationID": "",
        "AccountNo": "4k5829",
        "InsertType": 0

      }

      console.log(obj)

      // this._courierService.insertAPI(obj)
      //   .subscribe(response => {
      //     console.log(response)
      //     this.modalService.dismissAll();
      //     this.ngOnInit();
      //   });

    }

  }

}

