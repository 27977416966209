<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Discount Coupon Details</h5>
        </div>
        <div class="card-body tab2-card">
            <form [formGroup]="Form" class="needs-validation" novalidate="">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span>
                                Coupon Code</label>
                            <input class="form-control col-md-7" formControlName="code"
                                id="validationCustom1" type="text" required="" (keypress)="special_char($event)">
                            <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                Discount Percentage</label>
                            <input class="form-control col-md-7" formControlName="percentage" (keypress)="numberOnly($event)"
                                id="validationCustom0" type="text" required="">
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Max Orders Limit</label>
                            <input class="form-control col-md-7" formControlName="orderlimit" type="number" (keypress)="numberOnly($event)"
                                required="">
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Max Discount Amount</label>
                            <input class="form-control col-md-7" formControlName="dicountamount" type="number" (keypress)="numberOnly($event)"
                                required="">
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Discount Type</label>
                            <select class="custom-select col-md-7 form-control" formControlName="type" required="">
                                <option value="">--Select--</option>
                                <option value="percentage">Percentage</option>
                                <option value="amount">Amount</option>
                            </select>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Start Date</label>
                            <div class="input-group col-md-7 input-grp-p">
                                <input class="datepicker-here form-control digits" ngbDatepicker
                                    formControlName="start_date" #f="ngbDatepicker" (click)="f.toggle()">
                                <div class="input-group-append">
                                    <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                            class="fa fa-calendar"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">End Date</label>
                            <div class="input-group col-md-7 input-grp-p">
                                <input class="datepicker-here form-control digits" ngbDatepicker
                                    formControlName="end_date" #c="ngbDatepicker" (click)="c.toggle()">
                                <div class="input-group-append">
                                    <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                            class="fa fa-calendar"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="pull-left">
                <div class="col-xl-6">
                    <span *ngIf="Coupon_Id == null else elseBlock_update">
                        <button type="button" class="btn btn-primary" (click)="Create()">Save</button>
                    </span>
                    <ng-template #elseBlock_update>
                        <button type="button" class="btn btn-primary" (click)="Update()">Update</button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->