import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CouponService } from 'src/app/shared/service/coupon.service';

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit {

  public model: NgbDateStruct;
  public date: { year: number, month: number };

  Coupon_Id: string;
  API: string = "vouchers";
  returnUrl: string;

  Form = new FormGroup({
    code: new FormControl(),
    percentage: new FormControl(),
    orderlimit: new FormControl(),
    dicountamount: new FormControl(),
    type: new FormControl(),
    start_date: new FormControl(),
    end_date: new FormControl()
  });

  constructor(private formBuilder: FormBuilder, private calendar: NgbCalendar,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _masterCouponService: CouponService,
    private http: HttpClient, private route: ActivatedRoute, private router: Router,
    private renderer: Renderer2, el: ElementRef,
    private spinner: NgxSpinnerService
  ) {
    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/coupons/list-coupons';
    // this.Coupon_Id = this.activatedRoute.snapshot.paramMap.get("id");

    // if(this.Coupon_Id != null){
    //   this.getProductsById(this.Coupon_Id);
    // }
  }

  // getProductsById(event: any) {
  //   console.log(event)
  //   this._masterCouponService.getSingleDataById(this.API, event)

  //     .subscribe(data => {
  //       var prodData = {
  //         code: data.voucher_code,
  //         percentage: data.discount_percentage,
  //         orderlimit: data.orders_limit,
  //         dicountamount: data.amount_limit,
  //         type: data.discount_type,
  //         start_date: data.start_date,
  //         end_date: data.end_date
  //       };

  //       // this.getSubCategoryById(0, prodData.majorname);

  //       this.Form.patchValue(prodData);


  //     },
  //       err => {
  //         //console.log(err);
  //       });
  // }

  Create() {

    // if (this.Form.get('code').value == null) {
    //   alert("Enter Coupon Code");
    //   return;
    // }

    // if (this.Form.get('title').value == null) {
    //   alert("Enter Percentage");
    //   return;
    // }

    var obj = {
      voucher_code: this.Form.get('code').value,
      discount_percentage: this.Form.get('percentage').value,
      orders_limit: this.Form.get('orderlimit').value,
      amount_limit: this.Form.get('dicountamount').value,
      discount_type: this.Form.get('type').value,
      start_date: this.Form.get('start_date').value.year + "-" + this.Form.get('start_date').value.month + "-" + this.Form.get('start_date').value.day,
      end_date: this.Form.get('end_date').value.year + "-" + this.Form.get('end_date').value.month + "-" + this.Form.get('end_date').value.day
    }
    //console.log(obj);

    this._masterCouponService.insertAPI(this.API, obj)
      .subscribe(response => {
        this.router.navigate([this.returnUrl]);
      });
  }


  Update() {

  }

}
