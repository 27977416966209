// import { userListDB } from 'src/app/shared/tables/list-users';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  /*
  public user_list = []

  constructor() {
    this.user_list = userListDB.list_user;
  }

  public settings = {
    columns: {
      avatar: {
        title: 'Avatar',
        type: 'html'
      },
      fName: {
        title: 'First Name',
      },
      lName: {
        title: 'Last Name'
      },
      email: {
        title: 'Email'
      },
      last_login: {
        title: 'Last Login'
      },
      role: {
        title: 'Role'
      },
    },
  };

  public dataSource: userListDB;
*/


  UserList: {};
  public closeResult: string;
  readonly CONSTANTS = CONSTANTS;
  modalContent: undefined

  Form = new FormGroup({
    userId: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterUserService: UsersApiService) {
    
  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      this.Form.patchValue({
        userId: tableRow.id,
        status: tableRow.deleted_at
      });
    }

    console.log(this.modalContent);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnInit(): void {
    this.getAllListAdminUser();
  }

  getAllListAdminUser() {
    this._masterUserService.getAllListAdminUser()
      .subscribe(
        data => this.UserList = data
      );
  }

  DeleteFrontCustomer(){}
  

}

