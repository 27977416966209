import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class MasterApiService {

  constructor(
    private http: HttpClient
  ) { }


  // List of Master API
  getAllLists(string, offset: number, obj) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };
    let limit = CONSTANTS.PAGINATION;

    if (obj.start_date == undefined) {
      obj.start_date = "";
    }
    else {
      obj.start_date = obj.start_date.year + '-' + obj.start_date.month + '-' + obj.start_date.day;
    }

    if (obj.end_date == undefined) {
      obj.end_date = "";
    }
    else {
      obj.end_date = obj.end_date.year + '-' + obj.end_date.month + '-' + obj.end_date.day;
    }

    // console.log(CONSTANTS.BASE_URL + '/' + string + '?start_date=' + obj.start_date + '&end_date=' + obj.end_date +'&limit=' + limit + '&offset=' + (offset - 1) * limit)
    return this.http
      .get(CONSTANTS.BASE_URL + '/' + string + '?start_date=' + obj.start_date + '&end_date=' + obj.end_date + '&limit=' + limit + '&offset=' + (offset - 1) * limit, option)
      .pipe(
        map(res => {
          console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  getAllListsFromAPI(string) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + string, option)
      .pipe(
        map(res => {
          console.log(res)
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Insert Master API
  insertAPI(string, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Group By Id Master API
  recordByIdAPI(string, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/' + string + '/' + obj, option)
      .pipe(
        map(res => {
          return res;
        }),
      )
  }

  // Update Master API
  updateAPI(string, obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // Delete Master API
  deleteAPI(string, obj): Observable<any> {
    // console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    console.log(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id, JSON.stringify(obj))

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // Restore Master API
  restoreAPI(string, obj): Observable<any> {
    console.log("ID:" + obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/' + string + '/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        catchError(this.handleError)
      )
  }

  // ======== Handle Error Start ========

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      //errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = `Error Code: ${error.status}`;
    }
    //console.log(errorMessage);
    window.alert(errorMessage);
    return throwError(() => {
      return;
    });
  }

  // ======== Handle Error End ========


}
