import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { OrdersReportComponent } from './orders-report/orders-report.component'
import { ReturnOrdersReportComponent } from './return-orders-report/return-orders-report.component';
import { InventoryReportComponent } from './inventory-report/inventory-report.component'
import { VisitProductsComponent } from './visit-products/visit-products.component';
import { PurchaseOrderReportComponent } from './purchase-order-report/purchase-order-report.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    data: {
      title: "Reports",
      breadcrumb: "Reports"
    }
  },
  {
    path: 'orders-report',
    component: OrdersReportComponent,
    data: {
      title: "Orders Report",
      breadcrumb: "Report"
    }
  },
  {
    path: 'return-orders-report',
    component: ReturnOrdersReportComponent,
    data: {
      title: "Return Orders Report",
      breadcrumb: "Report"
    }
  },
  {
    path: 'inventory-report',
    component: InventoryReportComponent,
    data: {
      title: "Inventory Report",
      breadcrumb: "Report"
    }
  },
  {
    path: 'visit-products',
    component: VisitProductsComponent,
    data: {
      title: "Visit Product",
      breadcrumb: "Report"
    }
  },
  {
    path: 'purchase-order-report',
    component: PurchaseOrderReportComponent,
    data: {
      title: "Purchase Order",
      breadcrumb: "Report"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
