import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MasterApiService } from 'src/app/shared/service/master-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

import { ProductApiService } from 'src/app/shared/service/product-api.service';
import { ViewportScroller } from '@angular/common';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent implements OnInit {

  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  modalContent: undefined
  labelBox: string;
  OutletList: any={};
  CitiesList: {};
  API: string = "outlets";

  Form = new FormGroup({
    name: new FormControl(),
    Id: new FormControl(),
    location: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
    cityname: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, 
    private _masterService: MasterApiService,
    private route: ActivatedRoute, private router: Router,
    private productService: ProductApiService, private viewScroller: ViewportScroller) {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      this.pageNo = params.page ? params.page : this.pageNo;

      // Get Filtered Products.. 
      this.getLists();
    })
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    //console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";

      this.Form.patchValue({
        name: tableRow.name,
        location: tableRow.location,
        email: tableRow.email,
        phone: tableRow.phone,
        cityname: tableRow.city_id,
        Id: tableRow.id,
        status: tableRow.deleted_at
      });

      //console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.Form.patchValue({
        name: null,
        Id: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  /*
    onInputChange(event, backspace:true) {
      let newVal = event.replace(/\D/g, '');
      if (backspace && newVal.length <= 6) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 3) {
        newVal = newVal.replace(/^(\d{0,3})/, '($1)');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
      } else if (newVal.length <= 10) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      } else {
        newVal = newVal.substring(0, 10);
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
      }
      this.ngControl.valueAccessor.writeValue(newVal);
    }
  */
  ngOnInit(): void {
    this.getLists();
    this.getCitiesLists();
  }

  getLists() {
    this._masterService.getAllListsFromAPI(this.API)
    .subscribe(
      data => {
        this.OutletList = data;
        this.paginate = this.productService.getPager(this.OutletList.totalRecords, +this.pageNo);
      }
    );
}

  getCitiesLists() {
    this._masterService.getAllListsFromAPI("cities")
      .subscribe(
        data => this.CitiesList = data
      );
  }

  Create() {
    if (this.Form.get('name').value == null) {
      alert('Enter Country Name');
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('name').value,
        city_id: this.Form.get('cityname').value,
        location: this.Form.get('location').value,
        email: this.Form.get('email').value,
        phone: this.Form.get('phone').value
      }

      //console.log(obj);

      this._masterService.insertAPI(this.API, obj)
        .subscribe(response => {
          //console.log(response);
          window.location.reload();
        });
    }
  }

  Update() {
    if (this.Form.valid) {

      var obj = {
        id: this.Form.get('Id').value,
        name: this.Form.get('name').value,
        city_id: this.Form.get('cityname').value,
        location: this.Form.get('location').value,
        email: this.Form.get('email').value,
        phone: this.Form.get('phone').value
      }

      this._masterService.updateAPI(this.API, obj)
        .subscribe(response => {
          //console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  Delete() {
    var obj = {
      id: this.Form.get('Id').value,
      status: this.Form.get('status').value
    }

    if (JSON.stringify(obj.status) == 'null') {
      this._masterService.deleteAPI(this.API, obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterService.restoreAPI(this.API, obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}
