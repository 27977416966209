import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'

@Injectable({
  providedIn: 'root'
})
export class CourierApiService {

  constructor(
    private http: HttpClient
  ) { }

  insertAPI(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    //string API= "http://mnpcourier.com/mycodapi/api/Tracking/Consignment_ReferenceNo_Tracking?Username=bilal_4k5829&password=Systechlogic2022!!&Ref_No=100000000050";
    return this.http
      .post('http://mnpcourier.com/mycodapi/api/Tracking/Consignment_ReferenceNo_Tracking?' + '/' + JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // getAllOrderLists(): Observable<any> {
    getAllOrderLists() : Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/courierorders' , option)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
  }


  // ======== Handle Error Start ========

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      //errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage = `Error Code: ${error.status}`;
    }
    //console.log(errorMessage);
    window.alert(errorMessage);
    return throwError(() => {
      return;
    });
  }

  // ======== Handle Error End ========

}
