<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Manage Order</h5>
                </div>
                <div class="card-body">
                    <div class="">

                        <div class="btn-popup pull-left" style="width: 100%;" [formGroup]="filterForm">
                            <table class="filter-tbl">
                                <tr>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Payment
                                                Gateway:</strong></label></td>
                                    <td class="text">
                                        <select class="form-control select2 select2-hidden-accessible"
                                            id="payment_gateway" formControlName="payment_gateway">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Payment Gateway ---
                                            </option>
                                            <option *ngFor="let MasterCategory of paymentGateway"
                                                [value]="MasterCategory.id">
                                                {{MasterCategory.name | uppercase}}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Delivery
                                                Mode:</strong></label></td>
                                    <td class="text">
                                        <select class="form-control select2 select2-hidden-accessible"
                                            id="delivery_mode" formControlName="delivery_mode">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Delivery Mode ---
                                            </option>
                                            <option *ngFor="let MajorCategory of deliveryMode"
                                                [value]="MajorCategory.id">
                                                {{MajorCategory.name | uppercase}}
                                            </option>
                                        </select>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Order
                                                Status:</strong></label>
                                    </td>
                                    <td class="text">
                                        <select class="form-control select2 select2-hidden-accessible" id="order_status"
                                            formControlName="order_status">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Order Status ---
                                            </option>
                                            <option *ngFor="let SubCat of orderStatus.orderStatuses"
                                                [value]="SubCat.id">
                                                {{SubCat.name | uppercase}}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Tracking
                                                Number:</strong></label></td>
                                    <td class="text">
                                        <input class="form-control" id="validationCustom01"
                                            formControlName="tracking_number" type="text">
                                    </td>

                                </tr>
                                <tr>

                                    <td class="label"><label for="validationCustom01"
                                            class="mb-1"><strong>Phone:</strong></label></td>
                                    <td class="text">
                                        <input class="form-control" id="validationCustom01" formControlName="phone"
                                            type="text">
                                    </td>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Order
                                                Number:</strong></label></td>
                                    <td class="text">
                                        <input class="form-control" id="validationCustom01"
                                            formControlName="order_number" type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Order Start
                                                Date:</strong></label></td>
                                    <td class="text">
                                        <div class="input-group col-md-12 input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="order_start_date" #f1="ngbDatepicker" (click)="f1.toggle()">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="f1.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="label"><label for="validationCustom01" class="mb-1"><strong>Order End
                                                Date:</strong></label></td>
                                    <td class="text">
                                        <div class="input-group col-md-12 input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="order_end_date" #f2="ngbDatepicker" (click)="f2.toggle()">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="f2.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </td>

                                </tr>

                                <tr>
                                    <td colspan="3">
                                        <button type="button" class="btn btn-primary"
                                            (click)="getAllOrderLists()">Search</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <br>

                        <!-- <label><strong>Per Page: </strong></label>
                        <select formControlName="page" (change)="getAllOrderLists($event)">
                            <option value="10" selected>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select> -->
                        <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button>
                        <table id="excel-table" class="table table-striped users">
                            <tr>
                                <th class="center-align left-tbl" width="5%">#</th>
                                <th class="center-align" width="10%">Order Number</th>
                                <th class="center-align" width="10%">Tracking Number</th>
                                <th class="center-align" width="10%">Customer Name</th>
                                <!-- <th class="center-align" width="10%">Payment Gateway</th> -->
                                <th class="center-align" width="10%">Delivery Mode</th>
                                <th class="center-align" width="10%">Order Status</th>
                                <th class="center-align" width="10%">Date</th>
                                <th class="center-align" width="10%">TotalTotal</th>
                                <th class="center-align right-tbl" width="10%">Action</th>
                            </tr>
                            <ng-container *ngFor="let OrderData of orderList.orders; index as i">
                                <tr class="left-align" [ngClass]="OrderData.order_status_name == 'Returned'? 'red':'blue'  ">
                                    <!-- *ngIf="OrderData.order_status_name == 'Returned' then class='red';"> -->
                                    

                                    
                                    
                                    <td class="center-align" scope="row">{{ i + 1 }}</td>
                                    <td class="center-align">
                                        {{OrderData.id}}
                                    </td>
                                    <td class="center-align">
                                        {{OrderData.tracking_name}}
                                    </td>
                                    <td class="left-align">{{OrderData.shipper_name}}</td>
                                    <!-- <td class="center-align">
                                        {{OrderData.payment_gateway_name}}
                                    </td> -->
                                    <td class="center-align">
                                        {{OrderData.delivery_mode_name}}
                                    </td>
                                    <td class="center-align" >
                                        {{OrderData.order_status_name}}
                                    </td>
                                    <td class="center-align">
                                        {{OrderData.date | date:'fullDate'}}
                                    </td>
                                    <td class="center-align">
                                        {{OrderData.net_amount + ConvertStringToNumber(OrderData.shipping) | currency:'Rs. '}}
                                    </td>
                                    <td class="center-align">
                                        <a [routerLink]="['/sales/orders/orderdetail',OrderData.id]">View</a>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <app-pagination [products]="orderList.orders" [paginate]="paginate" (setPage)="setPage($event)">
                        </app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->