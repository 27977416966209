import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../../service/appsettings'
import { User} from '../login/user-model.model'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class LoginApiService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  authdata?: string;

  constructor(private http: HttpClient,private router: Router) {

    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('E-Com-Admin-user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string): Observable<any> {

    console.log(username, password)
    return this.http.post(CONSTANTS.BASE_URL + '/admin/login', { email: username, password })
      .pipe(map(user => {
        localStorage.setItem('E-Com-Admin-user', JSON.stringify(user));
       return JSON.stringify(user);
      }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('E-Com-Admin-user');
    this.userSubject.next(null);
    this.router.navigate(['/auth/login']);
}

// getAll() {
//   return this.http.get<User[]>(`${CONSTANTS.BASE_URL}/users`);
// }




}
