import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { FrontCustomersComponent } from './front-customers/front-customers.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesComponent } from './roles/roles.component';
import { CreateRoleComponent } from './create-role/create-role.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        data: {
          title: "Admin User",
          breadcrumb: "Admin User"
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: "Create User",
          breadcrumb: "Create User"
        }
      },
      {
        path: 'front-customers',
        component: FrontCustomersComponent,
        data: {
          title: "Front Customer",
          breadcrumb: "Front Customer"
        }
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        data: {
          title: "User Permissions",
          breadcrumb: "User Permissions"
        }
      },
      {
        path: 'roles',
        component: RolesComponent,
        data: {
          title: "User Roles",
          breadcrumb: "User Roles"
        }
      },
      {
        path: 'create-role',
        component: CreateRoleComponent,
        data: {
          title: "Create Role",
          breadcrumb: "Create Role"
        }
      },
      {
       
        path: 'create-user/:id',
        component: CreateUserComponent,
        data: {
          title: "Edit Create",
          breadcrumb: "Edit User"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
