<!-- Container-fluid starts-->
<!-- <ngx-spinner type="line-spin-clockwise-fade">
    <p class="loader"></p>
</ngx-spinner> -->

<div class="container-fluid" [formGroup]="Form">
    <div class="row">
        <div class="col-xl-4 col-md-6 xl-50">
            <div class="input-group col-md-12 input-grp-p">
                <label for="validationCustom01" class="mb-1" style="line-height: 34px;margin: 0 10px 0 0;">
                    <strong>Date:</strong></label>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="start_date"
                    #f1="ngbDatepicker" (click)="f1.toggle()">
                <span class="input-group-addon">to</span>
                <input class="datepicker-here form-control digits" ngbDatepicker formControlName="end_date"
                    #f2="ngbDatepicker" (click)="f2.toggle()">
            </div>
        </div>

        <div class="col-xl-4 col-md-6 xl-50">
            <button type="button" class="btn btn-primary" (click)="getLists()">Search</button>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body" style="overflow-x:auto;">
                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <button (click)="exportexcel()" class="btn btn-primary">Export to Excel</button>
                            <table id="excel-table" class="table table-striped report-tbl users">
                                <!-- <table id="users" class="table table-striped report-tbl"> -->
                                <tr>
                                    <th width="20%" class="center-align left-tbl">#</th>
                                    <th width="20%" class="center-align">Product Name</th>
                                    <th width="30%" class="center-align">Varient Group</th>
                                    <th width="13%" class="center-align">Product Varient</th>
                                    <th width="13%" class="center-align">Stock In</th>
                                    <th width="12%" class="center-align">Stock Out</th>
                                    <th width="12%" class="center-align">Stock In Date</th>
                                    <th width="12%" class="center-align right-tbl">Stock Out Date</th>
                                </tr>
                                <ng-container *ngFor="let list of ReportList.inventory; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td>{{list.product_name}}</td>
                                        <td class="center-align">{{list.product_varient_group_name }}</td>
                                        <td class="center-align">{{list.product_varient_name}}</td>
                                        <td class="center-align">{{list.stock_in}}</td>
                                        <td class="center-align">{{list.stock_out}}</td>
                                        <td class="center-align">{{list.stock_in_date}}</td>
                                        <!-- <td class="center-align">{{list.stock_in_date | date:'fullDate'}}</td> -->
                                        <td class="center-align">{{list.stock_out_date.split(',') }}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <app-pagination [products]="ReportList.inventory" [paginate]="paginate"
                                (setPage)="setPage($event)">
                            </app-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->