import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { majorCategoryDB } from 'src/app/shared/tables/major-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-major-category',
  templateUrl: './major-category.component.html',
  styleUrls: ['./major-category.component.scss']
})
export class MajorCategoryComponent implements OnInit {

  ImageBaseData: string | ArrayBuffer = null;
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  localUrl: any;


  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public major_categories = []
  public majorCat: majorCategoryDB[]; // create a variable of type tbl_language object  
  modalContent: undefined
  labelBox: string;
  labelImg: string;
  Categories: {};
  MajorCategoriesDataList: {};

  catForm = new FormGroup({
    majorname: new FormControl(),
    mastername: new FormControl(),
    catImage: new FormControl(),
    catId: new FormControl(),
    status: new FormControl()
  });

  constructor(private modalService: NgbModal, 
    private http: HttpClient, 
    private _masterCategoryService: CategoriesApiService, 
    fb: FormBuilder, private imageCompress: NgxImageCompressService,
    private renderer: Renderer2,el: ElementRef) {
    this.major_categories = majorCategoryDB.major_category;
  
    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  ddlCategory = "";

  open(content, tableRow) {

    this.modalContent = tableRow
    console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;
      // this.Id = tableRow.id;

      this.catForm.patchValue({
        majorname: tableRow.name,
        catId: tableRow.id,
        mastername: tableRow.master_category_id,
        status: tableRow.deleted_at
      });

      console.log(this.catForm);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.catForm.patchValue({
        majorname: null,
        catId: null,
        mastername: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getDataList();
    this.getMasterCategory();
  }

  /*
  handleFileInput(files: FileList) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      me.ImageBaseData = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
*/

  selectFile(event: any) {
    var fileName: any;
    let file = event.target.files[0];
    fileName = file['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        this.compressFiles(this.localUrl, fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  compressFiles(image, fileName) {
    var orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 100, 100).then(
      result => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
        console.warn('Size in bytes after compression:', this.sizeOFCompressedImage);
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
        //imageFile created below is the new compressed file which can be send to API in form data
        const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  getDataList() {
    this._masterCategoryService.getAllMasterCategoriesList()
      .subscribe(
        data => this.MajorCategoriesDataList = data
      );
  }

  getMasterCategory() {
    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => this.Categories = data
      );
  }

  submitForm() {
    if (this.catForm.valid) {

      if (this.catForm.get('majorname').value == null) {
        alert('Select Major Category');
      }

      if (this.catForm.get('mastername').value == null) {
        alert('Select Master Category');
      }

      var obj = {
        name: this.catForm.get('majorname').value,
        master_category_id: this.catForm.get('mastername').value,
        media_string: this.localCompressedURl
        //media_string: fileUplodVM.ImageBaseData
      }

      this._masterCategoryService.insertMajorCategoryData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }


  UpdateMajorCategory() {
    if (this.catForm.valid) {
      
      var obj = {
        id: this.catForm.get('catId').value,
        name: this.catForm.get('majorname').value,
        master_category_id: this.catForm.get('mastername').value,
        media_string: this.localCompressedURl
      }

      this._masterCategoryService.updateMajorCategoryData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }


  DeleteMasterCategory() {
    // var id = this.catForm.get('catId').value
    // this._masterCategoryService.deleteMajorCategoryData(id)
    //   .subscribe(response => {
    //     console.log(response)
    //     this.modalService.dismissAll();
    //       window.location.reload();
    //   });




    var obj = {
      id: this.catForm.get('catId').value,
      status: this.catForm.get('status').value
    }

    // console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterCategoryService.deleteMajorCategoryData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
    else {
      this._masterCategoryService.restoreMajorCategoryData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }



}

export class FileUplodVM {
  ImageBaseData: string;
}

