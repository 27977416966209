import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CONSTANTS } from '../service/appsettings'
import { json } from 'express';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  ImageBaseData: string | ArrayBuffer = null;

  constructor(
    private http: HttpClient
  ) { }

  // API File

  // ======= All Order Lists =======
  /*
    getAllOrderLists(offset: number) {
      let option = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
        })
      };
  
      //Pagination limit to showcase number of rows on one page
      let limit = 15;// limitcount;
  
      //console.log(CONSTANTS.BASE_URL + '/orders?limit=' + limit + '&offset=' + (offset - 1) * limit, option)
  
      return this.http
        .get(CONSTANTS.BASE_URL + '/orders?limit=' + limit + '&offset=' + (offset - 1) * limit, option)
        .pipe(
          map(res => {
            return res;
          }),
          //  catchError()
        )
    }
  */
  getAllOrderFilterLists(offset: number, obj) {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    //Pagination limit to showcase number of rows on one page
    let limit = CONSTANTS.PAGINATION;// limitcount;

    if (obj.order_number == undefined) {
      obj.order_number = "";
    }

    if (obj.payment_gateway == undefined) {
      obj.payment_gateway = "";
    }

    if (obj.delivery_mode == undefined) {
      obj.delivery_mode = "";
    }

    if (obj.order_status == undefined) {
      obj.order_status = "";
    }

    if (obj.order_start_date == undefined) {
      obj.order_start_date = "";
    }
    else {
      obj.order_start_date = obj.order_start_date.year + '-' + obj.order_start_date.month + '-' + obj.order_start_date.day;
    }

    if (obj.order_end_date == undefined) {
      obj.order_end_date = "";
    }
    else {
      obj.order_end_date = obj.order_end_date.year + '-' + obj.order_end_date.month + '-' + obj.order_end_date.day;
    }

    if (obj.tracking_number == undefined) {
      obj.tracking_number = "";
    }

    if (obj.phone == undefined) {
      obj.phone = "";
    }

   // console.log(CONSTANTS.BASE_URL + '/orders?' + 'order_number=' + obj.order_number + '&payment_gateway_id=' + obj.payment_gateway + '&delivery_mode_id=' + obj.delivery_mode + '&order_status_id=' + obj.order_status + '&start_date=' + obj.order_start_date + '&end_date=' + obj.order_end_date +'&tracking_name=' + obj.tracking_number + '&phone=' + obj.phone + '&limit=' + limit + '&offset=' + (offset - 1) * limit);

    return this.http
      .get(CONSTANTS.BASE_URL + '/orders?' + 'order_number=' + obj.order_number + '&payment_gateway_id=' + obj.payment_gateway + '&delivery_mode_id=' + obj.delivery_mode + '&order_status_id=' + obj.order_status + '&start_date=' + obj.order_start_date + '&end_date=' + obj.order_end_date +'&tracking_name=' + obj.tracking_number + '&phone=' + obj.phone + '&limit=' + limit + '&offset=' + (offset - 1) * limit, option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  updateOrder(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/orders/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // ======= All Order Lists =======

  getAllDeliveryModeLists() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/delivery-modes', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  getDeliveryModeById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/delivery-modes/' + obj, option)
      .pipe(
        map(res => {
          //  console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  getOrderStatusLists() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/order-statuses', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  getOrderStatusById(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/order-statuses/' + obj, option)
      .pipe(
        map(res => {
          // console.log(res)
          return res;
        }),
        //  catchError()
      )
  }

  // ======= All Order Detail By Id =======
  getSingleOrderDetailById(obj): Observable<any> {
    // console.log("Data___: "+obj)
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/orders/' + obj, option)
      .pipe(
        map(res => {
          //console.log("Data___: " + JSON.stringify(res))
          return JSON.stringify(res);
        }),
        //  catchError()
      )
  }


  // ======== Delivery Mode Start ========

  // Add Brand
  insertDeliveryMode(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/delivery-modes', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Update Brand
  updateDeliveryMode(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/delivery-modes/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


  // ======= Payment Gateway Start =======

  // List of Payment Gateway
  getAllPaymentGatewayLists() {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    return this.http
      .get(CONSTANTS.BASE_URL + '/payment-gateways', option)
      .pipe(
        map(res => {
          return res;
        }),
        //  catchError()
      )
  }

  // Add Payment Gateway
  insertPaymentGateway(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    // console.log(JSON.stringify(obj));
    return this.http
      .post(CONSTANTS.BASE_URL + '/payment-gateways', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Update Payment Gateway
  updatePaymentGateway(obj): Observable<any> {
    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PUT';

    return this.http
      .post(CONSTANTS.BASE_URL + '/payment-gateways/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Delete Master Category
  deletePaymentGateway(obj): Observable<any> {
    // console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'DELETE';

    return this.http
      .post(CONSTANTS.BASE_URL + '/payment-gateways/' + obj.id, JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }

  // Restore Master Category
  restorePaymentGateway(obj): Observable<any> {
    // console.log("ID:"+obj);

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSTANTS.USER_TOKEN}`
      })
    };

    obj['_method'] = 'PATCH';

    return this.http
      .post(CONSTANTS.BASE_URL + '/payment-gateways/' + obj.id + '/restore', JSON.stringify(obj), option)
      .pipe(
        map(res => {
          console.log(res);
          return res;
        }),
        //catchError(this.global.handleError)
      )
  }


}
