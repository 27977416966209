import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersApiService } from 'src/app/shared/service/users-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

// import { LocalDataSource } from 'ng2-smart-table';


@Component({
  selector: 'app-front-customers',
  templateUrl: './front-customers.component.html',
  styleUrls: ['./front-customers.component.scss'],
  // template: `
  //   <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
  // `,
})
export class FrontCustomersComponent implements OnInit {

  UserList: {};
  public closeResult: string;
  readonly CONSTANTS = CONSTANTS;
  modalContent: undefined

  Form = new FormGroup({
    userId: new FormControl(),
    status: new FormControl()
  });


  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      this.Form.patchValue({
        userId: tableRow.id,
        status: tableRow.deleted_at
      });
    }

    console.log(this.modalContent);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  // source: LocalDataSource;

  // settings = {
  //   columns: {
  //     id: {
  //       title: 'ID',
  //       editable: false,
  //       addable: false,
  //     },


  //     name: {
  //       title: 'Full Name',
  //     },
  //     username: {
  //       title: 'User Name',
  //     },
  //     email: {
  //       title: 'Email',
  //     },
  //   },
  // };

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterUserService: UsersApiService, config: NgbRatingConfig) {
    config.max = 5;
    config.readonly = true;
  }


  ngOnInit(): void {
    this.getAllListFrontCustomers();
  }

  getAllListFrontCustomers() {
    this._masterUserService.getAllListFrontCustomers()
      .subscribe(
        data => this.UserList = data
      );



    // this.source = new LocalDataSource();

    // this._masterUserService.getAllListFrontCustomers()
    // .subscribe(response => {
    //   console.log(response);
    //   this.source.load(response);
    // });


  }

  DeleteFrontCustomer() {
    var obj = {
      id: this.Form.get('userId').value,
      status: this.Form.get('status').value
    }

    console.log("Delete Function: " + JSON.stringify(obj.id));
    console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterUserService.deleteFrontCustomerData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterUserService.restoreFrontCustomerData(obj)
        .subscribe(response => {
          console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }




}
