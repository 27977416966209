<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-body">
                    <div class="btn-popup pull-right">

                        <button type="button" class="btn btn-secondary" data-toggle="modal"
                            data-original-title="Add Slider Type" data-target="#exampleModal"
                            (click)="open(content, 0)">Add Slider Type</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Slider Type</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" [formGroup]="Form">
                                <input formControlName="id" type="hidden">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Name:</label>
                                        <input class="form-control" id="name" formControlName="name" type="text"
                                            placeholder="Slider Type Name">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">

                                <div *ngIf="labelBox== 'Edit'; else elseBlock">
                                    <button type="button" class="btn btn-primary" (click)="Update()">Update</button>
                                </div>

                                <ng-template #elseBlock>
                                    <button type="button" class="btn btn-primary" (click)="Create()">Save</button>
                                </ng-template>

                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>


                        <!-- Delete Div -->
                        <ng-template #delcontent let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete Slider Banner</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" [formGroup]="Form">
                                <input formControlName="id" type="hidden">
                                <input formControlName="status" type="hidden">
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" (click)="Delete()">Yes</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">No</button>
                            </div>
                        </ng-template>



                    </div>



                    <div class="btn-popup pull-right">

                        <!-- <ng-template #contentEdit let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Edit Master Category</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" [formGroup]="Form">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Master Category Name:</label>
                                        <input class="form-control" id="validationCustom01" formControlName="catname"
                                            type="text" value="" placeholder="Master Category Name">
                                    </div>
                                    <div class="form-group mb-0">
                                        <label for="validationCustom02" class="mb-1">Category Image :</label>
                                        <input type="file" accept=".jpg,.png,.jpeg" id="catImage" name="catImage" (change)="selectFile($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="Create()">Save</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template> -->
                    </div>


                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="50%">Name</th>
                                    <th width="10%" class="center-align">Status</th>
                                    <th width="20%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let m of List; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td>{{m.name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="m.deleted_at == null; else elseBlock">
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span">
                                                <a (click)="open(content, m)" class="badge badge-success">
                                                    Edit
                                                </a>
                                            </span>
                                            <span class="space-span"
                                                *ngIf="m.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, m)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, m)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>


                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->