<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row" [formGroup]="catForm">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Major Category</h5>
                </div> -->
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-secondary" data-toggle="modal"
                            data-original-title="Add Master Category" data-target="#exampleModal"
                            (click)="open(content,0)">Add Major Category</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Major Category</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" >
                                <input formControlName="catId" type="hidden">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Major Category Name :</label>
                                        <input class="form-control" id="validationCustom01" type="text"
                                            placeholder="Enter Major Category Name" formControlName="majorname" (keypress)="special_char($event)">
                                    </div>
                                    <!-- formControlName="mastername" -->
                                    <div class="form-group">
                                        <label for="validationCustom01" class="mb-1">Master Category Name :</label>
                                        <select class="custom-select" id="catmajorname" formControlName="mastername">
                                            <option value="" class="text-primary font-weight-bolder">
                                                --- Select Category ---
                                            </option>
                                            <option *ngFor="let Category of Categories" [value]="Category.id">
                                                {{Category.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <!-- Required Validation Message -->
                                    <!-- <div *ngIf="form.submitted && DDCategory.invalid" class="text-danger">
                                            <h6 *ngIf="DDCategory.errors.required">Category is Required</h6>
                                        </div> -->

                                    <div class="form-group mb-0">
                                        <label for="validationCustom02" class="mb-1">Major Category Image :</label>
                                        <!-- <input class="form-control" id="validationCustom02" formControlName="catimg"  type="file"> -->

                                        <!-- <input type="file" id="catImage" name="catImage" accept=".png, .jpg, .jpeg"
                                            (change)="handleFileInput($event.target.files)"> -->

                                        <input type="file" accept=".jpg,.png,.jpeg" id="catImage" name="catImage"
                                            (change)="selectFile($event)">

                                        <img src="{{labelImg}}" class="mr-2" style="width: 30px">
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                            <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-primary" (click)="submitForm()">Save</button> -->

                                <div *ngIf="labelBox== 'Edit'; else elseBlock">
                                    <button type="button" class="btn btn-primary"
                                        (click)="UpdateMajorCategory()">Update</button>
                                </div>
                                <ng-template #elseBlock>
                                    <button type="button" class="btn btn-primary" (click)="submitForm()">Save</button>
                                </ng-template>

                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>



                        <!-- Delete Div -->
                        <ng-template #delcontent let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete Major Category</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" >
                                <!-- <div class="modal-body" [formGroup]="catForm"> -->
                                <input formControlName="catId" type="hidden">
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="DeleteMasterCategory()">Yes</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">No</button>
                            </div>
                        </ng-template>


                    </div>



                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <!-- <ng2-smart-table [settings]="settings" [source]="major_categories"></ng2-smart-table> -->

                            <table id="users" class="table table-striped">
                                <thead>
                                    <th class="center-align left-tbl" width="5%">#</th>
                                    <th width="10%" class="center-align">Image</th>
                                    <th width="30%">Master Category Name</th>
                                    <th width="30%">Major Category Name</th>
                                    <th class="center-align" width="10%">Status</th>
                                    <th class="center-align right-tbl" width="10%">Action</th>
                                </thead>
                                <ng-container *ngFor="let DataList of MajorCategoriesDataList; index as i">
                                    <tr class="left-align">
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td class="center-align">
                                            <img [src]="CONSTANTS.IMG_URL +'/'+ DataList.url" class="mr-2"
                                                style="width: 30px">
                                        </td>
                                        <td>{{DataList.master_category_name}}</td>
                                        <td>{{DataList.name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="DataList.deleted_at == null; else elseBlock">
                                                <span title="Active" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="InActive" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span">
                                                <a (click)="open(content, DataList)" class="badge badge-success"> Edit
                                                </a>
                                            </span>
                                            <!-- <span class="space-span">
                                                <a (click)="open(delcontent, DataList)" class="delete"> Delete
                                                </a>
                                            </span> -->


                                            <span class="space-span"
                                                *ngIf="DataList.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, DataList)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, DataList)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->