import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { OrdersReportComponent } from './orders-report/orders-report.component';
import { ReturnOrdersReportComponent } from './return-orders-report/return-orders-report.component';
import { InventoryReportComponent } from './inventory-report/inventory-report.component'
import { SharedModule } from './../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VisitProductsComponent } from './visit-products/visit-products.component';
import { PurchaseOrderReportComponent } from './purchase-order-report/purchase-order-report.component';

@NgModule({
  declarations: [ReportsComponent, OrdersReportComponent, ReturnOrdersReportComponent, InventoryReportComponent, VisitProductsComponent, PurchaseOrderReportComponent],
  imports: [
    CommonModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    ReportsRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule
  ]
})
export class ReportsModule { }
