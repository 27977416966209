import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { digitalSubCategoryDB } from 'src/app/shared/tables/digital-sub-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesApiService } from 'src/app/shared/service/categories-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-digital-sub-category',
  templateUrl: './digital-sub-category.component.html',
  styleUrls: ['./digital-sub-category.component.scss']
})
export class DigitalSubCategoryComponent implements OnInit {

  ImageBaseData: string | ArrayBuffer = null;

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_sub_categories = []
  public major_category = [];
  public Employe = [];
  modalContent: undefined
  labelBox: string;
  labelImg: string;

  catForm = new FormGroup({
    majorname: new FormControl(),
    mastername: new FormControl(),
    majorcatname: new FormControl(),
    catId: new FormControl(),
    status: new FormControl()
  });

  Categories: {};
  SubCategoriesDataList: {};

  MajorCategories: digitalSubCategoryDB[] = [];

  selectedMajorCategory: string = '';

  constructor(private modalService: NgbModal, private http: HttpClient, 
    private _masterCategoryService: CategoriesApiService, 
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,el: ElementRef) {
    this.digital_sub_categories = digitalSubCategoryDB.digital_sub_category;
  
    var events = 'cut copy paste';
    events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      })
    );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  open(content, tableRow) {

    this.modalContent = tableRow
    //console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;
      // this.Id = tableRow.id;

      this.catForm.patchValue({
        majorname: tableRow.name,
        catId: tableRow.id,
        mastername: tableRow.master_category_id,
        majorcatname: tableRow.major_category_id,
        status: tableRow.deleted_at
      });

     // console.log(this.catForm);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.catForm.patchValue({
        majorname: null,
        catId: null,
        mastername: null,
        majorcatname: null
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  ngOnInit() {
    this.getDataList();
    this.getMasterCategory();
    //this.getMajorCategoryById();
  }


  handleFileInput(files: FileList) {
    let me = this;
    let file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //console.log(reader.result);
      me.ImageBaseData = reader.result;
    };
    reader.onerror = function (error) {
      //console.log('Error: ', error);
    };
  }

  // Show Master Category List on DropDown
  getMasterCategory() {
    this._masterCategoryService.getMasterCategories()
      .subscribe(
        data => this.Categories = data
      );
  }

  // Show Major Category List on DropDown
  getMajorCategoryById(event: any) {
    this.showSpinner(); 
    this._masterCategoryService.getMajorCategoriesById(event.target.value)
      .subscribe(
        data => {
          this.MajorCategories = data;
          this.hideSpinner();
        }
      );
  }


  // Data List Of Sub Category Page
  getDataList() {
    this.showSpinner(); 
    this._masterCategoryService.getAllSubCategoriesList()
      .subscribe(
        data => {
          this.SubCategoriesDataList = data;
          this.hideSpinner();
        }
      );
  }

  // Save Data into Database
  submitForm() {
    if (this.catForm.valid) {
      var obj = {
        name: this.catForm.get('majorname').value,
        master_category_id: this.catForm.get('mastername').value,
        major_category_id: this.catForm.get('majorcatname').value
      }

      this._masterCategoryService.insertSubCategoryData(obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }

  UpdateSubCategory() {
    if (this.catForm.valid) {

      var obj = {
        id: this.catForm.get('catId').value,
        name: this.catForm.get('majorname').value,
        master_category_id: this.catForm.get('mastername').value,
        major_category_id: this.catForm.get('majorcatname').value
      }

      this._masterCategoryService.updateSubCategoryData(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          this.ngOnInit();
        });
    }
  }


  DeleteSubCategory() {
    // var id = this.catForm.get('catId').value
    // this._masterCategoryService.deleteSubCategoryData(id)
    //   .subscribe(response => {
    //     console.log(response)
    //     this.modalService.dismissAll();
    //       window.location.reload();
    //   });


      var obj = {
        id: this.catForm.get('catId').value,
        status: this.catForm.get('status').value
      }
  
      // console.log("Delete Function: " + JSON.stringify(obj.status));
  
      if (JSON.stringify(obj.status) == 'null') {
        this._masterCategoryService.deleteSubCategoryData(obj)
          .subscribe(response => {
            console.log(response)
            this.modalService.dismissAll();
            this.ngOnInit();
          });
      }
      else {
        this._masterCategoryService.restoreSubCategoryData(obj)
          .subscribe(response => {
            console.log(response)
            this.modalService.dismissAll();
            this.ngOnInit();
          });
      }



  }



}

export class FileUplodVM {
  ImageBaseData: string;
}
