import { Component, OnInit } from '@angular/core';
import { digitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SlidersApiService } from 'src/app/shared/service/sliders-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'
// import { ResourceLoader } from '@angular/compiler';
// import { Content } from '@angular/compiler/src/render3/r3_ast';
// import { DataUrl, DOC_ORIENTATION, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-slider-type',
  templateUrl: './slider-type.component.html',
  styleUrls: ['./slider-type.component.scss']
})
export class SliderTypeComponent implements OnInit {

  API: string = "slider-types";
  localUrl: any;
  base64textString = [];
  labelBox: string;
  labelImg: string;
  modalContent: undefined
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;
  public digital_categories = []

  Form = new FormGroup({
    name: new FormControl(),
    id: new FormControl(),
    status: new FormControl()
  });

  searchForm = new FormGroup({
    search: new FormControl()
  });

  List: {};
  siderTypeList: {};

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private _masterSliderService: SlidersApiService) {

  }

  open(content, tableRow) {
    this.modalContent = tableRow

    if (this.modalContent != 0) {
      //console.log(this.modalContent)
      //console.log(CONSTANTS.IMG_URL + '/' + tableRow.url)

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;
      // this.Id = tableRow.id;

      this.Form.patchValue({
        name: tableRow.name,
        id: tableRow.id,
        status: tableRow.deleted_at
      });

      //console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        catname: null,
        catId: null,
        //catimg: tableRow.url
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.getSliderBanner();
    this.getSliderType();
  }

  getSliderBanner() {
    this._masterSliderService.getSliderBanner(this.API)
      .subscribe(
        data => this.List = data
      );
  }

  getSliderType() {
    this._masterSliderService.getSliderType(this.API)
      .subscribe(
        data => {
          this.siderTypeList = data;
        }
      );
  }

  getMasterDataById(event: any) {
    this._masterSliderService.getSliderBannerById(this.API,event)
      .subscribe(response => {
       // console.log(response)
      });
  }

  Create() {
    if (this.Form.valid) {
      var obj = {
        name: this.Form.get('name').value,
        media_string: this.localCompressedURl
      }

      this._masterSliderService.insertData(this.API,obj)
        .subscribe(response => {
         // console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

  Update() {
    var obj = {
      name: this.Form.get('name').value,
      id: this.Form.get('id').value
    }

    this._masterSliderService.updateData(this.API,obj)
      .subscribe(response => {
       // console.log(response);
        this.modalService.dismissAll();
        window.location.reload();
      });
  }

  Delete() {
    var obj = {
      id: this.Form.get('id').value,
      status: this.Form.get('status').value
    }

    //console.log("Delete Function: " + JSON.stringify(obj.status));

    if (JSON.stringify(obj.status) == 'null') {
      this._masterSliderService.deleteData(this.API,obj)
        .subscribe(response => {
          //console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
    else {
      this._masterSliderService.restoreData(this.API,obj)
        .subscribe(response => {
         // console.log(response)
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}

export class FileUplodVM {
  ImageBaseData: string;
}


