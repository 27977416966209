import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SalesRoutingModule } from './sales-routing.module';
import { OrdersComponent } from './orders/orders.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { DeliverymodeComponent } from './deliverymode/deliverymode.component';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { SharedModule } from './../../shared/shared.module';
import { BookCourierCompanyComponent } from './book-courier-company/book-courier-company.component';


@NgModule({
  declarations: [OrdersComponent, TransactionsComponent, DeliverymodeComponent, OrderdetailComponent, PaymentGatewayComponent, BookCourierCompanyComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SalesRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    NgbModule,
    SharedModule
  ]
})
export class SalesModule { }
