import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OrderApiService } from 'src/app/shared/service/order-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CONSTANTS } from 'src/app/shared/service/appsettings'

@Component({
  selector: 'app-deliverymode',
  templateUrl: './deliverymode.component.html',
  styleUrls: ['./deliverymode.component.scss']
})
export class DeliverymodeComponent implements OnInit {

  readonly CONSTANTS = CONSTANTS;
  public closeResult: string;

  modalContent: undefined
  labelBox: string;
  labelImg: string;
  DeliveryList: {};

  Form = new FormGroup({
    name: new FormControl(),
    Id: new FormControl()
  });

  constructor(private modalService: NgbModal, private http: HttpClient, private _masterOrderService: OrderApiService) {

  }

  open(content, tableRow) {

    this.modalContent = tableRow
    console.log(this.modalContent)

    if (this.modalContent != 0) {

      this.labelBox = "Edit";
      this.labelImg = CONSTANTS.IMG_URL + '/' + tableRow.url;

      this.Form.patchValue({
        name: tableRow.name,
        Id: tableRow.id
      });

      console.log(this.Form);

    }
    else {
      this.labelBox = "Add";
      this.labelImg = null;
      this.Form.patchValue({
        name: null,
        Id: null,
      });
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getDeliveryModeList();
  }

  getDeliveryModeList() {
    this._masterOrderService.getAllDeliveryModeLists()
      .subscribe(
        data => this.DeliveryList = data
      );
  }


  CreateDeliveryMode() {
    if (this.Form.get('name').value == null) {
      alert('Enter Delivery Mode Name');
      return;
    }

    if (this.Form.valid) {

      var obj = {
        name: this.Form.get('name').value
      }

      console.log(obj);

      this._masterOrderService.insertDeliveryMode(obj)
        .subscribe(response => {
          console.log(response);
          window.location.reload();
        });
    }
  }

  UpdateDeliveryMode() {
    if (this.Form.valid) {

      var obj = {
        id: this.Form.get('Id').value,
        name: this.Form.get('name').value
      }

      this._masterOrderService.updateDeliveryMode(obj)
        .subscribe(response => {
          console.log(response);
          this.modalService.dismissAll();
          window.location.reload();
        });
    }
  }

}
