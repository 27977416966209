<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">

                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-secondary" data-toggle="modal"
                            data-original-title="Add Delivery Mode" data-target="#exampleModal"
                            (click)="open(content,0)">Add
                            Social Media</button>

                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{labelBox}} Social Media</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" [formGroup]="Form">
                                <input formControlName="Id" type="hidden">
                                <div class="form">
                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Social Media
                                                Name:</label></strong>
                                        <input class="form-control" type="text" name="name" id="name"
                                            formControlName="name" placeholder="Social Media Name" />
                                    </div>
                                    <div class="form-group">
                                        <strong><label for="validationCustom01" class="mb-1">Link:</label></strong>
                                        <input class="form-control" type="text" name="url" id="url"
                                            formControlName="url" placeholder="Link" />
                                    </div>
                                    <div class="form-group mb-0">
                                        <strong><label for="validationCustom02" class="mb-1">Image :</label></strong>
                                        <input type="file" accept=".jpg,.png,.jpeg" id="image" name="image"
                                            (change)="selectFile($event)">

                                        <img src="{{labelImg}}" class="mr-2" style="width: 30px">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">

                                <div *ngIf="labelBox== 'Edit'; else elseBlock">
                                    <button type="button" class="btn btn-primary"
                                        (click)="Update()">Update</button>
                                </div>
                                <ng-template #elseBlock>
                                    <button type="button" class="btn btn-primary"
                                        (click)="Create()">Save</button>
                                </ng-template>

                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                        </ng-template>

                        <!-- Delete Div -->
                        <ng-template #delcontent let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Delete</h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body" [formGroup]="Form">
                                <input formControlName="Id" type="hidden">
                                <input formControlName="status" type="hidden">
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary"
                                    (click)="Delete()">Yes</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">No</button>
                            </div>
                        </ng-template>
                    </div>

                    <div id="batchDelete" class="category-table">
                        <div class="table-responsive">
                            <table id="users" class="table table-striped">
                                <thead>
                                    <th width="5%" class="center-align left-tbl">#</th>
                                    <th width="15%" class="center-align">Name</th>
                                    <th width="13%" class="center-align">Status</th>
                                    <th width="12%" class="center-align right-tbl">Action</th>
                                </thead>
                                <ng-container *ngFor="let list of CountryList; index as i">
                                    <tr>
                                        <td class="center-align" scope="row">{{ i + 1 }}</td>
                                        <td>{{list.name}}</td>
                                        <td class="center-align">
                                            <div *ngIf="list.deleted_at == null; else elseBlock">
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="badge badge-success"
                                                        style="user-select: auto;">Active</span></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span title="Delivered" class="ng-star-inserted"
                                                    style="user-select: auto;"><span class="delete"
                                                        style="user-select: auto;">InActive</span></span>
                                            </ng-template>
                                        </td>
                                        <td class="center-align">
                                            <span class="space-span">
                                                <a (click)="open(content, list)" class="badge badge-success"> Edit
                                                </a>
                                            </span>
                                        
                                            <span class="space-span"
                                                *ngIf="list.deleted_at == null; else elseBlock_delete">
                                                <a (click)="open(delcontent, list)" class="delete"> Delete
                                                </a>
                                            </span>
                                            <ng-template #elseBlock_delete>
                                                <span class="space-span">
                                                    <a (click)="open(delcontent, list)" class="orange"> Restore
                                                    </a>
                                                </span>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->