import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { MajorCategoryComponent } from './digital/major-category/major-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductsvariantgroupComponent } from './digital/productsvariantgroup/productsvariantgroup.component';
import { ProductsvariantComponent } from './digital/productsvariant/productsvariant.component';
import { ProductVariantGroupAddComponent } from './digital/product-variant-group-add/product-variant-group-add.component';
import { ProductVariantpAddComponent } from './digital/product-variantp-add/product-variantp-add.component';
import { ProductReviewComponent } from './digital/product-review/product-review.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { ProductInventoryComponent } from './product-inventory/product-inventory.component';
import { BrandsComponent } from './brands/brands.component';
import { ProductReviewDetailComponent } from './digital/product-review-detail/product-review-detail.component';
import { AgeGroupComponent } from './digital/age-group/age-group.component';
import { PriceRangeComponent } from './digital/price-range/price-range.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'physical/category',
        component: CategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'physical/sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'physical/product-list',
        component: ProductListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'physical/product-detail',
        component: ProductDetailComponent,
        data: {
          title: "Product Detail",
          breadcrumb: "Product Detail"
        }
      },
      {
        path: 'physical/add-product',
        component: AddProductComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product"
        }
      },
      {
        path: 'digital/age-group',
        component: AgeGroupComponent,
        data: {
          title: "Age Group",
          breadcrumb: "Age Group"
        }
      },
      {
        path: 'digital/price-range',
        component: PriceRangeComponent,
        data: {
          title: "Price Range",
          breadcrumb: "Price Range"
        }
      },
      
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Master Category",
          breadcrumb: "Master Category"
        }
      },
      {
        path: 'digital/major-category',
        component: MajorCategoryComponent,
        data: {
          title: "Major Category",
          breadcrumb: "Major Category"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product"
        }
      },
      {
        path: 'digital/productsvariantgroup',
        component: ProductsvariantgroupComponent,
        data: {
          title: "Product Variant Group List",
          breadcrumb: "Add Product Variant Group List"
        }
      },
      {
        path: 'digital/productsvariant',
        component: ProductsvariantComponent,
        data: {
          title: "Product Variant List",
          breadcrumb: "Add Product Variant List"
        }
      },
      {
        path: 'digital/product-variant-group-add',
        component: ProductVariantGroupAddComponent,
        data: {
          title: "Add Product Variant Group",
          breadcrumb: "Add Product Variant Group"
        }
      },
      {
        path: 'digital/product-variant-add',
        component: ProductVariantpAddComponent,
        data: {
          title: "Add Product Variant",
          breadcrumb: "Add Product Variant"
        }
      },
      {
        path: 'product-inventory/product-inventory',
        component: ProductInventoryComponent,
        data: {
          title: "Product Inventory",
          breadcrumb: "Product Inventory"
        }
      },
      {
        path: 'brands/brands',
        component: BrandsComponent,
        data: {
          title: "Brands",
          breadcrumb: "Brands"
        }
      },
      {
        path: 'digital/product-review',
        component: ProductReviewComponent,
        data: {
          title: "Product Review",
          breadcrumb: "Product Review"
        }
      },

      {
        path: 'digital/digital-add-product/:id',
        component: DigitalAddComponent,
        data: {
          title: "Product Edit",
          breadcrumb: "Product Edit"
        }
      },

      {
        path: 'digital/product-review-detail/:id',
        component: ProductReviewDetailComponent,
        data: {
          breadcrumb: "Product Review"
        }
      }



    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
