import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizationRoutingModule } from './localization-routing.module';
import { TranslationsComponent } from './translations/translations.component';
import { RatesComponent } from './rates/rates.component';
import { TaxesComponent } from './taxes/taxes.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CountriesComponent } from './countries/countries.component';
import { ProvincesComponent } from './provinces/provinces.component';
import { CitiesComponent } from './cities/cities.component';
import { OutletComponent } from './outlet/outlet.component';
import { BanksComponent } from './banks/banks.component';
import { SliderBannerComponent } from './slider-banner/slider-banner.component';
import { SliderTypeComponent } from './slider-type/slider-type.component';
import { SocialMediaComponent } from './social-media/social-media.component';


@NgModule({
  declarations: [TranslationsComponent, RatesComponent, TaxesComponent, CountriesComponent, ProvincesComponent, CitiesComponent, OutletComponent, BanksComponent, SliderBannerComponent, SliderTypeComponent, SocialMediaComponent],
  imports: [
    CommonModule,
    LocalizationRoutingModule,
    Ng2SmartTableModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class LocalizationModule { }
